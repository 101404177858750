<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page_header">
        <h1 class="page_title">{{'management' | translate}} | <span>{{'committee' | translate}}</span></h1>
        <div class="page_action" style="gap: 20px">
            <a href="javascript:void(0)"
               (click)="actionExcel()"
               *ngIf="buttonExcel"
               class="page_action_excel mr-2 btn btn-gray-fill"
               type="button">
                <svg-icon src="assets/svg/download.svg"></svg-icon>
                <span class="font-weight-normal">Exportar Excel</span>
            </a>
            <a href="javascript:void(0);"
               (click)="newCommittee()"
               *ngIf="addCommittee"
               class="btn btn-warning"
               type="button">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                <span class="ml-2 font-weight-normal">{{'newCommittee' | translate}}</span>
            </a>

        </div>
    </div>
    <div class="page_padding">
        <div class="page_filter mb-4">
            <div class="input-material">
                <input id="search" class="form-control" type="text" [(ngModel)]="searchCommittee.keyword" required/>
                <label for="search">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    Busca
                </label>
            </div>
            <div class="input-material" *ngIf="unityCommittee">
                <select id="unity" class="form-control" name="type" [(ngModel)]="searchCommittee.unity">
                    <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option *ngFor="let u of listUnity" [value]="u.id">{{u.name}}</option>
                </select>
                <label for="unity" class="mb-0 mr-3">{{'unity' | translate}}</label>
            </div>
            <div class="input-material">
                <select id="committee" class="form-control" name="type" [(ngModel)]="searchCommittee.manager">
                    <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option *ngFor="let u of listUsers" [value]="u.id">{{u.name}}</option>
                </select>
                <label for="committee" class="mb-0 mr-3">Gestor</label>
            </div>
            <div class="input-material">
                <select id="status" class="form-control" name="type" [(ngModel)]="searchCommittee.status">
                    <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option [ngValue]="true">Ativo</option>
                    <option [ngValue]="false">Inativo</option>
                </select>
                <label for="status" class="mb-0 mr-3">Status</label>
            </div>
            <div class="page_filter_action flex-grow-1">
                <a href="javascript:void(0)"
                   (click)="onSearch()"
                   class="btn btn-block btn-lg btn-primary btn-search"
                   style="font-weight: normal;"
                   type="button">Buscar</a>
            </div>

            <div class="page_filter_action flex-grow-1">
                <a href="javascript:void(0)" (click)="cleanFilter()"
                   class="btn btn-block btn-lg btn-primary btn-search"
                   style="font-weight: normal;"
                   type="button">{{'cleanFilter' | translate}}</a>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table_regular">
                <thead>
                <tr>
                    <th scope="col" class="w-10"></th>
                    <th scope="col">Código</th>
                    <th scope="col">{{'committee' | translate}}</th>
                    <th class="text-center" scope="col">{{'unity' | translate}}</th>
                    <th scope="col">{{'responsibleCommittee' | translate}}</th>
                    <th class="text-center" scope="col">Participantes</th>
                    <th class="text-center" scope="col">Status</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(committee)">
                    <td colspan="7" class="text-center" style="padding: 68px 0 !important;"> nenhum registro encontrado
                    </td>
                </tr>
                <tr *ngFor="let c of committee">
                    <td>
                        <div class="d-flex justify-content-center" style="gap: 15px">
                            <svg-icon class="is-cursor" *ngIf="editCommittee" (click)="updateCommittee(c)"
                                      src="assets/svg/edit.svg"></svg-icon>
                            <svg-icon class="is-cursor" *ngIf="deleteCommittee" (click)="onDelete(c.id)"
                                      src="assets/svg/close-circle.svg"></svg-icon>
                        </div>
                    </td>
                    <td>00000</td>
                    <td>{{c.name}}</td>
                    <td class="text-center">{{c.unity?.name!}}</td>
                    <td>
                        <img class="user-photo border-warning"
                             src="{{c.manager.photo}}" onerror="this.onerror=null;
                                this.src='https://www.pontoxtecidos.com.br/static/567/sku/164485009798408.jpg';" alt="img"/>

                        {{c.manager?.name}}
                    </td>
                    <td class="text-center">
                        <div class="d-flex justify-content-center align-items-center">
                            <div class="position-relative" style="left: 15px;">
                                <img class="user-photo border-warning position-relative mr-0"
                                     *ngFor="let p of c.participants; let i = index"
                                     [ngStyle]="{'left': getPosition(i)}"
                                     src="{{p.photo}}" onerror="this.onerror=null;
                                this.src='https://www.pontoxtecidos.com.br/static/567/sku/164485009798408.jpg';"
                                     alt="img"/>
                            </div>
                            <span *ngIf="c.participants?.length > 5" class="position-relative"
                                  style="right: 15px;">+5</span>
                        </div>
                    </td>
                    <td class="text-center">{{c.status ? 'Ativo' : 'Inativo'}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalCommittee" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header align-items-center bg-gray" style="min-height: 107px;">
                <h2 class="modal-title">{{isNullOrUndefined(modelCommittee.id) ? translate.instant('newCommittee') : translate.instant('editCommittee')}}</h2>
                <a data-dismiss="modal" class="modal_close" aria-label="Close">
                    <svg-icon src="assets/svg/close-modal.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body p-3 p-md-4">
                <div class="d-flex flex-wrap" style="column-gap: 30px">
                    <div class="flex-grow-1">
                        <div class="input-material">
                            <input id="nameUnity" [(ngModel)]="modelCommittee.name" class="form-control" type="text"
                                   required/>
                            <label for="nameUnity">
                                {{'committeeName' | translate}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-wrap" style="column-gap: 30px">
                    <!--                    <div class="flex-grow-1">-->
                    <!--                        <div class="input-material">-->
                    <!--                            <select id="area" [(ngModel)]="createCommitteeAreaInput.id" class="form-control"-->
                    <!--                                    name="type">-->
                    <!--                                <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>-->
                    <!--                                <option *ngFor="let i of listArea" [value]="i.id">{{i.name}}</option>-->
                    <!--                            </select>-->
                    <!--                            <label for="area">Área</label>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div class="flex-grow-1" *ngIf="unityCommittee">
                        <div class="input-material">
                            <select id="unity_modal" [(ngModel)]="createCommitteeUnityInput.id" class="form-control"
                                    name="type">
                                <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                                <option *ngFor="let i of listUnity" [value]="i.id">{{i.name}}</option>
                            </select>
                            <label for="unity_modal">{{'unity' | translate}}</label>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <div class="input-material">
                            <select id="manager" [(ngModel)]="createCommitteeManagerInput.id" class="form-control"
                                    name="type">
                                <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                                <option *ngFor="let i of listUsers" [value]="i.id">{{i.name}}</option>
                            </select>
                            <label for="manager">{{'responsibleCommittee' | translate}}</label>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-wrap" style="column-gap: 30px">
                    <div class="flex-grow-1">
                        <div class="input-material">
                            <!--                            <select id="participants" [(ngModel)]="userSelected" class="form-control" name="type">-->
                            <!--                                <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>-->
                            <!--                                <option *ngFor="let i of listUsers" [ngValue]="i">{{i.name}}</option>-->
                            <!--                            </select>-->
                            <!--                            <label for="participants">Participante</label>-->
                            <ng-multiselect-dropdown
                                    id="participants"
                                    [placeholder]="'Selecione'"
                                    [(ngModel)]="participants"
                                    [settings]="dropdownParticipantSettings"
                                    [data]="listUsers"
                                    (onSelect)="addParticipants()"
                            >
                            </ng-multiselect-dropdown>
                            <label for="participants" class="disabled">{{'participantSelection' | translate}}</label>
                        </div>
                    </div>
                    <!--                    <div class="">-->
                    <!--                        <a (click)="addParticipants()" class="btn btn-warning">Adicionar</a>-->
                    <!--                    </div>-->
                </div>
                <div class="d-flex flex-wrap" style="column-gap: 30px">
                       <div class="d-flex"
                         *ngFor="let p of participants">
<!--                        <img class="committee-img"-->
<!--                             [src]="!isNullOrUndefined(p.photo)?p.photo:'https://medicina.ucpel.edu.br/wp-content/uploads/2020/07/apacucpel_ucpel_image_319-1024x960.jpeg'">-->
                        <p class="committee-name"> | {{p.name}} | </p>
<!--                        <svg-icon (click)="removeParticipants(p.id)" [svgClass]="'committee-close'"-->
<!--                                  src="assets/svg/close-circle.svg"></svg-icon>-->
                    </div>
                </div>
                <hr class="my-3">
                <div class="d-flex justify-content-end align-items-center mb-3" style="column-gap: 30px">
                    <span>Status</span>
                    <label class="switch mb-0">
                        <input type="checkbox"
                               name="checkbox"
                               [(ngModel)]="modelCommittee.status"
                               class="ng-untouched ng-pristine ng-valid">
                        <span class="slider round"></span>
                    </label>
                </div>
                <div class="d-flex justify-content-end align-items-center mb-3" style="column-gap: 30px">
                    <span>{{this.modelCommittee.status ? 'Ativo' : 'Inativo' }}</span>
                </div>
                <div class="d-flex flex-grow-1" style="gap: 30px;max-width: 394px;margin: 0 auto">
                    <a class="btn flex-grow-1 btn-outline-primary" data-dismiss="modal" aria-label="Close">Cancelar</a>
                    <a class="btn flex-grow-1 btn-primary" (click)="onSave();">Salvar</a>
                </div>
            </div>
        </div>
    </div>
</div>

