import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ExcelService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getExcelIdea(model) {
        const url = `${this.BASE_URL}/ideas/search-idea/excel`;
        return this.http.post(url, model);
    }

    getExcelUser(model) {
        const url = `${this.BASE_URL}/users/search-user/excel`;
        return this.http.post(url, model);
    }

    getExcelArea(model) {
        const url = `${this.BASE_URL}/areas/search-area/excel`;
        return this.http.post(url, model);
    }

    getExcelUnities(model) {
        const url = `${this.BASE_URL}/unities/search-unity/excel`;
        return this.http.post(url, model);
    }

    getExcelCommittees(model) {
        const url = `${this.BASE_URL}/committees/search-committee/excel`;
        return this.http.post(url, model);
    }
}
