<div class="page">
    <div class="page_header">
        <a class="mr-4" href="javascript:void('');" [routerLink]="'/clic-news'">
            <svg-icon src="assets/svg/back.svg" ></svg-icon>
        </a>
        <h1 class="page_title">Clic News</h1>
        <div class="autor" *ngIf="!isNullOrUndefined(model.id)">
            <div class="d-flex flex-column">
                <p>Publicado por</p>
                <div class="row align-items-center ml-0">
                    <!--<img class="user_img" src="assets/images/profile.png" alt="">-->
                    <b>{{model.author}}</b>
                </div>
            </div>
            <div class="divisor" *ngIf="!isNullOrUndefined(model.createdAt)"></div>

            <div class="d-flex flex-column" *ngIf="!isNullOrUndefined(model.createdAt)">
                <p>Publicado em</p>
                <b>{{model.createdAt | date: 'dd/MM/yyyy'}}</b>
            </div>

        </div>
    </div>
    <div class="p-3">
        <div class="row justify-content-between p-3 pr-5">
            <div class="col-md-9 col-5 card-primary-beige p-3 ">
                <b>Titulo</b>
                <input id="search" type="text" required="" class="form-control" [(ngModel)]="model.title">
            </div>
            <div class="col-md-2 col-5 card-primary-beige d-flex align-items-center justify-content-between p-3 ">
                <b>Publicado</b>
                <label class="switch">
                    <input type="checkbox" name="checkbox" [checked]="model.status === 'published'"
                           (change)="handlerState($event)" [value]="true">
                    <span class="slider round"></span>
                </label>
            </div>

            <div class="col-12 col-lg-9 card-primary-beige p-3 mt-3">
                <b>{{'description' | translate}}</b>
                <div class="w-100 d-block">
                    <input id="description" type="text" required="" class="form-control" maxlength="250" [(ngModel)]="model.description">
                </div>
            </div>

            <div class="col-12 col-lg-3 card-primary-beige p-3 mt-3">
                <b>Autor</b>
                <div class="w-100 d-block">
                    <input id="author" type="text" required="" class="form-control"  maxlength="25" [(ngModel)]="model.author">
                </div>
            </div>
            <div class="col-12 card-primary-beige p-3 mt-3">
                <b>Texto</b>
                <div class="w-100 d-block">
                    <!--<input id="content" type="text" required="" class="form-control" [(ngModel)]="model.content">-->
                    <ckeditor [(ngModel)]="model.content" [editor]="Editor"></ckeditor>
                </div>
            </div>

            <div class="col-12 card-primary-beige p-3 mt-3 d-flex flex-column align-items-baseline">
                <b>Tags</b>
                <div class="col-md-4 col-12">
                    <div class="input-material m-0 d-flex justify-content-start">
                        <input id="tags" type="text" required="" class="form-control"
                               [(ngModel)]="modelTag"
                               (keyup.enter)="createTag($event.target.value)">
                        <label for="tags">Escreva aqui sua tag </label>
                    </div>
                </div>
                <div class="col-md-12 col-12">
                        <span class="d-inline-block px-2" *ngFor="let t of model.tags">{{t.tag}}
                            <a href="javascript:void('');" (click)="actionRemoveTag(t);">X</a></span>
                </div>
            </div>

            <div class="col-12 card-primary-beige mt-3 pt-3 ">
                <b>Imagem</b>
                <div class="">
                    <div class="p-3  inner_image">
                        <div class="col-lg-6 col-12">
                            <p>e 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more
                                recently with desktop publishing software like Aldus PageMaker including versions of
                                Lor</p>
                        </div>
                        <div class="col-lg-6 col-12 text-center">
                            <a href="javascript:void('');" class="btn btn-outline-primary my-5"
                               *ngIf="isNullOrUndefined(this.model.imageUrl) && !croppedImage"
                               (click)="showModal('modalCropNews')">Adicionar imagem</a>
                            <div class="news_image is-cursor" (click)="showModal('modalCropNews')">
                                <img  [src]="!croppedImage ? this.model.imageUrl : croppedImage" />
                            </div>
                            <!--<div class="news_image">
                                <img  src="assets/images/custom.png" alt="">
                                <svg-icon src="assets/svg/close-circle-white.svg"></svg-icon>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-grow-1" style="gap: 30px; max-width: 600px; margin: 40px auto 0;">
                <a class="btn flex-grow-1 btn-outline-primary" (click)="onSave()">Salvar</a>
                <a class="btn flex-grow-1 btn-primary" *ngIf="this.model.status !== 'published'"
                   (click)="onPublished()">Publicar</a>
            </div>

        </div>

    </div>

</div>

<app-crop-image [cropId]="'modalCropNews'"
                [cropAspectRatio]="12/6"
                [cropResizeToWidth]="1024"
                [cropResizeToHeight]="576"
                (croppedImageResult)="setCroppedImage($event)"></app-crop-image>

