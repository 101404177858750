<!--<p class="card" draggable="true" (dragstart)="dragStart($event)" id="{{card.id}}">-->
<!--</p>-->
<div class="kamban-card"  draggable="true" (dragstart)="dragStart($event)" id="{{card?.action.id}}">
    <p class="kamban-card_code">{{card?.action.code}}</p>
    <p class="kamban-card_description">{{card?.action.title}}</p>
    <div class="kamban-card_info">
        <span class="kamban-card_date">{{card?.action.createdAt | date:'dd/MM/yyyy'}}</span>
        <div class="kamban-card_info_item">
            <svg-icon src="assets/svg/comment.svg"></svg-icon>
            <span class="kamban-card_comment">{{card?.commentsCount}}</span>
        </div>
        <div class="kamban-card_assignment">
            <svg-icon src="assets/svg/check-circle.svg"></svg-icon>
            <span>0/{{card?.totalTasks}}</span>
        </div>
        <div class="kamban-card_info_item">
            <svg-icon src="assets/svg/attachment.svg"></svg-icon>
            <span class="kamban-card_attachment">{{card?.filesCount}}</span>
        </div>
    </div>
    <div class="kamban-card_created-by">
        <img  src="https://www.pontoxtecidos.com.br/static/567/sku/164485009798408.jpg">
        <p>{{card?.action.responsible.name}}</p>
    </div>

</div>
