import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {
    AddNewsTagsGQL,
    CreateNewsGQL, CreateNewsInput,
    GetNewGQL,
    GetNewQuery,
    News, NewsTag,
    UpdateNewsGQL, UpdateNewsInput, User
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {UserService} from "../../service/user.service";

declare var $: any;

@Component({
    selector: 'app-clic-news-detail',
    templateUrl: './clic-news-detail.component.html',
    styleUrls: ['./clic-news-detail.component.scss']
})
export class ClicNewsDetailComponent extends BaseComponent implements OnInit {
    user: User;

    paramId: string = null;
    model: News = new News();
    modelQuery: QueryRef<GetNewQuery>;

    modelTag = null;
    // modelTagsView = [];

    Editor = ClassicEditor;

    file: File = null;
    thumpPath: string | ArrayBuffer;
    croppedImage: any = null;

    constructor(public router: Router,
                public router2: ActivatedRoute,
                public loading: NgxSpinnerService,
                private getNewGQL: GetNewGQL,
                private createNewsGQL: CreateNewsGQL,
                private updateNewsGQL: UpdateNewsGQL,
                private addNewsTagsGQL: AddNewsTagsGQL,
                public translate: TranslateService,
                public userService: UserService) {
        super(router, loading, translate);
        this.model.content = null; // CRIAR CHAVE NO OBJETO PARA EDITOR FUNCIONAR
    }

    ngOnInit(): void {
        this.router2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
                this.paramId = url.id;
                if (this.paramId === 'register') {
                    return;
                }
                this.modelQuery = this.getNewGQL.watch({input: this.paramId});
                this.modelQuery.valueChanges.subscribe(({data}) => {
                    this.model = Object.assign({}, data.getNew) as News;
                });
            }
        });

        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.model.author = this.user.name;
            }, error: err => super.onError(err)
        });
    }

    // CROP
    setCroppedImage(o: any) {
        this.croppedImage = o.thumb;
        this.handleUpload(o.file);
    }

    handleUpload(event) {
        const files = event;
        if (files.length > 0) {
            if (files[0].size > 2000000) {
                super.showMessage(this.translate.instant('profile.edit.warningAttention'),
                    this.translate.instant('profile.edit.warningUpload'), 'info');
                return;
            }
            if (files[0].type.toLowerCase().indexOf('jpeg') === -1
                && files[0].type.toLowerCase().indexOf('jpg') === -1
                && files[0].type.toLowerCase().indexOf('png') === -1) {
                super.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('warningUploadExtensions'),
                    'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (_event) => {
            this.thumpPath = _event.target.result;
        };
        reader.readAsDataURL(event[0]);
        this.file = files[0];
        // this.uploadImage();
    }

    uploadImage() {
        this.userService.upload(this.file, this.model.id, 'news-image').subscribe(
            data => {
                setTimeout(() => {
                    this.onSuccess('update');
                }, 300);
                // this.loading.hide();
            }, error => super.onError(error));
    }

    // TAG
    createTag(s: any) {
        if (this.isNullOrUndefined(this.modelTag.trim())) {
            return;
        }
        this.model.tags = !this.isNullOrUndefined(this.model.tags) ? this.model.tags : [];
        if (this.model.tags.some(x => x.tag === s)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningAlrdChalange'));
            return;
        }
        const a = new NewsTag();
        a.tag = s;
        this.model.tags = !this.isNullOrUndefined(this.model.tags) ? Object.assign([], this.model.tags) : [];
        this.model.tags.push(a);
        this.modelTag = null;
    }

    actionRemoveTag(o: any) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningConform'), () => {
            this.model.tags = this.model.tags.filter((item) => item.tag !== o.tag);
        });
    }

    handlerState($event: any) {
        if ($($event.target).prop('checked')) {
            this.model.status = 'published';
        } else {
            this.model.status = 'unpublished';
        }
    }

    // SAVE
    onPublished() {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningAskConfirm'), () => {
            this.onSave('published');
        });
    }

    onSave(published?) {
        this.model.status = this.isNullOrUndefined(this.model.status) ? 'unpublished' : this.model.status;
        const arrValidateFields = [
            {value: this.model.title, text: 'Titulo <br />'},
            {value: this.model.description, text: 'Descrição <br />'},
            {value: this.model.author, text: 'Autor <br />'},
            {value: this.model.content, text: 'Texto <br />'},
            {value: this.model.status, text: 'Status <br />'}
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }
        const a: any = !this.isNullOrUndefined(this.model.id) ? new UpdateNewsInput() : new CreateNewsInput();
        a.content = this.model.content;
        a.tag = [];
        if (!this.isNullOrUndefined(this.model.id)) {
            a.id = this.model.id;
        }
        a.title = this.model.title;
        a.author = this.model.author;
        a.status = !this.isNullOrUndefined(published)  ? 'published' : this.model.status;
        console.log(a.status);
        a.description = this.model.description;
        if (!this.isNullOrUndefined(this.model.tags)) {
            this.model.tags.map((x) => {
                a.tag.push(x.tag);
            });
        }
        // this.model.tags = a;
        if (this.isNullOrUndefined(this.model.id)) {
            this.onCreateNews(a);
        } else {
            console.log(a.status);
            this.onUpdateNews(a);
        }
    }

    onUpdateNews(a: UpdateNewsInput) {
        this.loading.show();
        this.updateNewsGQL.mutate({data: a}).subscribe((result) => {
            const a: any = result.data.updateNews as News;
            console.log('a');
            console.log(a);
            this.model = a;
            if (!this.isNullOrUndefined(this.croppedImage)) {
                this.uploadImage();
            } else {
                this.onSuccess('update');
            }
        });
    }

    onCreateNews(a: CreateNewsInput) {
        this.loading.show();
        this.createNewsGQL.mutate({data: a}).subscribe((result) => {
            // this.budgetQuery.refetch({id: this.paramId});
            const a: any = result.data.createNews as News;
            console.log('a');
            console.log(a);
            this.model = a;
            if (!this.isNullOrUndefined(this.croppedImage)) {
                this.uploadImage();
            } else {
                this.onSuccess('create');
            }
        });
    }

    onSuccess(type: string) {
        this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant(type == 'create' ? 'noticiapublicada':'noticiaatualizada'), 'success');
        this.router.navigate(['/clic-news']);
        this.loading.hide();
        // this.modelQuery.refetch({input: this.paramId});
    }
}
