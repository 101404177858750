import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {
    Challenge, CreateChallengeGQL, CreateChallengeInput,
    GetNewsSearchGQL,
    GetNewsSearchQuery, PageChallenge, PageChallengesGQL,
    PageChallengesQuery, RemoveChallengeGQL,
    RemoveNewsGQL, SearchChallengeInput, UpdateChallengeGQL, UpdateChallengeInput, User
} from "../../../generated/graphql";
import {UserService} from "../../service/user.service";
import {QueryRef} from "apollo-angular";
import moment from "moment";

declare var $: any;

@Component({
    selector: 'app-challenge',
    templateUrl: './challenge.component.html',
    styleUrls: ['./challenge.component.scss']
})
export class ChallengeComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    user: User = new User();
    filter: SearchChallengeInput = new SearchChallengeInput();
    model: PageChallenge = new PageChallenge();
    modelQuery: QueryRef<PageChallengesQuery>;
    modelChallenge: Challenge = new Challenge();
    // language = 'pt';
    /*{
        page: this.model.currentPage,
        language: this.language === 'es' ? 'es-ES' : 'pt-BR'
    }*/

    newChallenge: boolean;
    editChallenge: boolean;
    deleteChallenge: boolean;

    constructor(
        public router: Router,
        public loading: NgxSpinnerService,
        public translate: TranslateService,
        public userService: UserService,
        public pageChallengesGQL: PageChallengesGQL,
        public removeChallengeGQL: RemoveChallengeGQL,
        public createChallengeGQL: CreateChallengeGQL,
        public updateChallengeGQL: UpdateChallengeGQL,
    ) {
        super(router, loading, translate);
        this.filter.page = 1;
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.userProfile();
            }, error: err => super.onError(err)
        });
        this.userService.language.subscribe({
            next: data => {
                this.filter.language = this.isNullOrUndefined(data) ? this.translate.currentLang : data;
                this.filter.language = this.filter.language === 'es' ? 'es-ES' : 'pt-BR';
                this.filter.page = 1;
                this.getSearch();
            }, error: err => super.onError(err)
        })
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        const el = $('#modalChallenge');
        if (el[0]) {
            el.remove();
        }
    }

    pageChanged(event) {
        this.filter.page = event;
        this.actionSearch();
    }

    actionSearch() {
        this.modelQuery.refetch({data: this.filter});
    }

    getSearch() {
        this.modelQuery = this.pageChallengesGQL.watch({data: this.filter});
        this.modelQuery.valueChanges.subscribe(({data}) => {
            this.model = data.pageChallenges as PageChallenge;
            this.loading.hide();
        });
    }

    actionChallenge() {
        this.modelChallenge = new Challenge();
        this.modelChallenge.startDate = moment().format();
        this.modelChallenge.endDate = moment().add(29, "days").format();
        this.setDatePickerNext('picker-period', (start, end, label) => {
            this.modelChallenge.startDate = start.format();
            this.modelChallenge.endDate = end.utcOffset(0, true).format();
        }, this.modelChallenge.startDate, this.modelChallenge.endDate);
        this.showModal('modalChallenge');
    }

    updateChallenge(c: Challenge) {
        this.modelChallenge = Object.assign({}, c);
        this.setDatePickerNext('picker-period', (start, end, label) => {
            this.modelChallenge.startDate = start.utc().format();
            this.modelChallenge.endDate = end.utcOffset(0, true).format();
        }, this.modelChallenge.startDate, this.modelChallenge.endDate);
        this.showModal('modalChallenge');
    }

    removeChallenge(id: any) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningAttention'), () => {
            console.log('removeChallenge');
            this.removeChallengeGQL.mutate({
                language: this.filter.language === 'es' ? 'es-ES' : 'pt-BR',
                id: id
            }).subscribe(
                (data) => {
                    this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.chalangeDeleteConform'), 'success');
                    this.actionSearch();
                    this.loading.hide();
                }, err => super.onError(err)
            );
        });
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.modelChallenge.name, text: 'Nome <br />'},
            {value: this.modelChallenge.title, text: 'Titulo <br />'},
            {value: this.modelChallenge.description, text: 'Descrição <br />'},
            {value: this.modelChallenge.language, text: 'Idioma <br />'},
            {value: this.modelChallenge.status, text: 'Status <br />'}
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }
        if (super.isNullOrUndefined(this.modelChallenge.id)) {
            this.onCreateChallenge();
        } else {
            this.onUpdateChallenge();
        }
    }

    onCreateChallenge() {
        const a: CreateChallengeInput = new CreateChallengeInput();
        a.name = this.modelChallenge.name;
        if (this.modelChallenge.isDateSensitive) {
            a.startDate = this.modelChallenge.startDate;
            a.endDate = this.modelChallenge.endDate;
        }
        a.isDateSensitive = this.modelChallenge.isDateSensitive;
        a.title = this.modelChallenge.title;
        a.description = this.modelChallenge.description;
        a.status = this.modelChallenge.status;
        a.language = this.modelChallenge.language;
        this.createChallengeGQL.mutate({data: a}).subscribe(
            (data) => {
                this.closeModal('modalChallenge');
                this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.chalangeAddConform'), 'success');
                this.actionSearch();
                this.loading.hide();
            }, err => super.onError(err)
        );
    }

    onUpdateChallenge() {
        const a: UpdateChallengeInput = new UpdateChallengeInput();
        a.id = this.modelChallenge.id;
        a.name = this.modelChallenge.name;
        if (this.modelChallenge.isDateSensitive) {
            a.startDate = this.modelChallenge.startDate;
            a.endDate = this.modelChallenge.endDate;
        }
        a.isDateSensitive = this.modelChallenge.isDateSensitive;
        a.title = this.modelChallenge.title;
        a.description = this.modelChallenge.description;
        a.status = this.modelChallenge.status;
        a.language = this.modelChallenge.language;
        this.updateChallengeGQL.mutate({language: this.modelChallenge.language, data: a}).subscribe(
            (data) => {
                this.closeModal('modalChallenge');
                this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.chalangeLoadingConform'), 'success');
                this.actionSearch();
                this.loading.hide();
            }, err => super.onError(err)
        );
    }


    setDatePickerNext(id: string, callback, startDate, endDate) {
        $('.' + id).daterangepicker({
            startDate: moment(startDate).utcOffset(0, true),
            endDate: moment(endDate).utcOffset(0, true),
            cancelButtonClasses: 'btn-danger',
            ranges: {
                Hoje: [moment(), moment()],
                'Amanhã': [moment().add(1, 'days'), moment().add(1, 'days')],
                '7 Dias': [moment(), moment().add(6, 'days')],
                '30 Dias': [moment(), moment().add(29, 'days')]
            },
            locale: {
                customRangeLabel: 'Personalizar',
                format: 'DD/MM/YYYY',
                separator: ' - ',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                monthNames: this.getMonthNames(),
                daysOfWeek: this.getDaysOfDayWeek(),
                firstDay: 1
            }
        }, callback);
    }

    userProfile() {
        switch (this.user.role) {
            case 'admin':
                this.newChallenge = true;
                this.editChallenge = true;
                this.deleteChallenge = true;
                break;
            case 'user':
                this.newChallenge = true;
                this.editChallenge = true;
                this.deleteChallenge = true;
                break;
            case 'corporate-manager':
                this.newChallenge = true;
                this.editChallenge = true;
                this.deleteChallenge = true;
                break;
            case 'local-manager':
                this.newChallenge = false;
                this.editChallenge = false;
                this.deleteChallenge = false;
                break;
            case 'committee-member':
                this.newChallenge = false;
                this.editChallenge = false;
                this.deleteChallenge = false;
                break;

        }
    }
}
