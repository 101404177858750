import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {
    Committee,
    CommitteesGQL,
    Country, CreateCommitteeParticipantInput, CreateUserCommitteeInput,
    CreateUserGQL,
    CreateUserInput, CreateUserUnityInput,
    GetCountriesGQL,
    GetCountriesQuery,
    GetUnitiesByCountryGQL,
    GetUnitiesByCountryQuery, GetUnitiesGQL,
    RemoveUserGQL,
    SearchUserGQL,
    SearchUserInput,
    SearchUserQuery,
    TranslateIdeaExpectationInput,
    Unity,
    UpdateUserGQL,
    UpdateUserInput,
    User, UserPage
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {ExcelService} from '../../service/excel.service';
import {UserService} from '../../service/user.service';

declare var $: any;

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    user = new User();
    modelUser: User = new User();
    searchUserQuery: QueryRef<SearchUserQuery>;
    userResult: UserPage = new UserPage();
    committeeResult: Committee[] = [];
    committee: Committee[] = [];

    listUnity: Unity[] = [];
    searchUserInput: SearchUserInput = new SearchUserInput();

    filterUnities: Array<Unity>;
    filterUnityId: string;
    filterCountries: Array<Country>;
    filterCountryId: string;
    committeeId: string;
    countryQuery: QueryRef<GetCountriesQuery>;
    unityQuery: QueryRef<GetUnitiesByCountryQuery>;

    dropdownCommitteeSettings = {};

    buttonExcel: boolean;
    editUser: boolean;
    addUser: boolean;
    deleteUser: boolean;
    unityCommittee: boolean;

    isVisible: boolean = false;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public userService: UserService,
                public CommitteesGQL: CommitteesGQL,
                public searchUserGQL: SearchUserGQL,
                public createUserGQL: CreateUserGQL,
                public removeUserGQL: RemoveUserGQL,
                public updateUserGQL: UpdateUserGQL,
                public excelService: ExcelService,
                public unitiesGQL: GetUnitiesGQL,
                private getCountriesGQL: GetCountriesGQL,
                private getUnitiesByCountryGQL: GetUnitiesByCountryGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit() {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.userProfile();
            }, error: err => super.onError(err)
        });

        this.dropdownCommitteeSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            itemsShowLimit: 0,
            enableCheckAll: false,
            allowSearchFilter: true,
            maxHeight: 500,
        };
        this.actionList();
        this.actionListUnity();
        this.filterGetCountries();
        this.actionListCommittee();
    }

    ngAfterViewInit() {
    }

    ngOnDestroy(): void {
        const el = $('#modalGroup');
        if (el[0]) {
            el.remove();
        }
    }

    actionListCommittee() {
        this.CommitteesGQL.watch().valueChanges.subscribe(({data}) => {
            this.committeeResult = data.committees as Committee[];
        });
    }

    actionList() {
        this.loading.show();
        this.searchUserQuery = this.searchUserGQL.watch({input: this.searchUserInput});
        this.searchUserQuery.valueChanges.subscribe(({data}) => {
            this.userResult = data.searchUser as UserPage;
            this.loading.hide();
        });
    }

    actionListUnity() {
        this.unitiesGQL.watch().valueChanges.subscribe(({data}) => {
            this.listUnity = data.getUnities as Unity[];
        });
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.modelUser.name, text: 'Nome<br>'},
            {value: this.modelUser.email, text: 'Email<br>'},
            {value: this.modelUser.role, text: 'Perfil<br>'},
            {value: this.filterUnityId, text: 'Unidade<br>'},
            {value: this.filterCountryId, text: 'País<br>'}
        ];

        switch (this.modelUser.role) {
            case 'committee-member':
                if (this.isNullOrUndefined(this.committee)) {
                    this.showMessage('Atenção', `Preencha os campos corretamente: <br> Comitê`);
                    return;
                }
                break;
        }

        switch (this.user.role) {
            case 'local-manager':
                arrValidateFields.splice(3, 2);
                break;
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br> ${stringError}`);
            return;
        }
        if (!this.isEMailValid(this.modelUser.email)) {
            return;
        }
        if (super.isNullOrUndefined(this.modelUser.id)) {
            this.actionCreatedUser();
        } else {
            this.actionUpdateUser();
        }
    }

    convertCommittees(p, update = false) {
        if (update) {
            let committeeList: CreateUserCommitteeInput[] = [];
            p.map((e) => {
                const p = {id: e.id};
                committeeList.push(p);
            });
            return committeeList;
        } else {
            let committeeList: CreateUserCommitteeInput[] = [];
            p.map((e) => {
                const p = {id: e.id};
                committeeList.push(p);
            });
            return committeeList;
        }
    }

    actionCreatedUser() {
        this.loading.show();
        let unity: CreateUserUnityInput = new CreateUserUnityInput();
        unity = {id: this.filterUnityId};

        switch (this.user.role) {
            case 'local-manager':
                unity = {id: this.user.unity.id};
        }

        let input: CreateUserInput = new CreateUserInput();
        input = {
            name: this.modelUser.name,
            email: this.modelUser.email,
            role: this.modelUser.role,
            unity: unity,
            commites: this.modelUser.role == 'committee-member' ? this.convertCommittees(this.committee) : []
        };
        // input = {name: this.modelUser.name, email: this.modelUser.email, role: this.modelUser.role, unity: this.modelUser.unity, country: this.modelUser.country};
        this.createUserGQL.mutate({input: input}).subscribe(
            (data) => {
                $('#modalGroup').modal('hide');
                this.searchUserQuery.refetch({input: this.searchUserInput});
                this.showMessage(this.translate.instant('success'), this.translate.instant('usuariocriado'), 'success');
                this.loading.hide();
            }, err => super.onError(err)
        );
    }

    actionUpdateUser() {
        this.loading.show();
        let unity: CreateUserUnityInput = new CreateUserUnityInput();
        unity = {id: this.filterUnityId};

        let input = new UpdateUserInput();
        input = {
            name: this.modelUser.name,
            email: this.modelUser.email,
            role: this.modelUser.role,
            unity: unity,
            commites: this.convertCommittees(this.committee)
        };
        // input = {name: this.modelUser.name, email: this.modelUser.email, role: this.modelUser.role, unity: this.modelUser.unity, country: this.modelUser.country};
        this.updateUserGQL.mutate({input: input, id: this.modelUser.id}).subscribe(
            (data) => {
                $('#modalGroup').modal('hide');
                this.searchUserQuery.refetch({input: this.searchUserInput});
                this.showMessage(this.translate.instant('success'), this.translate.instant('usuarioeditado'), 'success');
                this.loading.hide();
            }, err => super.onError(err)
        );
    }

    actionDelete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente excluir esse usuário?', () => {
            this.loading.show();
            this.removeUserGQL.mutate({id: id}).subscribe(
                (data) => {
                    $('#modalGroup').modal('hide');
                    this.searchUserQuery.refetch({input: this.searchUserInput});
                    this.loading.hide();
                }, err => super.onError(err)
            );
        });
    }

    newUser() {
        this.isVisible = false;
        this.modelUser = new User();
        this.committee = null;
        $('#modalGroup').modal('show');
    }

    UpdateUser(user: User) {
        this.isVisible = false;
        this.filterUnityId = undefined;
        this.committee = Object.assign([], user).committeeParticipants;
        this.modelUser = Object.assign({}, user);
        if (!this.isNullOrUndefined(user.unity)) {
            this.filterCountryId = Object.assign({}, user.unity!.country).id;
        }
        this.unityQuery = this.getUnitiesByCountryGQL.watch(
            {countryId: this.isNullOrUndefined(this.filterCountryId) ? '' : this.filterCountryId});
        this.unityQuery.valueChanges.subscribe(({data}) => {
            this.filterUnities = data.getUnitiesByCountry as Array<Unity>;
            if (!this.isNullOrUndefined(user.unity)) {
                this.filterUnityId = user.unity.id;
            }
            $('#modalGroup').modal('show');
        });

        if (user.role == 'committee-member' || user.committeeParticipants.length > 0) {
            this.isVisible = true;
        }
    }

    onSearch(event = 1) {
        this.searchUserInput.page = event;
        this.searchUserQuery.refetch({input: this.searchUserInput});
    }

    filterGetCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            this.filterCountries = data.getCountries as Array<Country>;
        });
    }

    getUnitiesFilter() {
        this.filterUnityId = null;
        this.unityQuery = this.getUnitiesByCountryGQL.watch(
            {countryId: this.isNullOrUndefined(this.filterCountryId) ? '' : this.filterCountryId});
        this.unityQuery.valueChanges.subscribe(({data}) => {
            this.filterUnities = data.getUnitiesByCountry as Array<Unity>;
        });
    }

    actionExcel() {
        // let modelExcel = {
        //     committee: this.searchUserInput.committee,
        //     keyword: this.searchUserInput.keyword,
        //     userID: this.user.id,
        // }

        this.loading.show();
        this.excelService.getExcelUser(this.searchUserInput).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    setCommitteeInput($event: any) {
        if (this.isNullOrUndefined($event)) {
            this.isVisible = false;
            this.committee = null;
        }
        switch ($event) {
            case 'committee-member':
                this.isVisible = true;
                break;
            default:
                this.isVisible = false;
                this.committee = null;
                break;
        }
    }

    userProfile() {
        switch (this.user.role) {
            case 'admin':
                this.buttonExcel = true;
                this.addUser = true;
                this.editUser = true;
                this.deleteUser = true;
                this.unityCommittee = true;
                break;
            case 'user':
                this.buttonExcel = true;
                this.addUser = true;
                this.editUser = true;
                this.deleteUser = true;
                this.unityCommittee = false;
                break;
            case 'corporate-manager':
                this.buttonExcel = true;
                this.addUser = true;
                this.editUser = true;
                this.deleteUser = true;
                this.unityCommittee = true;
                break;
            case 'local-manager':
                this.buttonExcel = true;
                this.addUser = true;
                this.editUser = true;
                this.deleteUser = false;
                this.unityCommittee = false;
                break;
            case 'committee-member':
                this.buttonExcel = false;
                this.addUser = false;
                this.editUser = false;
                this.deleteUser = false;
                this.unityCommittee = false;
                break;

        }
    }

}
