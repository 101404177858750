<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page_header">
        <h1 class="page_title">{{'management' | translate}} | <span>Unidades</span></h1>
        <div class="page_action" style="gap: 20px">
            <a href="javascript:void(0)"
               (click)="actionExcel()"
               *ngIf="buttonExcel"
               class="page_action_excel mr-2 btn btn-gray-fill"
               type="button">
                <svg-icon src="assets/svg/download.svg"></svg-icon>
                <span class="font-weight-normal">Exportar Excel</span>
            </a>
            <a href="javascript:void('Editar');"
               (click)="newUnity()"
               *ngIf="addUnity"
               class="page_action_excel btn btn-warning"
               type="button">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                <span class="ml-2 font-weight-normal">{{'newUnity' | translate}}</span>
            </a>

        </div>
    </div>
    <div class="page_padding">
        <div class="page_filter mb-4">
            <div class="input-material" style="max-width: 394px">
                <input id="search" [(ngModel)]="searchUnityInput.keyword" class="form-control" type="text" required/>
                <label for="search">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    Busca
                </label>
            </div>
            <div class="input-material mr-auto" style="max-width: 182px">
                <select id="unity" [(ngModel)]="searchUnityInput.country" class="form-control" name="type">
                    <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option *ngFor="let u of countriesResult" [value]="u.id">{{u.name}}</option>
                </select>
                <label for="unity" class="mb-0 mr-3">País</label>
            </div>
            <div class="page_filter_action flex-grow-1" style="max-width: 109px;">
                <a href="javascript:void(0)"
                   class="btn btn-block btn-lg btn-primary btn-search"
                   (click)="onSearch()"
                   style="font-weight: normal;"
                   type="button">Buscar</a>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table_regular">
                <thead>
                <tr>
                    <th scope="col" class="w-10"></th>
                    <th scope="col" class="w-15">País</th>
                    <th scope="col">{{'unity' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(unitiesResult)">
                    <td colspan="3" class="text-center" style="padding: 68px 0 !important;"> nenhum registro encontrado
                    </td>
                </tr>
                <tr *ngFor="let u of unitiesResult">
                    <td>
                        <div class="d-flex justify-content-center" style="gap: 15px">
                            <svg-icon class="is-cursor" *ngIf="editUnity" (click)="updateUnity(u)" src="assets/svg/edit.svg"></svg-icon>
                            <svg-icon class="is-cursor" *ngIf="deleteUnity" (click)="onDelete(u.id)"
                                      src="assets/svg/close-circle.svg"></svg-icon>
                        </div>
                    </td>
                    <td>
                        <img style="border-radius: 100%;width: 31px;height: 31px;object-fit: cover"
                             [src]="u.country?.flag" alt="flag">
                    </td>
                    <td>{{u.name}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalUnity" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header align-items-center bg-gray" style="min-height: 107px;">
                <h2 class="modal-title">{{isNullOrUndefined(modelUnities.id) ? translate.instant('newUnity') : 'Editar unidade'}}</h2>
                <a data-dismiss="modal" class="modal_close" aria-label="Close">
                    <svg-icon src="assets/svg/close-modal.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body p-3 p-md-4">
                <div class="d-flex flex-wrap" style="column-gap: 30px">
                    <div class="flex-grow-1">
                        <div class="input-material">
                            <input id="nameUnity" [(ngModel)]="modelUnities.name" class="form-control" type="text"
                                   required/>
                            <label for="nameUnity">
                                Nome da unidade
                            </label>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <div class="input-material">
                            <select id="countryUnity" class="form-control" name="type"
                                    [(ngModel)]="createUnityCountryInput.id">
                                <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                                <option *ngFor="let i of countriesResult" [value]="i.id">{{i.name}}</option>
                            </select>
                            <label for="countryUnity">País</label>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-grow-1" style="gap: 30px;max-width: 394px;margin: 0 auto">
                    <a class="btn flex-grow-1 btn-outline-primary" data-dismiss="modal" aria-label="Close">Cancelar</a>
                    <a class="btn flex-grow-1 btn-primary" (click)="onSave();">Salvar</a>
                </div>
            </div>
        </div>
    </div>
</div>
