<div class="d-block d-xl-flex">
    <div style="width: 100%;min-width: 60%" class="px-3">

        <div class="row mt-4 mb-3 px-3 px-md-0">
            <div class="col-2">
                <a href="javascript:window.history.back();">
                    <svg-icon src="assets/svg/arrow.svg"></svg-icon>
                </a>
            </div>
            <div class="col-7 col-lg-8 text-center">
                <span class="header-title">Detalhes do Projeto</span>
            </div>
            <div class="col-3 col-lg-2">
                <span class="header-date">Criado em <strong>10/03/2021</strong></span>
            </div>
        </div>

        <div class="py-3 bg-beige rounded pb-5">
            <div class="nav_step-secondary shadow mx-auto mb-4">
                <ul class="nav d-flex justify-content-between" id="myTab" role="tablist">
                    <li class="text-center">
                        <a class="nav-link nav_step-secondary-item" id="step1-tab" data-toggle="tab" href="#step1"
                           role="tab"
                           aria-controls="step1" aria-selected="true">
                            <span class="step">1</span>
                            <div class="position_item">
                                <span class="position_item-stage">02/03/21</span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-secondary-item" id="step2-tab" data-toggle="tab" href="#step2"
                           role="tab"
                           aria-controls="step2" aria-selected="false">
                            <span class="step">2</span>
                            <div class="position_item">
                                <span class="position_item-stage">02/03/21</span>
                            </div>
                        </a>
                    </li>
                    <li class=" text-center">
                        <a class="nav-link nav_step-secondary-item" id="step3-tab" data-toggle="tab" href="#step3"
                           role="tab"
                           aria-controls="step3" aria-selected="false">
                            <span class="step">3</span>
                            <div class="position_item">
                                <span class="position_item-stage">02/03/21</span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-secondary-item" id="step4-tab" data-toggle="tab" href="#step4"
                           role="tab"
                           aria-controls="step4" aria-selected="false">
                            <span class="step">4</span>
                            <div class="position_item">
                                <span class="position_item-stage">02/03/21</span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-secondary-item active" id="step5-tab" data-toggle="tab"
                           href="#step5" role="tab"
                           aria-controls="step5" aria-selected="false">
                            <span class="step">5</span>
                            <div class="position_item">
                                <span class="position_item-stage">02/03/21</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

        </div>

        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="step5" role="tabpanel" aria-labelledby="step5-tab">
                <div class="row mt-4">
                    <div class="col-md-7">
                        <div class="px-3 py-4" style="max-height: 464px;overflow: auto;">
                            <div class="card bg-warning border-0 shadow-none">
                                <div class="row p-3">
                                    <strong class="col size-md">Criado por</strong>
                                    <span class="col text-right size-md">09/03/2021</span>
                                    <span class="col-12 size-xm pl-4">Marta Saldanha de Puela</span>
                                </div>
                            </div>
                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">Comercial Local</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <span class="col-12 mt-2">Nome do Projeto:
                                                <span class="text-primary">Lorem ipsum</span>
                                            </span>
                                            <span class="col-12 mt-2">Código:
                                                <span class="text-primary">368514</span>
                                            </span>
                                            <div class="col-12 mt-2">
                                                <img class="mr-2" src="assets/images/country.jpg" alt="country"/>
                                                <span>País de Origem:
                                                    <span class="text-primary">Peru</span>
                                                </span>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">AR Local</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <span class="col-12 mt-2">Motivo da solicitação: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">CT</span>
                                            <span class="col-12 mt-2">Canal de distribuição: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">CT</span>
                                            <span class="col-12 mt-2">{{'description' | translate}}: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">DEXMEDETOMIDINA 100MCG INJ 5FA AL</span>
                                            <span class="col-12 mt-2">Unidade de medida básica: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">CT</span>
                                            <span class="col-12 mt-2">Nome do detentor do registro: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary">Eurofarma Uruguay S.A.</span>
                                            <span class="col-12 mt-2">Expedido por: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary">Eurofarma</span>
                                            <span class="col-12 mt-2">Registro Ministério da Saúde: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary">Lorem ipsum dolor sit amet, consetetur</span>
                                            <span class="col-12 mt-2">Validade do registro: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary">25/06/2021</span>
                                            <span class="col-12 mt-2">Fabricante para comprar produto ou Subcontratação: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary">Eurofarma Laboratorios S.A.</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">Artes</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <span class="col-12 mt-2">EAN 13:
                                                <span class="text-primary">3365987541478</span>
                                            </span>
                                            <span class="col-12 mt-2">DUM 14:
                                                <span class="text-primary">52589658745214</span>
                                            </span>
                                        </div>
                                    </div>
                                    <strong class="col-12 mt-3 mb-2">Cadastros Unificados</strong>
                                    <div class="col-12">
                                        <div class="card p-2 pl-4 mt-3 pl-sm-3">
                                            <div class="row">
                                                <div class="col d-flex align-items-center justify-content-center pr-2">
                                                    <img style="width: 42px;" class="img-fluid"
                                                         src="assets/images/country.jpg">
                                                </div>
                                                <div class="col-sm-6 ml-0 pl-0 mt-3 mt-sm-0">
                                                    <div class="row">
                                                        <span class="col-12 text-gray-text">Guatemala</span>
                                                        <span class="col-12 text-gray-text">Fórmula: <strong>Dipirona Monoidratada</strong></span>
                                                        <span class="col-12 text-gray-text">Prazo de validade: <strong>12 meses</strong></span>
                                                    </div>

                                                </div>
                                                <div class="col-sm-4 d-flex align-items-center justify-content-center justify-content-lg-end mt-4 mt-sm-0">
                                                    <div class="row text-center">
                                                        <span class="col-12 text-gray-text size-xs">Preenchido até</span>
                                                        <span class="col-12 d-flex align-items-center justify-content-center text-primary">
                                                            <span class="d-flex justify-content-center align-items-center bg-warning rounded-circle mr-2"
                                                                  style="width: 25px;height: 25px;">
                                                                <strong>3</strong>
                                                            </span>
                                                            <strong>AR Interinacional</strong>
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="card p-2 pl-4 mt-3 pl-sm-3">
                                            <div class="row">
                                                <div class="col d-flex align-items-center justify-content-center pr-2">
                                                    <img style="width: 42px;" class="img-fluid"
                                                         src="assets/images/country.jpg">
                                                </div>
                                                <div class="col-sm-6 ml-0 pl-0 mt-3 mt-sm-0">
                                                    <div class="row">
                                                        <span class="col-12 text-gray-text">Guatemala</span>
                                                        <span class="col-12 text-gray-text">Fórmula: <strong>Dipirona Monoidratada</strong></span>
                                                        <span class="col-12 text-gray-text">Prazo de validade: <strong>12 meses</strong></span>
                                                    </div>

                                                </div>
                                                <div class="col-sm-4 d-flex align-items-center justify-content-center justify-content-lg-end mt-4 mt-sm-0">
                                                    <div class="row text-center">
                                                        <span class="col-12 text-gray-text size-xs">Preenchido até</span>
                                                        <span class="col-12 d-flex align-items-center justify-content-center text-primary">
                                                            <span class="d-flex justify-content-center align-items-center bg-warning rounded-circle mr-2"
                                                                  style="width: 25px;height: 25px;">
                                                                <strong>3</strong>
                                                            </span>
                                                            <strong>AR Interinacional</strong>
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-5">
                        <div class="pl-5 my-4 pt-2 pr-5 border-left border-gray-text">

<!--                            <div class="bg-success text-white rounded d-flex justify-content-center px-2 mb-2" style="width: fit-content">-->
<!--                                <svg-icon src="assets/svg/check.svg"></svg-icon>-->
<!--                                <span class="ml-1">Concluído</span>-->
<!--                            </div>-->

                            <div class="bg-danger text-white rounded d-flex justify-content-center px-2 mb-2"
                                 style="width: fit-content">
                                <svg-icon src="assets/svg/close.svg"></svg-icon>
                                <span class="ml-1">Reprovado</span>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="SAP" value="Lorem ipsum dolor sit amet" type="text"
                                       disabled="disabled" required/>
                                <label for="SAP" style="top:4px;">Códogo do SAP</label>
                            </div>
                            <div class="text-left mb-2">
                                <span class="text-secondary">Descritivo</span>
                            </div>
                            <textarea class="input-material mb-0" id="description3" disabled="disabled"
                                      style="width: 100%; resize: none"
                                      rows="7"
                                      [value]="'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam lacinia, leo vel consequat luctus, sapien ligula ornare leo, at tempor felis libero in massa.'"
                                      maxlength="40" required></textarea>
                            <label class="w-100 text-right" for="description3">Máximo de 40 caracteres</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



