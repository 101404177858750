<header class="header d-flex justify-content-between align-items-center">
  <span class="d-flex d-lg-none d-flex justify-content-center align-items-center" (click)="actionNavToggle()">
    <div class="hamburger d-flex d-lg-none">
      <span class="bar"></span>
    </div>
  </span>
    <div class="d-flex align-items-center" style="gap: 15px">
        <svg-icon src="assets/svg/logo-eurofarma.svg"></svg-icon>
        <div class="header-divider d-none d-sm-block"></div>
        <span class="header-subtitle d-none d-sm-block">Sistema Clic</span>
    </div>
    <div class="d-none d-lg-flex">
        <a href="#" class="d-none d-lg-block header__user">
            <span>{{'headerSalutation' | translate}}, <strong>{{user.name}}</strong></span>
            <img src="{{!user?.photo ? 'https://www.pontoxtecidos.com.br/static/567/sku/164485009798408.jpg' : user?.photo}}"
                 class="profile_photo"/>
        </a>
        <div class="header__btn">
            <app-notification></app-notification>
        </div>
        <div class="header__btn pr-0">
            <font class="header_font">{{'idioma' | translate}}:</font>
            <a class="header_font" href="javascript:void('');" [ngClass]="{'active' : translate.currentLang === 'pt'}"
               (click)="setLanguage('pt')">PT</a>
            <span class="separator">|</span>
            <a class="header_font" href="javascript:void('');" [ngClass]="{'active' : translate.currentLang === 'es'}"
               (click)="setLanguage('es')">ES</a>
        </div>
        <div class="header__btn">
            <a href="javascript:void('sair');" (click)="actionLogout()"  class="d-none d-lg-flex header__user__outbtn">
                <strong class="header__user__outbtn__text">{{'sair' | translate}}</strong>
            </a>
        </div>
    </div>
</header>
