import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {
    AcceptIdeaCommitteeInput,
    AcceptIdeaGQL,
    AcceptIdeaInput,
    ActionStatusGQL,
    ApprovedIdeaGQL, ApproveIdeaExpectationsInput,
    ApproveIdeaInput,
    AwardIdeaGQL, Challenge,
    Committee,
    CommitteesGQL,
    CreateIdeaConclusionGQL,
    CreateIdeaConclusionIdeaInput,
    CreateIdeaConclusionInput,
    DefaultMessage, DenyIdeaGQL, GetChallengesByLanguageAllGQL, GetChallengesByLanguageAllQuery,
    GetIdeaGQL,
    GetIdeaQuery, GetSustainabilitiesGQL,
    Idea,
    IdeaConclusion,
    RemoveFileGQL,
    ReproveIdeaGQL,
    UpdateIdeaCommitteeGQL,
    ReproveIdeaInput, SearchIdeaInput, Sustainability,
    TranslateIdeaChallengeInput, TranslateIdeaExpectationInput,
    TranslateIdeaGQL,
    TranslateIdeaInput, Unity, UpdateIdeaCommitteeInput,
    UpdateIdeaConclusionGQL,
    UpdateIdeaConclusionInput,
    UpdateIdeaImplementationGQL,
    UpdateIdeaImplementationInput, User,
    ValidateApproveGQL,
    ValidateApproveQuery
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {UserService} from '../../service/user.service';
import {AnimationOptions} from 'ngx-lottie';
import moment from 'moment';

declare var $: any;

@Component({
    selector: 'app-my-list-detail',
    templateUrl: './my-list-detail.component.html',
    styleUrls: ['./my-list-detail.component.scss']
})
export class MyListDetailComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    step = 1;
    user: User = new User();
    paramId = null;
    getIdeaQuery: QueryRef<GetIdeaQuery>;
    validateApproveQuery: QueryRef<ValidateApproveQuery>;
    resultApprove: DefaultMessage = new DefaultMessage();
    idea: Idea = new Idea();
    committees: Committee[] = [];
    sustainabilities: Sustainability[] = [];
    acceptIdeaInput: AcceptIdeaInput = new AcceptIdeaInput();
    committeeId: string;
    committeeUpdate: string;
    updateIdeaCommitteInput: UpdateIdeaCommitteeInput = new UpdateIdeaCommitteeInput();
    modelTag: string;
    approveIdea: Idea = new Idea();
    ideaConclusion: IdeaConclusion = new IdeaConclusion();
    reproveIdeaInput: ReproveIdeaInput = new ReproveIdeaInput();
    actionStatus: string[] = [];
    validateStatus: boolean;

    file: File = null;
    listFiles = [];
    isUpload = false;

    culture: string;

    getChallengeAllQuery: QueryRef<GetChallengesByLanguageAllQuery>;

    challengeResult: Challenge[] = [];

    translateIdeaInputBR: TranslateIdeaInput = new TranslateIdeaInput();
    translateIdeaInputES: TranslateIdeaInput = new TranslateIdeaInput();

    challengeIdBR: string;
    challengeIdES: string;

    financialVoteDescription: string;
    productivityVoteDescription: string;
    sustainabilityVoteDescription: string;

    accessAnimation: AnimationOptions = {
        path: '/assets/lottie/trophy.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
    };

    productivityVote = '';
    productivityVotes = [
        {id: 'not attend', value: 0},
        {id: 'parctially attends', value: 10},
        {id: 'attend', value: 15},
        {id: 'overcomes', value: 21}
    ];

    sustainabilityVote = '';
    sustainabilityVotes = [
        {id: 'not attend', value: 0},
        {id: 'parctially attends', value: 5},
        {id: 'attend', value: 9},
        {id: 'overcomes', value: 15}
    ];

    financialVote = '';
    financialVotes = [
        {id: 'not attend', value: 0},
        {id: 'parctially attends', value: 12},
        {id: 'attend', value: 21},
        {id: 'overcomes', value: 31}
    ];

    points = [
        {min: 1, max: 12, value: 984.40, taxValue: 68.91, equip: 1476.60, taxEquip: 103.36},
        {min: 13, max: 27, value: 2035.50, taxValue: 142.49, equip: 3053.25, taxEquip: 213.73},
        {min: 28, max: 37, value: 3438.50, taxValue: 240.70, equip: 5157.75, taxEquip: 361.04},
        {min: 38, max: 52, value: 4853.00, taxValue: 339.71, equip: 7279.50, taxEquip: 509.57},
        {min: 53, max: 99, value: 6325.00, taxValue: 442.75, equip: 9487.50, taxEquip: 664.13},
    ];

    dropSettings: any = {
        singleSelection: false,
        idField: 'id',
        textField: 'type',
        selectAllText: 'Marcar todos',
        unSelectAllText: 'Desmarcar todos',
        itemsShowLimit: 3,
        allowSearchFilter: true
    };

    listStatusItems = [];
    approveIdeaInput: ApproveIdeaInput = new ApproveIdeaInput();
    ApproveIdeaExpectationsInput: ApproveIdeaExpectationsInput = new ApproveIdeaExpectationsInput();

    modelSonsBR: Idea = new Idea();
    modelSonsES: Idea = new Idea();

    tags: boolean;
    goCommitte: boolean;
    whichCommitee: boolean;
    reproveCommitte: boolean;
    aproveonAnalysis: boolean;
    finalizeAction: boolean;
    finishAwards: boolean;
    editAction: boolean;
    definitionCommitte: boolean;
    concludeIdea: boolean;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public route2: ActivatedRoute,
                private _location: Location,
                public userService: UserService,
                private getIdeaGQL: GetIdeaGQL,
                private committeesGQL: CommitteesGQL,
                private getSustainabilitiesGQL: GetSustainabilitiesGQL,
                private validateApproveGQL: ValidateApproveGQL,
                private acceptIdeaGQL: AcceptIdeaGQL,
                private approvedIdeaGQL: ApprovedIdeaGQL,
                private createIdeaConclusionGQL: CreateIdeaConclusionGQL,
                private translateIdeaGQL: TranslateIdeaGQL,
                private getChallengesByLanguageAllGQL: GetChallengesByLanguageAllGQL,
                private awardIdeaGQL: AwardIdeaGQL,
                private updateIdeaImplementationGQL: UpdateIdeaImplementationGQL,
                private updateIdeaConclusionGQL: UpdateIdeaConclusionGQL,
                private reproveIdeaGQL: ReproveIdeaGQL,
                private UpdateIdeaCommitteeGQL: UpdateIdeaCommitteeGQL,
                private denyIdeaGQL: DenyIdeaGQL,
                private actionStatusGQL: ActionStatusGQL,
                private removeFileGQL: RemoveFileGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.userProfile();
            }, error: err => super.onError(err)
        });
        this.route2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
                this.paramId = url.id;
                this.loading.show();
                this.getIdeaQuery = this.getIdeaGQL.watch({id: url.id});
                this.getIdeaQuery.valueChanges.subscribe(({data}) => {
                    this.idea = data.getIdea as Idea;
                    this.validateStatus = this.handlerStatus();
                    this.getEnumType();
                    if (data.getIdea.status === 'received') {
                        this.getCommittees();
                    }
                    if (data.getIdea.status === 'analysis') {
                        this.getCommittees();
                        this.committeeUpdate = this.idea.committee.id;
                    }
                    this.modelPtEs();
                    this.getSustainabilities();
                    this.loading.hide();
                }, (error) => {
                    super.onError(error);
                    this.router.navigate(['/my-list'])
                });
            }
        });
    }

    modelPtEs() {
        this.modelSonsBR = this.getSon('pt-BR')
        this.modelSonsES = this.getSon('es-ES')
    }

    getSon(language: string) {
        const indexSons = this.idea.sons.findIndex((e) => e.challengeLanguage === language)
        if (indexSons !== -1) {
            return JSON.parse(JSON.stringify(this.idea.sons[indexSons]))
        } else {
            return JSON.parse(JSON.stringify(this.idea))
        }
    }

    ngAfterViewInit(): void {

    }

    ngOnDestroy(): void {
        const elModalCommittee = $('#ModalCommittee');
        const elModalRefusal = $('#ModalRefusal');
        const elModalResume = $('#ModalResume');
        if (elModalCommittee[0]) {
            elModalCommittee.remove();
        }
        if (elModalRefusal[0]) {
            elModalRefusal.remove();
        }
        if (elModalResume[0]) {
            elModalResume.remove();
        }
    }

    onChangeExpectation(x: any) {
        x.isSelected = !x.isSelected;
    }

    getCommittees() {
        this.committeesGQL.watch({}).valueChanges.subscribe(({data}) => {
            this.committees = data.committees as Committee[];
    });
    }

    getSustainabilities() {
        this.getSustainabilitiesGQL.watch({}).valueChanges.subscribe(({data}) => {
            this.sustainabilities = data.sustainabilities as Sustainability[];
        });
    }

    actionRemoveTag(o: any) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar essa tag?', () => {
            this.acceptIdeaInput.tags = this.acceptIdeaInput.tags.filter((item) => item !== o);
        });
    }

    createTag(s: any) {
        if (this.isNullOrUndefined(this.modelTag) || this.isNullOrUndefined(this.modelTag.trim())) {
            return;
        }
        this.acceptIdeaInput.tags = !this.isNullOrUndefined(this.acceptIdeaInput.tags) ? this.acceptIdeaInput.tags : [];
        if (this.acceptIdeaInput.tags.some(x => x === s)) {
            this.showMessage('Atenção', `Já existe uma tag cadastrada.`);
            return;
        }
        this.acceptIdeaInput.tags = !this.isNullOrUndefined(this.acceptIdeaInput.tags) ? Object.assign([], this.acceptIdeaInput.tags) : [];
        this.acceptIdeaInput.tags.push(s);
        this.modelTag = null;
    }

    onAcceptIdea() {
        const arrValidateFields = [
            {value: this.acceptIdeaInput.tags, text: 'Tags<br>'},
            {value: this.committeeId, text: 'Comitê<br>'},
        ];
        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br> ${stringError}`);
            return;
        }
        const acceptIdeaCommitteeInput: AcceptIdeaCommitteeInput = {id: this.committeeId};
        this.acceptIdeaInput.id = this.paramId;
        this.acceptIdeaInput.committee = acceptIdeaCommitteeInput;
        this.loading.show();
        this.acceptIdeaGQL.mutate({input: this.acceptIdeaInput}).subscribe(
            ({data}) => {
                this.getIdeaQuery.refetch({id: data.acceptIdea.id});
                this.loading.hide();
                this.showMessage('Sucesso!', `Ideia aceita com sucesso!`, 'success');
            }, err => super.onError(err));
    }

    getActionByType(type: string) {
        return this.idea.action.filter((e) => e.status === type);
    }

    onAnalysis(id) {
        this.validateApproveGQL.watch({id}).valueChanges.subscribe(({data}) => {
            this.resultApprove = data.validateApproveIdea as DefaultMessage;
            if (this.resultApprove.status === 200) {
                this.approveIdea.implementationResume = undefined;
                this.showModal('ModalResume');
                this.approveIdea.premiationDate = moment().format();
                this.setDate('picker-premiationDate', (start, end, label) => {
                    this.approveIdea.premiationDate = end.utcOffset(0, true).format();
                });
            } else {
                return;
            }
        });

    }

    onApproved() {
        this.productivityVote = null;
        this.sustainabilityVote = null;
        this.financialVote = null;

        this.ideaConclusion = new IdeaConclusion();
        this.ideaConclusion.prize = 0.0;
        // this.ideaConclusion.premiationDate = this.dateNow;
        // this.setDate('picker-period', (start, end, label) => {
        //     this.ideaConclusion.premiationDate = end.utcOffset(0, true).format();
        // }, this.ideaConclusion.premiationDate);
        this.financialVoteDescription = this.idea?.expectations.find(s => s.expectation == 'finance-admin')?.expectationDescription;
        this.productivityVoteDescription = this.idea?.expectations.find(s => s.expectation == 'productive-admin')?.expectationDescription;
        this.sustainabilityVoteDescription  = this.idea?.expectations.find(s => s.expectation == 'sustainability-admin')?.expectationDescription;
        this.showModal('ModalCommittee');
    }

    onAward() {
        super.confirmMessage('Atenção', 'Deseja finalizar a ideia ?', () => {
            this.loading.show();
            this.awardIdeaGQL.mutate({input: this.paramId}).subscribe(
                ({data}) => {
                    this.getIdeaQuery.refetch({id: data.awardIdea.id});
                    this.loading.hide();
                    this.showMessage('Sucesso!', `Premiação Concluida com sucesso!`, 'success');
                    this.showModalTranslateESPT(1);
                }, err => super.onError(err));
        });
    }

    showModalTranslate() {
        // if (!this.isNullOrUndefined(this.idea.sons)) {
        //     Object.assign(this.ideasSons, this.idea.sons);
        // } else {
        //     this.ideasSons = new Idea();
        //     this.ideasSons.expectations = this.idea.expectations;
        // }
        // this.getChanlenge();
        // super.showModal('modalTranslate');
    }

    clickStep(s: number) {
        this.step = s
        this.getChanlenge();
    }

    showModalTranslateESPT(x: number) {
        this.step = x;
        super.showModal('modalTranslateESPT');
        this.getChanlenge();
    }

    getChanlenge() {
        if (this.step === 1) {
            this.culture = 'pt-BR'
        } else {
            this.culture = 'es-ES'
        }
        this.getChallengeAllQuery = this.getChallengesByLanguageAllGQL.watch({input: this.culture});
        this.getChallengeAllQuery.valueChanges.subscribe(({data}) => {
            this.challengeResult = data.getChallengesByLanguageAll as Challenge[];
        })
    }

    ideaTranslate() {
        const arrValidateFields = [
            {value: this.challengeIdBR, text: 'Idea Português<br>'},
            {value: this.modelSonsBR.title, text: 'Título Português<br>'},
            {value: this.modelSonsBR.description, text: 'Descrição Português<br>'},
            {value: this.modelSonsBR.productiveDescription, text: 'Descrição Produtiva Português<br>'},
            {value: this.modelSonsBR.analysisDescription, text: 'Descrição de Analise Português<br>'},

            {value: this.challengeIdES, text: 'Idea Espanhol<br>'},
            {value: this.modelSonsES.title, text: 'Título Espanhol<br>'},
            {value: this.modelSonsES.description, text: 'Descrição Espanhol<br>'},
            {value: this.modelSonsES.productiveDescription, text: 'Descrição Produtiva Espanhol<br>'},
            {value: this.modelSonsES.analysisDescription, text: 'Descrição de Analise Espanhol<br>'},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br> ${stringError}`);
            return;
        }
        let translateIdeaExpectationInputBR: TranslateIdeaExpectationInput[] = [];
        this.modelSonsBR.expectations.forEach((e) => {
            translateIdeaExpectationInputBR.push({
                id: e.id,
                expectationDescription: e.expectationDescription,
                expectation: e.expectation
            })
        });
        const translateIdeaChallengeInputBR: TranslateIdeaChallengeInput = {id: this.challengeIdBR};
        this.translateIdeaInputBR = {
            challengeLanguage: 'pt-BR',
            challengeId: translateIdeaChallengeInputBR,
            analysisDescription: this.modelSonsBR.analysisDescription,
            description: this.modelSonsBR.description,
            expectation: translateIdeaExpectationInputBR,
            motive: this.modelSonsBR.motive,
            productiveDescription: this.modelSonsBR.productiveDescription,
            title: this.modelSonsBR.title,
            id: this.idea.id
        };

        let translateIdeaExpectationInputES: TranslateIdeaExpectationInput[] = [];
        this.modelSonsES.expectations.forEach((e) => {
            translateIdeaExpectationInputES.push({
                id: e.id,
                expectationDescription: e.expectationDescription,
                expectation: e.expectation
            })
        });

        const translateIdeaChallengeInputES: TranslateIdeaChallengeInput = {id: this.challengeIdES};
        this.translateIdeaInputES = {
            challengeLanguage: 'es-ES',
            challengeId: translateIdeaChallengeInputES,
            analysisDescription: this.modelSonsES.analysisDescription,
            description: this.modelSonsES.description,
            expectation: translateIdeaExpectationInputES,
            motive: this.modelSonsES.motive,
            productiveDescription: this.modelSonsES.productiveDescription,
            title: this.modelSonsES.title,
            id: this.idea.id
        };

        this.loading.show();
        this.translateIdeaGQL.mutate({input: [this.translateIdeaInputBR, this.translateIdeaInputES]}).subscribe(
            ({data}) => {
                this.getIdeaQuery.refetch({id: this.paramId}).then();
                this.closeModal('modalTranslateESPT');
                this.showMessage('Sucesso', `Idea atualizada com sucesso!`, 'success');
                this.loading.hide();
            }, err => super.onError(err));

    }

    onImplementation() {
        this.approveIdeaInput.sustainability = [];
        this.listStatusItems.forEach((e) => {
            this.approveIdeaInput.sustainability.push({id: e.id});
        });
        const arrValidateFields = [
            {value: this.approveIdea.implementationResume, text: 'Descrição<br>'},
            // {value: this.approveIdea.analysisFinacialreturn, text: 'Retorno Financeiro<br>'},
            // {value: this.approveIdea.analysisManValue, text: 'Valor H/Homem<br>'},
            // {value: this.approveIdea.analysisMachineValue, text: 'Valor H/Máquina<br>'},
            /*{value: this.approveIdea.analysisSustainability, text: 'Sustentabilidade<br>'},*/
            {value: this.approveIdea.premiationDate, text: 'Data da premiação<br>'},
        ];
        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br> ${stringError}`);
            return;
        }
        if (super.isNullOrUndefined(this.idea.implementationResume)) {
            this.createImplementation();
        } else {
            this.updateImplementation();
        }
    }

    createImplementation() {
        let approveIdeaExpectationsInput: ApproveIdeaExpectationsInput[] = [];

        this.idea.expectations.forEach((e) => {
            approveIdeaExpectationsInput.push({
                expectation: e.expectation + '-admin',
                expectationDescription: e.expectationDescription
            })
        })
        const createIdeaImplementationInput: ApproveIdeaInput = {
            id: this.paramId,
            implementationResume: this.approveIdea.implementationResume,
            analysisFinacialreturn: this.approveIdea.analysisFinacialreturn,
            analysisMachineValue: this.approveIdea.analysisMachineValue,
            analysisManValue: this.approveIdea.analysisManValue,
            analysisSustainability: this.approveIdea.analysisSustainability,
            premiationDate: this.approveIdea.premiationDate,
            sustainability: this.approveIdeaInput.sustainability,
            expectations: approveIdeaExpectationsInput,
            // expectations: [createExpectationF,createExpectationP,createExpectationS]
            // expectations: this.ApproveIdeaExpectationsInput,
            // analysisDescription: this.approveIdeaInput.analysisDescription,
        };

        console.log(createIdeaImplementationInput)
        this.loading.show();
        this.approvedIdeaGQL.mutate({input: createIdeaImplementationInput}).subscribe(
            ({data}) => {
                this.actionUploadFile(this.paramId, false);
                this.closeModal('ModalResume');
                this.loading.hide();
                this.showMessage('Sucesso!', `Ideia aprovada com sucesso!`, 'success');
            }, err => super.onError(err));
    }

    updateImplementation() {
        let approveIdeaExpectationsInput: ApproveIdeaExpectationsInput[] = [];

        this.idea.expectations.forEach((e) => {
            approveIdeaExpectationsInput.push({
                expectation: e.expectation,
                expectationDescription: e.expectationDescription
            })
        })
        const updateIdeaImplementationInput: UpdateIdeaImplementationInput = {
            id: this.paramId,
            implementationResume: this.approveIdea.implementationResume,
            analysisFinacialreturn: this.approveIdea.analysisFinacialreturn,
            analysisMachineValue: this.approveIdea.analysisMachineValue,
            analysisManValue: this.approveIdea.analysisManValue,
            analysisSustainability: this.approveIdea.analysisSustainability,
            premiationDate: this.approveIdea.premiationDate,
            sustainability: this.approveIdeaInput.sustainability,
            expectations: approveIdeaExpectationsInput,
            // analysisDescription: this.approveIdeaInput.analysisDescription,
        };

        this.loading.show();

        console.log(this.approveIdeaInput);

        this.updateIdeaImplementationGQL.mutate({input: updateIdeaImplementationInput}).subscribe(({data}) => {
            this.actionUploadFile(this.paramId, false);
            this.closeModal('ModalResume');
            this.loading.hide();
            this.showMessage('Sucesso!', `Implantação da idéia alterado com sucesso!`, 'success');
        }, err => super.onError(err));
    }

    onIdeaConclusion() {
        const arrValidateFields = [
            {value: this.productivityVote, text: 'Votação produtividade<br>'},
            {value: this.sustainabilityVote, text: 'Votação sustentabilidade<br>'},
            {value: this.financialVote, text: 'Votação retorno financeiro<br>'},
            // {value: this.ideaConclusion.prize, text: 'Valor do prêmio<br>'},
        ];

        if (this.financialVote !== 'not attend') {
            arrValidateFields.push(
                {value: this.financialVoteDescription, text: 'Descrição Retono Financeiro<br>'},
            )
        }
        if (this.sustainabilityVote !== 'not attend') {
            arrValidateFields.push(
                {value: this.sustainabilityVoteDescription, text: 'Descrição Sustentabilidade<br>'},
            )
        }
        if (this.productivityVote !== 'not attend') {
            arrValidateFields.push(
                {value: this.productivityVoteDescription, text: 'Descrição Aumento de Produtividade<br>'},
            )
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br> ${stringError}`);
            return;
        }

        this.ideaConclusion.productivityVote = this.productivityVote;
        this.ideaConclusion.sustainabilityVote = this.sustainabilityVote;
        this.ideaConclusion.financialVote = this.financialVote;


        if (this.financialVote === 'not attend') {
            this.ideaConclusion.financialVoteDescription = null;
        }
        if (this.sustainabilityVote === 'not attend') {
            this.ideaConclusion.sustainabilityVoteDescription = null;
        }
        if (this.productivityVote === 'not attend') {
            this.ideaConclusion.productivityVoteDescription = null;
        }

        if (super.isNullOrUndefined(this.ideaConclusion.id)) {
            this.CreateIdeaConclusion();
        } else {
            this.UpdateIdeaConclusion();
        }

    }

    CreateIdeaConclusion() {
        const createIdeaConclusionIdeaInput: CreateIdeaConclusionIdeaInput = {id: this.paramId};
        const createIdeaConclusionInput: CreateIdeaConclusionInput = {
            productivityVote: this.ideaConclusion.productivityVote,
            sustainabilityVote: this.ideaConclusion.sustainabilityVote,
            financialVote: this.ideaConclusion.financialVote,
            financialVoteDescription: this.financialVoteDescription,
            productivityVoteDescription: this.productivityVoteDescription,
            sustainabilityVoteDescription: this.sustainabilityVoteDescription,
            prize: this.ideaConclusion.prize,
            points: this.ideaConclusion.points,
            idea: createIdeaConclusionIdeaInput,
        };
        this.loading.show();
        this.createIdeaConclusionGQL.mutate({input: createIdeaConclusionInput}).subscribe(
            ({data}) => {
                this.closeModal('ModalCommittee');
                this.loading.hide();
                this.getIdeaQuery.refetch({id: this.paramId});
                // this.actionUploadFile(data.createIdeaConclusion.id);
                // this.showMessage('Sucesso!', `Ideia finalizada com sucesso!`, 'success');
            }, err => super.onError(err));
    }

    UpdateIdeaConclusion() {
        const createIdeaConclusionIdeaInput: CreateIdeaConclusionIdeaInput = {id: this.paramId};
        const updateIdeaConclusionInput: UpdateIdeaConclusionInput = {
            id: this.ideaConclusion.id,
            productivityVote: this.ideaConclusion.productivityVote,
            sustainabilityVote: this.ideaConclusion.sustainabilityVote,
            financialVote: this.ideaConclusion.financialVote,
            financialVoteDescription: this.financialVoteDescription,
            productivityVoteDescription: this.productivityVoteDescription,
            sustainabilityVoteDescription: this.sustainabilityVoteDescription,
            prize: this.ideaConclusion.prize,
            points: this.ideaConclusion.points,
            idea: createIdeaConclusionIdeaInput,
        };
        this.loading.show();
        this.updateIdeaConclusionGQL.mutate({input: updateIdeaConclusionInput}).subscribe(
            ({data}) => {
                this.closeModal('ModalCommittee');
                this.getIdeaQuery.refetch({id: this.paramId});
                this.loading.hide();
                // this.actionUploadFile(data.updateIdeaConclusion.id);
                // this.showMessage('Sucesso!', `Definição do comitê alterado com sucesso!`, 'success');
            }, err => super.onError(err));
    }

    onModalImplementation() {
        this.approveIdea = new Idea();
        this.listFiles = [];
        this.setDate('picker-premiationDate', (start, end, label) => {
            this.approveIdea.premiationDate = end.utcOffset(0, true).format();
        }, this.idea.premiationDate);
        this.approveIdea = this.idea;
        this.listStatusItems = this.idea.sustainability;
        this.showModal('ModalResume');
    }

    onEditDefinition() {
        this.idea.conclusion = Object.assign({}, this.idea.conclusion);
        this.ideaConclusion = this.idea.conclusion;
        if (!this.isNullOrUndefined(this.idea.conclusion, true)) {
            this.productivityVote = this.idea.conclusion.productivityVote;
            this.sustainabilityVote = this.idea.conclusion.sustainabilityVote;
            this.financialVote = this.idea.conclusion.financialVote;
            this.financialVoteDescription = this.idea.conclusion.financialVoteDescription;
            this.sustainabilityVoteDescription = this.idea.conclusion.sustainabilityVoteDescription;
            this.productivityVoteDescription = this.idea.conclusion.productivityVoteDescription;
        }
        // this.setDate('picker-period', (start, end, label) => {
        //     this.ideaConclusion.premiationDate = end.utcOffset(0, true).format();
        // }, this.ideaConclusion.premiationDate);

        this.showModal('ModalCommittee');
    }



    changeDenialStatus() {
        const idDenialStatus = this.paramId;
        this.denyIdeaGQL.mutate({id: idDenialStatus}).subscribe(
            ({data}) => {
                this.closeModal('ModalDenial');
                this.getIdeaQuery.refetch({id: this.paramId});
                this.loading.hide();
                this.showMessage('Sucesso!', `Sua Ideia mudou para reprovada com sucesso!`, 'success');
            }, err => super.onError(err));
    }

    onReprove(id) {
        if (this.idea.status === 'analysis') {
            this.showModal('ModalDenial');
        } else {
            // this.validateApproveGQL.watch({id}).valueChanges.subscribe(({data}) => {
            //     this.resultApprove = data.validateApproveIdea as DefaultMessage;
            // });
            // if (this.resultApprove.status === 200) {
            //     this.reproveIdeaInput = new ReproveIdeaInput();
            //     this.showModal('ModalRefusal');
            // } else {
            //     return;
            // }
            this.reproveIdeaInput = new ReproveIdeaInput();
            this.showModal('ModalRefusal');
        }
    }

// UPLOAD FILE MODAL
    handlerUpload(event) {
        const files = event.target.files;
        if (files.length > 0) {
            if (files[0].size > 60000000) {
                super.showMessage(this.translate.instant('baseComponent.warningAttention'),
                    this.translate.instant('baseComponent.warningMsgUploadFileMaxSize'), 'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (file) => {
            // this.pathImage = file.target.result;
            // this.pathImageObj[element].path = file.target.result;
            // this.pathImageObj[element].name = files[0].name;
            this.listFiles.push({file: files[0], path: file.target.result});

        };
        reader.readAsDataURL(event.target.files[0]);
        this.file = null;
        this.isUpload = false;
    }

    actionUploadFile(id: string, navigator = true) {
        if (this.listFiles.length === 0) {
            console.log('aqui');
            this.getIdeaQuery.refetch({id: this.paramId});
            this.loading.hide();
        }
        this.listFiles.forEach((x, index) => {
            this.userService.upload(x.file, id, 'idea').subscribe({
                next: data => {
                    if (this.listFiles.length === (index + 1)) {
                        console.log('aqui');
                        this.isUpload = true;
                        this.loading.hide();
                        this.getIdeaQuery.refetch({id: this.paramId});
                        this.listFiles = [];
                        if (navigator) {
                            this.router.navigate(['/my-list']);
                        }
                    }
                }, error: err => super.onError(err)

            });
        });
    }

    calcAward() {
        this.ideaConclusion.prize = 0.0;
        const p = this.productivityVote == null ? 0 : this.productivityVotes.find(s => s.id === this.productivityVote).value;
        const s = this.sustainabilityVote == null ? 0 : this.sustainabilityVotes.find(s => s.id === this.sustainabilityVote).value;
        const f = this.financialVote == null ? 0 : this.financialVotes.find(s => s.id === this.financialVote).value;

        const total = (p + s + f);

        const point = this.points.filter((v) => {
            return (total >= v.min && total <= v.max);

        });
        this.ideaConclusion.points = total
        if (point.length !== 0) {
            if ((this.idea.authors.length + 1) > 1) {
                this.ideaConclusion.prize = point[0].equip;
            } else {
                this.ideaConclusion.prize = point[0].value;
            }
        }
    }

    handlerStatus() {
        switch (this.idea.status) {
            case 'awarded':
                return false;
            default:
                return true;
        }
    }

    getEnumType() {
        this.actionStatusGQL.watch().valueChanges.subscribe(({data}) => {
            this.actionStatus = data.actionStatus as string[];
        }, error => this.onError(error));
    }

    onRemoveFileList(i: number) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar esse arquivo?', () => {
            this.listFiles.splice(i, 1);
        });
    }

    onRemoveFile(id: string, i: number) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar esse arquivo?', () => {
            this.removeFileGQL.mutate({id}).subscribe(({data}) => {
                this.approveIdea.ideafiles.splice(i, 1);
                this.showMessage('Sucesso!', 'Arquivo deletado com sucesso!', 'success');
            }, error => super.onError(error));
        });
    }

    onSelectDrop(item: any): void {
    }

    handlerSustainability(sustainability: Array<Sustainability>) {
        var response = [];
        for (let s of sustainability) {
            const x = this.getTranslate(s.type)
            response.push(x);
        }
        return response.join('|');
    }

    userProfile() {
        switch (this.user.role) {
            case 'admin':
                this.tags = true;
                this.whichCommitee = true;
                this.reproveCommitte = true;
                this.goCommitte = true;
                this.finalizeAction = true;
                this.aproveonAnalysis = true;
                this.finishAwards = true;
                this.editAction = true;
                this.definitionCommitte = true;
                this.concludeIdea = true;
                break;
            case 'user':
                this.tags = true;
                this.whichCommitee = true;
                this.reproveCommitte = true;
                this.goCommitte = true;
                this.finalizeAction = true;
                this.aproveonAnalysis = true;
                this.finishAwards = true;
                this.editAction = true;
                this.definitionCommitte = true;
                this.concludeIdea = true;
                break;
            case 'corporate-manager':
                this.tags = true;
                this.whichCommitee = true;
                this.reproveCommitte = true;
                this.goCommitte = true;
                this.finalizeAction = true;
                this.aproveonAnalysis = true;
                this.finishAwards = true;
                this.editAction = true;
                this.definitionCommitte = true;
                this.concludeIdea = true;
                break;
            case 'local-manager':
                this.tags = true;
                this.whichCommitee = true;
                this.reproveCommitte = true;
                this.goCommitte = true;
                this.finalizeAction = true;
                this.aproveonAnalysis = true;
                this.finishAwards = true;
                this.editAction = true;
                this.definitionCommitte = true;
                this.concludeIdea = true;
                break;
            case 'committee-member':
                this.tags = false;
                this.whichCommitee = true;
                this.reproveCommitte = true;
                this.goCommitte = true;
                this.finalizeAction = false;
                this.aproveonAnalysis = false;
                this.finishAwards = false;
                this.editAction = false;
                this.definitionCommitte = false;
                this.concludeIdea = true;
                break;
        }
    }

    reproveIdea() {
        const arrValidateFields = [
            {value: this.reproveIdeaInput.motive, text: 'Descreva aqui<br>'},
        ];
        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br> ${stringError}`);
            return;
        }
        this.reproveIdeaInput.id = this.paramId;
        this.reproveIdeaGQL.mutate({input: this.reproveIdeaInput}).subscribe(
            ({data}) => {
                this.closeModal('ModalRefusal');
                this.getIdeaQuery.refetch({id: this.paramId});
                this.loading.hide();
                this.showMessage('Sucesso!', `Ideia reprovada com sucesso!`, 'success');
            }, err => super.onError(err));
    }
    UpdateCommittee() {
        this.updateIdeaCommitteInput = {id: this.idea.id, committe: {id: this.committeeUpdate}};

        this.UpdateIdeaCommitteeGQL.mutate({input: this.updateIdeaCommitteInput}).subscribe(
            async ({data}) => {
                location.reload();
            }, err => super.onError(err));
    }
}
