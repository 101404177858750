<div class="page">
    <div class="page_header">
        <h1 class="page_title">Clic News</h1>
        <div class="page_action" style="gap: 20px">
            <!--<a href="javascript:void(0)" (click)="actionClear()"
               class="page_action_excel btn btn-gray-fill"
               type="button">
                <svg-icon src="assets/svg/download.svg"></svg-icon>
                <span class="font-weight-normal">Exportar Excel</span>
            </a>-->
            <a href="javascript:void(0)" *ngIf="newClicNews" [routerLink]="'/clic-news-detail/register'"
               class="btn btn-warning d-flex align-items-center" style="gap: 10px"
               type="button">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                <span class="font-weight-normal">{{'newNews' | translate}}</span>
            </a>
        </div>
    </div>
    <div class="page_padding">
        <div class="page_filter mb-4">
            <div class="input-material">
                <input id="search" class="form-control" type="text" required
                       (keyup.enter)="actionSearch()"
                       [(ngModel)]="modelFilterKeyword"/>
                <label for="search">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    Busca
                </label>
            </div>
            <!--<div class="input-material">
                <select id="unity" class="form-control" name="type">
                    <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option *ngFor="let u of [1,2,3,4,5]" [value]="u">{{u}}</option>
                </select>
                <label for="unity" class="mb-0 mr-3">Área</label>
            </div>-->
            <!--<div class="input-material">
                <select id="committee" class="form-control" name="type">
                    <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option *ngFor="let u of [1,2,3,4,5]" [value]="u">{{u}}</option>
                </select>
                <label for="committee" class="mb-0 mr-3">Comitê</label>
            </div>-->
            <div class="input-material">
                <select id="status" class="form-control" name="type" [(ngModel)]="modelFilterStatus">
                    <option [ngValue]="''">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option [value]="'published'">Publicado</option>
                    <option [value]="'unpublished'">Não Publicado</option>
                </select>
                <label for="status" class="mb-0 mr-3">Status</label>
            </div>
            <div class="page_filter_action flex-grow-1" style="max-width: 109px;">
                <a href="javascript:void(0)" (click)="actionSearch()"
                   class="btn btn-block btn-lg btn-primary btn-search"
                   style="font-weight: normal;"
                   type="button">Buscar</a>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table_regular">
                <thead>
                <tr>
                    <th class="text-center w-10" scope="col"></th>
                    <th class="text-center w-5" scope="col">Status</th>
                    <!--<th class="text-center w-10" scope="col">Código</th>-->
                    <th class="text-center" scope="col">{{'publicationDate' | translate}}</th>
                    <th scope="col">Título</th>
                    <th scope="col">Autor</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(news.news)">
                    <td colspan="8" class="text-center" style="padding: 68px 0;"> nenhum registro encontrado</td>
                </tr>
                <tr *ngFor="let a of news.news |
                paginate: {itemsPerPage: news.pageSize,
                currentPage: news.currentPage,
                totalItems: news.totalCount }">
                    <td>
                        <div class="d-flex justify-content-center" style="gap: 15px">
                            <svg-icon class="is-cursor" *ngIf="editClicNews" src="assets/svg/edit.svg"
                                      [routerLink]="['/clic-news-detail/' + a.id]"></svg-icon>
                            <svg-icon (click)="removeNews(a.id)" *ngIf="deleteClicNews" class="is-cursor"
                                      src="assets/svg/close-circle.svg"></svg-icon>
                        </div>
                    </td>
                    <td class="text-center">
                        <label class="switch disabled cursor-default">
                            <input type="checkbox" name="checkbox"
                                   [checked]="a.status === 'published'"
                                   [disabled]="true">
                            <span class="slider round cursor-default"></span>
                        </label>
                    </td>
                    <!--<td class="text-center">
                        00000
                    </td>-->
                    <td class="text-center">
                        {{a.createdAt|date:'dd/MM/yyyy'}}
                    </td>
                    <td>
                        {{a.title}}
                    </td>
                    <td class="idea-item_text">
                        {{a.author}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div>
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>
