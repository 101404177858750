import {AfterViewInit, Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {DashboardService} from '../../service/dashboard.service';
import {UserService} from '../../service/user.service';
import {Home, HomeGQL, HomeQuery, SearchAllIdeasQuery, SearchIdeaInput, User} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';

declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    searchIdeaHomeInput: SearchIdeaInput = new SearchIdeaInput();

    getIdeasHomeSearchQuery: QueryRef<HomeQuery>;
    user: User = new User();
    home: Home = new Home();
    page = 1;
    ideasHomeQuery: QueryRef<HomeQuery>;

    listHome: boolean;
    kanbanHome: boolean;
    newsHome: boolean;
    dashboardHome: boolean;
    managementHome: boolean;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public userService: UserService,
                public homeGQL: HomeGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit() {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.userProfile();
            }, error: err => super.onError(err)
        });

        $('#sidebar').addClass('is-hide');
        this.loading.show();
        this.ideasHomeQuery = this.homeGQL.watch({page: this.page});
        this.ideasHomeQuery.valueChanges.subscribe(({data}) => {
            this.home = data?.home as Home;
            this.loading.hide();
        });
    }

    ngAfterViewInit() {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    // this.btnNew = data.role === 'local' || data.role === 'admin';
                }
            }
        });
    }

    actionSearch() {
        this.loading.show();
        this.ideasHomeQuery.refetch({page: this.page}).finally(() => this.loading.hide());
    }

    ngOnDestroy(): void {
        $('#sidebar').removeClass('is-hide');
    }

    pageChanged(event) {
        this.page = event;
        this.actionSearch();
    }

    userProfile() {
        switch (this.user.role) {
            case 'admin':
                this.listHome = true;
                this.kanbanHome = true;
                this.newsHome = true;
                this.dashboardHome = true;
                this.managementHome = true;
                break;
            case 'user':
                this.listHome = true;
                this.kanbanHome = true;
                this.newsHome = true;
                this.dashboardHome = true;
                this.managementHome = true;
                break;
            case 'corporate-manager':
                this.listHome = true;
                this.kanbanHome = true;
                this.newsHome = true;
                this.dashboardHome = true;
                this.managementHome = true;
                break;
            case 'local-manager':
                this.listHome = true;
                this.kanbanHome = true;
                this.newsHome = true;
                this.dashboardHome = true;
                this.managementHome = true;
                break;
            case 'committee-member':
                this.listHome = true;
                this.kanbanHome = true;
                this.newsHome = false;
                this.dashboardHome = true;
                this.managementHome = false;
                break;
        }
    }
}



