import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {LogoutComponent} from './views/logout/logout.component';
import {RegisterComponent} from './views/register/register.component';
import {HomeComponent} from './views/home/home.component';
import {MyListComponent} from './views/my-list/my-list.component';
import {RegisterDetailComponent} from './views/register-detail/register-detail.component';
import {UserComponent} from './views/user/user.component';
import {AuthenticateComponent} from './views/authenticate/authenticate.component';
import {KambanComponent} from './views/kamban/kamban.component';
import {MyListDetailComponent} from './views/my-list-detail/my-list-detail.component';
import {ClicNewsComponent} from './views/clic-news/clic-news.component';
import {ClicNewsDetailComponent} from './views/clic-news-detail/clic-news-detail.component';
import {AreaComponent} from './views/area/area.component';
import {UnityComponent} from './views/unity/unity.component';
import {CommitteeComponent} from './views/committee/committee.component';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {ChallengeComponent} from './views/challenge/challenge.component';

const routes: Routes = [
    {
        path: 'authenticate', component: AuthenticateComponent
    },
    {
        path: 'logout', component: LogoutComponent
    },
    {
        path: '', component: MainComponent,
        children: [
            {path: 'register', component: RegisterComponent},
            {path: 'register/:id', component: RegisterComponent},
            {path: 'register-detail', component: RegisterDetailComponent},
            {path: 'my-list', component: MyListComponent},
            {path: 'my-list-detail/:id', component: MyListDetailComponent},
            {path: 'kanban', component: KambanComponent},
            {path: 'kanban/:id', component: KambanComponent},
            {path: 'user', component: UserComponent},
            {path: 'clic-news', component: ClicNewsComponent},
            {path: 'clic-news-detail/:id', component: ClicNewsDetailComponent},
            {path: 'challenge', component: ChallengeComponent},
            {path: 'area', component: AreaComponent},
            {path: 'unity', component: UnityComponent},
            {path: 'committee', component: CommitteeComponent},
            {path: 'dashboard', component: DashboardComponent},
            {path: ':id', component: HomeComponent},
            {path: '', component: HomeComponent}
        ],
        // canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        relativeLinkResolution: 'legacy',
        scrollOffset: [0, 0],
        scrollPositionRestoration: 'top'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
