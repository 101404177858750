import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {
    Area,
    AreasGQL,
    AreasQuery,
    Committee,
    CreateCommitteeAreaInput, CreateCommitteeGQL,
    CreateCommitteeInput,
    CreateCommitteeManagerInput,
    CreateCommitteeParticipantInput, CreateCommitteeUnityInput, GetUnitiesGQL,
    GetUsersGQL,
    GetUsersQuery, RemoveCommitteeGQL,
    SearchCommittee,
    SearchCommitteeGQL,
    SearchCommitteeQuery, Unity, UpdateCommitteeGQL, UpdateCommitteeInput,
    User
} from '../../../generated/graphql';
import {Observable} from 'rxjs';
import {QueryRef} from 'apollo-angular';
import {ExcelService} from "../../service/excel.service";
import {UserService} from 'src/app/service/user.service';
import moment from "moment";

declare var $: any;

@Component({
    selector: 'app-committee',
    templateUrl: './committee.component.html',
    styleUrls: ['./committee.component.scss']
})
export class CommitteeComponent extends BaseComponent implements OnInit, OnDestroy {
    user: User = new User();
    searchCommitteeQuery: QueryRef<SearchCommitteeQuery>;
    getUsersQuery: QueryRef<GetUsersQuery>;
    committee: Array<Committee>;
    modelCommittee: Committee = new Committee();
    createCommitteeAreaInput: CreateCommitteeAreaInput = new CreateCommitteeAreaInput();
    createCommitteeUnityInput: CreateCommitteeUnityInput = new CreateCommitteeUnityInput();
    createCommitteeManagerInput: CreateCommitteeManagerInput = new CreateCommitteeManagerInput();
    searchCommittee: SearchCommittee = new SearchCommittee();
    listArea: Area[] = [];
    listUnity: Unity[] = [];
    listUsers: User[] = [];
    userSelected: User;
    participants: User[] = [];

    dropdownParticipantSettings = {};

    buttonExcel: boolean;
    editCommittee: boolean;
    addCommittee: boolean;
    deleteCommittee: boolean;
    unityCommittee: boolean;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public userService: UserService,
                public translate: TranslateService,
                public searchCommitteeGQL: SearchCommitteeGQL,
                public areasGQL: AreasGQL,
                public unitiesGQL: GetUnitiesGQL,
                public getUsersGQL: GetUsersGQL,
                public createCommitteeGQL: CreateCommitteeGQL,
                public updateCommitteeGQL: UpdateCommitteeGQL,
                public removeCommitteeGQL: RemoveCommitteeGQL,
                public excelService: ExcelService,
    ) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.userProfile();
            }, error: err => super.onError(err)
        });
        this.dropdownParticipantSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            itemsShowLimit: 0,
            enableCheckAll: false,
            allowSearchFilter: true,
            maxHeight: 500,
        };
        this.actionList();
        // this.actionListArea();
        this.actionListUnity();
        this.actionListUsers();

    }

    ngOnDestroy(): void {
        this.searchCommittee = new SearchCommittee();
        const el = $('#modalCommittee');
        if (el[0]) {
            el.remove();
        }
    }

    actionListArea() {
        this.areasGQL.watch().valueChanges.subscribe(({data}) => {
            this.listArea = data.areas as Area[];
        });
    }

    actionListUnity() {
        this.unitiesGQL.watch().valueChanges.subscribe(({data}) => {
            this.listUnity = data.getUnities as Area[];
        });
    }


    actionListUsers() {
        this.getUsersQuery = this.getUsersGQL.watch();
        this.getUsersQuery.valueChanges.subscribe(({data}) => {
            this.listUsers = data.getUsers as User[];
        });
    }

    getPosition(i: number) {
        switch (i) {
            case 0:
                return '0';
            case 1:
                return '-12.5px';
            case 2:
                return '-25px';
            case 3:
                return '-37.5px';
            default:
                return '-37.5px';
        }
    }

    actionList() {
        this.searchCommitteeQuery = this.searchCommitteeGQL.watch({input: this.searchCommittee});
        this.searchCommitteeQuery.valueChanges.subscribe(({data}) => {
            this.committee = data.searchCommittee as Array<Committee>;
        });
    }

    onSearch() {
        this.searchCommitteeQuery.refetch({input: this.searchCommittee});
    }

    cleanFilter() {
        this.searchCommittee.keyword = undefined;
        this.searchCommittee.unity = undefined;
        this.searchCommittee.manager = undefined;
        this.searchCommittee.status = undefined;
    }

    newCommittee() {
        this.modelCommittee = new Committee();
        this.modelCommittee.status = true;
        // this.createCommitteeAreaInput = new CreateCommitteeAreaInput();
        this.createCommitteeUnityInput = new CreateCommitteeUnityInput();
        this.createCommitteeManagerInput = new CreateCommitteeManagerInput();
        this.participants = [];
        this.userSelected = undefined;
        super.showModal('modalCommittee');
    }

    updateCommittee(committee: Committee) {
        this.modelCommittee = Object.assign({}, committee);
        // this.createCommitteeAreaInput.id = Object.assign({}, committee).area?.id;
        this.createCommitteeUnityInput.id = Object.assign({}, committee).unity?.id;
        this.createCommitteeManagerInput.id = Object.assign({}, committee).manager?.id;
        this.participants = Object.assign([], committee).participants;
        this.userSelected = undefined;
        super.showModal('modalCommittee');
    }

    onSave() {
        const arrValidateFields = [
            {value: this.modelCommittee.name, text: 'Nome<br>'},
            // {value: this.createCommitteeAreaInput, text: 'Área<br>'},
            {value: this.createCommitteeUnityInput.id, text: 'Unidade<br>'},
            {value: this.createCommitteeManagerInput, text: 'Gerente do comitê<br>'},
            {value: this.participants, text: 'Participantes<br>'},
            // {value: this.modelUser.role, text: 'Perfil<br>'},
        ];
        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br> ${stringError}`);
            return;
        }
        if (super.isNullOrUndefined(this.modelCommittee.id)) {
            this.actionCreatedCommittee();
        } else {
            this.actionUpdateCommittee();
        }

    }

    addParticipants() {
        if (this.isNullOrUndefined(this.userSelected)) {
            return;
        }
        if (this.participants.includes(this.userSelected)) {
            return this.showMessage('Atenção', `Participante já selecionado`);
        }
        this.participants.push(this.userSelected);
        this.userSelected = undefined;
    }

    convertParticipants(p, update = false) {
        if (update) {
            let participantList: CreateCommitteeParticipantInput[] = [];
            p.map((e) => {
                const p = {id: e.id};
                participantList.push(p);
            })
            return participantList;
        } else {
            let participantList: CreateCommitteeParticipantInput[] = [];
            p.map((e) => {
                const p = {id: e.id};
                participantList.push(p);
            })
            return participantList;
        }

    }


    actionCreatedCommittee() {
        this.loading.show();
        const input: CreateCommitteeInput = {
            name: this.modelCommittee.name,
            // area: this.createCommitteeAreaInput,
            manager: this.createCommitteeManagerInput,
            status: this.modelCommittee.status ?? false,
            unity: this.createCommitteeUnityInput,
            participants: this.convertParticipants(this.participants),
        }
        this.createCommitteeGQL.mutate({input: input}).subscribe(
            (data) => {
                $('#modalCommittee').modal('hide');
                this.searchCommitteeQuery.refetch({input: this.searchCommittee});
                this.loading.hide();
            }, err => super.onError(err)
        );
    }

    actionUpdateCommittee() {
        this.loading.show();
        const input: UpdateCommitteeInput = {
            id: this.modelCommittee.id,
            name: this.modelCommittee.name,
            // area: this.createCommitteeAreaInput,
            unity: this.createCommitteeUnityInput,
            manager: this.createCommitteeManagerInput,
            status: this.modelCommittee.status ?? false,
            participant: this.convertParticipants(this.participants),
        }
        this.updateCommitteeGQL.mutate({input: input}).subscribe(
            (data) => {
                $('#modalCommittee').modal('hide');
                this.searchCommitteeQuery.refetch({input: this.searchCommittee});
                this.loading.hide();
            }, err => super.onError(err)
        );
    }

    onDelete(id: string) {
        super.confirmMessage('Atenção', 'Deseja realmente excluir esse comitê?', () => {
            this.loading.show();
            this.removeCommitteeGQL.mutate({id: id}).subscribe(
                (data) => {
                    this.searchCommitteeQuery.refetch({input: this.searchCommittee});
                    this.loading.hide();
                }, err => super.onError(err));
        });
    }

    removeParticipants(id: string) {
        const index = this.participants.findIndex((e) => e.id === id);
        if (index > -1) {
            // Object.defineProperty(this.participants, index, {value: 1, configurable: true });
            this.participants.splice(index, 1);
        }
    }

    actionExcel() {
        this.loading.show();
        this.excelService.getExcelCommittees(this.searchCommittee).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    userProfile() {
        switch (this.user.role) {
            case 'admin':
                this.buttonExcel = true;
                this.addCommittee = true;
                this.editCommittee = true;
                this.deleteCommittee = true;
                this.unityCommittee = true;
                break;
            case 'user':
                this.buttonExcel = false;
                this.addCommittee = false;
                this.editCommittee = false;
                this.deleteCommittee = false;
                this.unityCommittee = false;
                break;
            case 'corporate-manager':
                this.buttonExcel = true;
                this.addCommittee = true;
                this.editCommittee = true;
                this.deleteCommittee = true;
                this.unityCommittee = true;
                break;
            case 'local-manager':
                this.buttonExcel = false;
                this.addCommittee = false;
                this.editCommittee = true;
                this.deleteCommittee = false;
                this.unityCommittee = false;
                break;
            case 'committee-member':
                this.buttonExcel = false;
                this.addCommittee = false;
                this.editCommittee = false;
                this.deleteCommittee = false;
                this.unityCommittee = false;
                break;
        }
    }
}
