<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page_header">
        <h1 class="page_title">{{'sidebar.ideias' | translate }} | <span>{{'minhalista.title' | translate}}</span></h1>
        <div class="page_action">
            <a href="javascript:void(0)" class="page_action_excel btn btn-gray-fill"
               type="button">
                <svg-icon src="assets/svg/download.svg"></svg-icon>
                <span (click)="actionExcel()" class="font-weight-normal">Exportar Excel</span>
            </a>
        </div>
    </div>
    <div class="page_padding">
        <div class="page_filter">
            <div class="input-material">
                <input id="search" [(ngModel)]="searchIdeaInput.keyword" class="form-control" type="text" required/>
                <label for="search">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    Busca
                </label>
            </div>
            <div class="input-material" *ngIf="btnUnity">
                <select id="unity" class="form-control" name="type" [(ngModel)]="searchIdeaInput.unityId">
                    <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option [ngValue]="undefined">Todas</option>
                    <option *ngFor="let u of unities" [value]="u.id">{{u.name}}</option>
                </select>
                <label for="unity" class="mb-0 mr-3">{{'unity' | translate}}</label>
            </div>
            <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">
            <div class="input-material flex-grow-1" *ngIf="btnCommittee">
                <ng-multiselect-dropdown
                        [settings]="dropSettings"
                        [(ngModel)]="committess"
                        [data]="committee"
                        [placeholder]="'buscar' | translate"
                        (ngModelChange)="onSelectDropCommitte($event)">
                </ng-multiselect-dropdown>
                <label class="mb-0 mr-3">{{'committee' | translate}}</label>
            </div>
        </div>
            <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">
                <div class="input-material flex-grow-1">
                    <ng-multiselect-dropdown
                            [settings]="dropSettings"
                            [(ngModel)]="listStatusItems"
                            [data]="listStatusDrop"
                            [placeholder]="'buscar' | translate"
                            (ngModelChange)="onSelectDrop($event)">
                    </ng-multiselect-dropdown>
                    <label class="mb-0 mr-3 disabled">Status</label>
                </div>
            </div>
            <div class="input-material">
<!--                <select id="responsible" class="form-control text-capitalize" name="type"-->
<!--                        [(ngModel)]="searchIdeaInput.authorName">-->
<!--                    <option selected-->
<!--                            [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>-->
<!--                    <option *ngFor="let d of authors" [value]="d.authorName"-->
<!--                            class="text-capitalize">{{getTranslate(d.authorName)}}</option>-->
<!--                </select>-->
<!--                <label for="responsible" class="mb-0 mr-3">Autor</label>-->
                <ng-multiselect-dropdown
                        [settings]="dropSettingsAuthor"
                        [(ngModel)]="authors"
                        [data]="author"
                        [placeholder]="'buscar' | translate"
                        (ngModelChange)="onSelectDropAuthors($event)">
                </ng-multiselect-dropdown>
                <label class="mb-0 mr-3 disabled">Autor</label>
            </div>
            <div class="input-material">
                <input id="start" class="form-control picker-period" type="text"
                       required/>
                <label for="start">
                    {{'minhalista.periodo' | translate}}
                </label>
                <svg-icon [svgClass]="'position-absolute date'"
                          src="assets/svg/date.svg"></svg-icon>
            </div>
            <div class="page_filter_action flex-grow-1">
                <a href="javascript:void(0)" (click)="pageChanged(1)"
                   class="btn btn-block btn-lg btn-primary btn-search"
                   style="font-weight: normal;"
                   type="button">Buscar</a>
            </div>

            <div class="page_filter_action flex-grow-1">
                <a href="javascript:void(0)" (click)="cleanFilter()"
                   class="btn btn-block btn-lg btn-primary btn-search"
                   style="font-weight: normal;"
                   type="button">{{'cleanFilter' | translate}}</a>
            </div>
        </div>
        <div class="page_order">
            <div class="page_order_line">&nbsp;</div>
            <div class="page_order_box">
                <span class="page_order_lbl">Ordenar</span>
                <select [(ngModel)]="searchIdeaInput.order" (ngModelChange)="actionSearch()" class="page_order_select">
                    <option *ngFor="let i of order" [value]="i">{{getTranslate(i)}}</option>
                </select>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table_regular">
                <thead>
                <tr>
                    <th scope="col" class="w-5"></th>
                    <th scope="col">Código</th>
                    <th scope="col">Ideia</th>
                    <th scope="col">Autor</th>
                    <th class="text-center" scope="col">{{'unity' | translate}}</th>
                    <th scope="col">{{'committee' | translate}}</th>
                    <th scope="col">Status</th>
                    <th scope="col">{{'actions' | translate}}</th>
                    <th class="text-center" scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(ideas.ideas)">
                    <td colspan="8" class="text-center" style="padding: 68px 0;"> nenhum registro encontrado</td>
                </tr>
                <tr *ngFor="let i of ideas.ideas | paginate: {
                                itemsPerPage: ideas.pageSize,
                                currentPage: ideas.currentPage,
                                totalItems: ideas.totalCount }"
                    class="is-cursor">
                    <td>
                        <div class="d-flex justify-content-center" style="gap: 15px">
                            <svg-icon class="is-cursor" *ngIf="deleteIdea" (click)="onDelete(i.id)"
                                      src="assets/svg/close-circle.svg"></svg-icon>
                        </div>
                    </td>
                    <td [routerLink]="'/my-list-detail/' + i.id">
                        <div class="mx-lg-2 mb-lg-3">
                            <span class="idea-item_code position-relative">{{i.code}}
                                <span [ngStyle]="{'background': getStatusColors(i.status)}">&nbsp;</span>
                            </span>
                        </div>
                    </td>
                    <td [routerLink]="'/my-list-detail/' + i.id">
                        <div>
                            <h3 class="idea-item_title">{{i.title}}</h3>
                            <div class="d-flex">
                                <p class="idea-item_text mb-0 flex-grow-1">Criado em
                                    <span>{{i.createdAt | date:'dd/MM/yy'}}</span>
                                </p>
                            </div>
                        </div>
                    </td>
                    <td [routerLink]="'/my-list-detail/' + i.id">
                        <div *ngIf="!isNullOrUndefined(i.authorName)"></div>
                        <div *ngIf="!isNullOrUndefined(i.authorName)" class="d-flex align-items-center">
                            <p class="mb-0">{{i.authorName}}</p>
                        </div>
                    </td>
                    <td class="idea-item_text text-center" [routerLink]="'/my-list-detail/' + i.id">
                        {{i.unity?.name}}
                    </td>
                    <td class="idea-item_text" [routerLink]="'/my-list-detail/' + i.id">
                        <p class="mb-0">{{i.committee?.name}}</p>
                    </td>
                    <td colspan="3" *ngIf="i.status === 'received'" [routerLink]="'/my-list-detail/' + i.id">
                        <div class="text-center text-capitalize">
                            <div *ngIf="!isNullOrUndefined(i.status)" class="item_status" [ngClass]="i.status"
                                 [ngStyle]="{'background': getStatusColors(i.status)}">
                                <svg-icon src="assets/svg/{{i.status}}.svg"></svg-icon>
                                {{getTranslate(i.status)}}
                            </div>
                        </div>
                    </td>
                    <td *ngIf="i.status !== 'received'" [routerLink]="'/my-list-detail/' + i.id">
                        <div class="text-center text-capitalize">
                            <div *ngIf="!isNullOrUndefined(i.status)" class="item_status" [ngClass]="i.status"
                                 [ngStyle]="{'background': getStatusColors(i.status)}">
                                <svg-icon src="assets/svg/{{i.status}}.svg"></svg-icon>
                                {{getTranslate(i.status)}}
                            </div>
                        </div>
                    </td>
                    <td *ngIf="i.status !== 'received'" [routerLink]="'/my-list-detail/' + i.id">
                        <div class="d-flex align-items-center" style="text-align-last: center;gap: 10px">
                            <p class="idea-item_text mb-0"><span
                                    class="idea-item_text_size-big">{{i.endedAction}}</span>/{{i.action ? i.action.length : 0 }}
                            </p>
                        </div>
                    </td>
                    <td *ngIf="i.status !== 'received'">
                        <svg-icon src="assets/svg/kanban.svg"
                                  [routerLink]="'/kanban/'+i.id"></svg-icon>
                    </td>
                </tr>
                </tbody>
            </table>
            <div>
                <pagination-controls
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
