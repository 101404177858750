<section class="single-page">
    <div class="btn-hero"
         *ngIf="managementHome"
         [routerLink]="'/user'">
        <svg-icon src="assets/svg/options.svg"></svg-icon>
        <span>{{'management' | translate}}</span>
    </div>
    <div class="hero">
        <img src="assets/images/background.png" alt="Clic"/>
    </div>

    <div class="list-featured">
        <div class="p-2">
            <a href="javascript:void('');" *ngIf="listHome" [routerLink]="'/my-list'" class="item">
                <svg-icon src="assets/svg/list.svg"></svg-icon>
                <span>{{'ideaList' | translate}}</span>
            </a>
        </div>
        <div class="p-2">
            <a href="javascript:void('');" *ngIf="kanbanHome" [routerLink]="'/kanban'" class="item">
                <svg-icon src="assets/svg/graph.svg"></svg-icon>
                <span>{{'kanban' | translate}}</span>
            </a>
        </div>
        <div class="p-2">
            <a href="javascript:void('');" *ngIf="newsHome" [routerLink]="'/clic-news'" class="item">
                <svg-icon src="assets/svg/news.svg"></svg-icon>
                <span>Clic News</span>
            </a>
        </div>
        <div class="p-2">
            <a href="javascript:void('');" *ngIf="dashboardHome" [routerLink]="'/dashboard'" class="item">
                <svg-icon src="assets/svg/tiles.svg"></svg-icon>
                <span>Dashboard</span>
            </a>
        </div>

    </div>
    <div class="row section_2 pb-5">
        <div class="col-12 col-lg-4 d-none">
            <div class="panel-primary">
                <div class="panel-primary-title mt-4">
                    <svg-icon src="assets/svg/list-primary.svg"></svg-icon>
                    <h2>Últimas Idéias</h2>
                </div>
                <div class="panel-primary-body">
                    <div class="panel-primary-list">
                        <div *ngFor="let i of home?.ideas |
                paginate: {itemsPerPage: home?.pageSize,
                currentPage: home?.currentPage,
                totalItems: home?.totalCount }" class="panel-primary-item">
                            <span>{{i.code}} | {{i.title}}</span>
                            <div *ngIf="!isNullOrUndefined(i.status)" class="item_status text-capitalize" [ngClass]="i.status"
                                 [ngStyle]="{'background': getStatusColors(i.status)}">
                                <svg-icon src="assets/svg/{{i.status}}.svg"></svg-icon>
                                {{getTranslate(i.status)}}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <pagination-controls
                            [previousLabel]="labels.previousLabel"
                            [nextLabel]="labels.nextLabel"
                            [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                            [screenReaderPageLabel]="labels.screenReaderPageLabel"
                            [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                            (pageChange)="pageChanged($event)">
                    </pagination-controls>
                </div>
            </div>
        </div>
        <div class="col-11">
            <div class="panel-primary">
                <div class="panel-primary-title mt-4">
                    <svg-icon src="assets/svg/tiles-primary.svg"></svg-icon>
                    <h2>Fluxo de tarefas</h2>
                </div>
                <div class="panel-primary-body">
                    <div class="panel-primary-list-vert">
                        <div class="panel-primary-list-vert-item">
                            <b>{{home?.tasksPending}}</b>
                            <p>{{'pending' | translate}}</p>
                        </div>
                        <div class="panel-primary-list-vert-item">
                            <b>{{home?.tasksInProgress}}</b>
                            <p>{{'in-progress' | translate}}</p>
                        </div>
                        <div class="panel-primary-list-vert-item">
                            <b>{{home?.tasksDone}}</b>
                            <p>{{'done' | translate}}</p>
                        </div>
                        <div class="panel-primary-list-vert-item">
                            <b>{{home?.tasksCanceled}}</b>
                            <p>{{'canceled' | translate}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home" *ngIf="false">
    <div class="home_header">
        <h2 class="page_title">CLIC | <span>Dashboard</span></h2>
        <div class="home_divider">&nbsp;</div>
    </div>
</section>


