import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from "../../service/user.service";
import {Notification} from "../../model/user/notification";
import {AuthType, User} from "../../../generated/graphql";

declare var $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {

    user: User;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public userService: UserService
    ) {
        super(router, loading, translate);
    }

    ngOnInit(): void {

        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
    }

    actionNavToggle(): void {
        $('body').toggleClass('nav-open');
        $('.hamburger').toggleClass('open');
    }

    setLanguage(l: string) {
        this.switchLanguage(l);
        this.userService.updateLanguage(l);
    }

    actionLogout() {
        localStorage.clear();
        const p = window.location.pathname.split('/');
        // const virtualPath = p.length >= 3 ? '/' + p[1] : '';
        const redirectUrl = window.location.origin +  '/logout';
        const logoutURL = 'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' + redirectUrl;
        window.location.href = logoutURL;
    }
}
