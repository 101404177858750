<div class="page" style="display: grid">
    <div class="page_header">
        <h1 class="page_title">{{'ideaManager' | translate}} | <span>Kanban</span></h1>
        <div class="page_action">

            <a href="javascript:history.back(-1); void('');" class="btn btn-outline-primary mr-4"
               style="max-width: 250px" *ngIf="isNullOrUndefined(parmId)">
                Voltar
            </a>

            <a href="javascript:void('');" (click)="router.navigate(['my-list-detail/' + parmId])"
               class="btn btn-outline-primary mr-4"
               style="max-width: 250px" *ngIf="!isNullOrUndefined(parmId)">
                Voltar
            </a>


            <a href="javascript:void(0)"
               *ngIf="addAction"
               (click)="newSelectIdea()"
               class="page_action_excel btn btn-warning"
               type="button">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                <span class="ml-2 font-weight-normal">Nova Ação</span>
            </a>
        </div>
    </div>
    <div class="page_padding">
        <div class="page_filter row no-gutters">
            <div class="input-material">
                <input id="search" class="form-control" type="text" [(ngModel)]="searchActionInput.keyword"
                       required/>
                <label for="search">Busca</label>
            </div>
            <div class="input-material" style="min-width: 164px;">
                <ng-multiselect-dropdown
                        id="status"
                        [placeholder]="'minhalista.input3.subtitle' | translate"
                        [settings]="dropdownSettingsUnities"
                        [data]="listIdeas"
                        [(ngModel)]="selectedUnityItems"
                        (onSelect)="onItemSelectUnities($event)"
                        (onDeSelect)="onItemDeselectUnities($event)"
                >
                </ng-multiselect-dropdown>
                <label for="status" class="disabled">{{'ideaSelection' | translate}}</label>
            </div>
            <div class="input-material" *ngIf="unityCountry">
                <select id="country" class="form-control" name="type"
                        [(ngModel)]="searchActionInput.country">
                    <option selected
                            [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option *ngFor="let u of listCountry" [value]="u.id">{{u.name}}</option>
                </select>
                <label for="country" class="mb-0 mr-3">País</label>
            </div>
            <div class="input-material" *ngIf="unityCountry">
                <select id="unity" class="form-control" name="type"
                        [(ngModel)]="searchActionInput.unity">
                    <option selected
                            [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option *ngFor="let u of listUnity" [value]="u.id">{{u.name}}</option>
                </select>
                <label for="unity" class="mb-0 mr-3">{{'unity' | translate}}</label>
            </div>
            <div class="input-material flex-grow-1">
                <ng-multiselect-dropdown
                        [settings]="dropSettings"
                        [(ngModel)]="committee"
                        [data]="listCommittee"
                        [placeholder]="'buscar' | translate"
                        (ngModelChange)="onSelectDropCommitte($event)">
                </ng-multiselect-dropdown>
                <label class="mb-0 mr-3">{{'committee' | translate}}</label>
            </div>
            <!--            <div class="input-material">-->
            <!--                <select id="committee" class="form-control" name="type"-->
            <!--                        [(ngModel)]="searchActionInput.committee">-->
            <!--                    <option selected-->
            <!--                            [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>-->
            <!--                    <option *ngFor="let c of listCommittee" [value]="c.id">{{c.name}}</option>-->
            <!--                </select>-->
            <!--                <label for="committee" class="mb-0 mr-3">Comitê</label>-->
            <!--            </div>-->
            <div class="input-material">
                <select id="statusFilter" class="form-control" name="type"
                        [(ngModel)]="searchActionInput.isDelayed">
                    <option selected
                            [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option [ngValue]="true">Em atraso</option>
                    <option [ngValue]="false">Em dia</option>
                    <option [ngValue]="undefined">Todos</option>
                </select>
                <label for="statusFilter" class="mb-0 mr-3">Status</label>
            </div>
            <div class="input-material" style="min-width: 154px;">
                <ng-multiselect-dropdown
                        (onSelectAll)="selectedAllResponsible($event)"
                        [settings]="dropSettings"
                        (onDeSelectAll)="this.searchActionInput.responsible = [];"
                        [data]="listUser"
                        [(ngModel)]="selectedItemsResponsible"
                        (onSelect)="selectResponsible($event)"
                        (onDeSelect)="deSelectResponsible($event)"
                        [placeholder]="'buscar' | translate"
                >
                </ng-multiselect-dropdown>
                <label class="mb-0 mr-3 disabled">Responsável</label>
            </div>
            <div class="input-material">
                <input id="start" class="form-control picker-period" type="text"
                       required/>
                <label for="start">{{'minhalista.input4' | translate}}</label>
            </div>
            <div class="page_filter_action flex-grow-1">
                <a href="javascript:void(0)" (click)="actionSearch()"
                   class="btn btn-block btn-lg btn-primary btn-search"
                   style="font-weight: normal;"
                   type="button">Buscar</a>
            </div>
            <div class="page_filter_action flex-grow-1">
                <a href="javascript:void(0)" (click)="actionCleanFilter()"
                   class="btn btn-block btn-lg btn-outline-primary btn-search"
                   style="font-weight: normal;"
                   type="button">{{'cleanFilter' | translate}}</a>
            </div>

        </div>
    </div>
    <div style="display: block;width: 100%;overflow-x: auto;-webkit-overflow-scrolling: touch;">
        <div class="kamban-container">
            <app-kamban-list *ngFor="let list of listKamban" [list]="list"
                             (getAction)="updateAction($event)"
                             (newAction)="newSelectIdea($event)"
                             (currentStatus)="getCurrentStatus($event)"
                             (updateCard)="updateList($event)"
                             [cardStore]="cardStore">
            </app-kamban-list>
        </div>
    </div>


</div>

<div class="modal-custom modal fade" id="modalIdea" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalActionLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content p-0">
            <div class="modal-header align-items-center bg-gray" style="min-height: 107px;">
                <h2 class="modal-user_title">{{'idea' | translate}}</h2>
                <a data-dismiss="modal" class="modal_close" aria-label="Close">
                    <svg-icon src="assets/svg/close-modal.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small modal_resource-body modal-kamban">
                <div class="modal-kamban_col">
                    <div class="modal-kamban_col-secondary">
                        <div class="w-100">
                            <div class="modal-kamban_card-secondary">
                                <div class="input-material">
                                    <ng-multiselect-dropdown
                                            id="statusIdea"
                                            [placeholder]="'minhalista.input3.subtitle' | translate"
                                            [settings]="dropdownSettingsUnities"
                                            [data]="listIdeasAction"
                                            (onSelect)="selectIdeaByAction($event)"
                                            (onDeSelect)="deSelectIdeaByAction($event)"
                                    >
                                    </ng-multiselect-dropdown>
                                    <label for="statusIdea" class="disabled">{{'ideaSelection' | translate}}</label>
                                </div>
                                <ng-container *ngIf="!isNullOrUndefined(selectIdea)">
                                    <div class="modal-kamban_divider">&nbsp;</div>
                                    <div class="d-flex flex-wrap" style="column-gap: 50px;row-gap: 20px">
                                        <dl class="flex-grow-1">
                                            <dt class="modal-kamban_lbl">{{'unity' | translate}}</dt>
                                            <dd class="modal-kamban_value mt-2 ml-2">{{selectIdea?.unity?.name}}</dd>
                                        </dl>
                                        <dl class="flex-grow-1">
                                            <dt class="modal-kamban_lbl">{{'committee' | translate}}</dt>
                                            <dd class="modal-kamban_value mt-2 ml-2">{{selectIdea?.committee?.name}}</dd>
                                        </dl>
                                        <!--                                        <dl class="flex-grow-1">-->
                                        <!--                                            <dt class="modal-kamban_lbl">Área de aplicação</dt>-->
                                        <!--                                            <dd class="modal-kamban_value mt-2 ml-2">{{selectIdea?.?.name}}</dd>-->
                                        <!--                                        </dl>-->
                                    </div>
                                    <div class="modal-kamban_divider">&nbsp;</div>
                                    <p class="modal-kamban_lbl">Administrador responsável</p>
                                    <div class="modal-kamban_responsible-adm">
                                        <img class="modal-kamban_user-img"
                                             src="https://www.pontoxtecidos.com.br/static/567/sku/164485009798408.jpg"
                                             alt="user">
                                        <p>{{selectIdea?.areaResponsible}}</p>
                                    </div>
                                    <a (click)="newAction()" class="btn btn-block btn-primary ml-auto mr-auto mt-4"
                                       style="max-width: 250px">
                                        Próximo
                                    </a>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalAction" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalActionLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content p-0">
            <div class="modal-body modal-body--small modal_resource-body modal-kamban">
                <div class="modal-kamban_col action">
                    <div class="modal-kamban_col-primary">
                        <div class="d-flex align-items-end mb-2">
                            <span *ngIf="!isNullOrUndefined(modelAction.idea)" class="item_status text-capitalize"
                                  [ngClass]="modelAction.idea?.status"
                                  [ngStyle]="{'background': getStatusColors(modelAction.idea?.status)}">
                                <svg-icon src="assets/svg/{{modelAction.idea?.status}}.svg"></svg-icon>
                                {{getTranslate(modelAction.idea?.status)}}
                            </span>
                            <span class="modal-kamban_code">{{modelAction?.idea?.code}} |</span>
                            <span class="modal-kamban_title-idea">{{modelAction.idea?.title}}</span>
                        </div>
                        <div class="modal-kamban_card-primary">
                            <textarea #titleTextarea class="modal-kamban_title-action" placeholder="Defina o Título da Ação"
                                      [(ngModel)]="modelAction.title"
                                      (input)="adjustTextareaHeight()">
                            </textarea>
                            <p class="modal-kamban_lbl mb-3">{{'description' | translate}}</p>
                            <textarea #descriptionTextarea class="modal-kamban_value w-100"
                                      placeholder="Defina a descrição da Ação"
                                      maxlength="1000"
                                      [(ngModel)]="modelAction.description"
                                      (input)="adjustTextareaHeight()">
                            </textarea>
                            <dl>
                                <dt class="modal-kamban_lbl my-4">Anexos</dt>
                                <dd class="modal-kamban_attachment">
                                    <ng-container *ngFor="let i of modelAction.files;let inx =  index">
                                        <div>
                                            <div class="modal-kamban_card-attachment">
                                                <ng-container *ngIf="isNullOrUndefined(modelAction.id)">
                                                    <img *ngIf="i.url.startsWith('data')" class="attachment_doc"
                                                         [src]="i.url" alt="doc">
                                                    <svg-icon *ngIf="!i.url.startsWith('data')" class="attachment_doc"
                                                              [src]="typeFileNew(i.url)"></svg-icon>
                                                </ng-container>

                                                <ng-container *ngIf="!isNullOrUndefined(modelAction.id)">
                                                    <a href="{{i.url}}" target="_blank"
                                                       class="d-flex justify-content-center align-items-center"
                                                       style="width: 100%;height: 100%">
                                                        <img *ngIf="getType(i.url)" class="attachment_doc"
                                                             [src]="typeFileUpdate(i.url)" alt="doc">
                                                        <svg-icon *ngIf="!getType(i.url)" class="attachment_doc"
                                                                  [src]="typeFileUpdate(i.url)"></svg-icon>
                                                    </a>
                                                </ng-container>
                                                <a class="is-cursor position-absolute"
                                                   (click)="removeActionFile(i.id,inx)"
                                                   style="top: 5px;right: 5px">
                                                    <svg-icon src="assets/svg/close-circle.svg"></svg-icon>
                                                </a>
                                            </div>
                                            <p style="font-size: 11px;text-align: center;">{{i.name}}</p>
                                        </div>
                                    </ng-container>

                                    <label class="modal-kamban_card-attachment-plus is-cursor">
                                        <svg-icon src="assets/svg/attachment.svg"></svg-icon>
                                        <span>Adicionar</span>
                                        <input type="file" id="doc-1" name="doc-1" required
                                               (change)="handleUpload($event)"/>
                                    </label>
                                </dd>
                            </dl>
                        </div>
                        <div class="modal-kamban_divider">&nbsp;</div>
                        <p class="modal-kamban_lbl">Tarefas</p>
                        <div class="assignment_list">
                            <div class="assignment_list_item" *ngFor="let t of modelAction.tasks,let i = index">
                                <div class="d-flex align-items-center flex-grow-1">
                                    <input *ngIf="!isNullOrUndefined(this.modelAction.id)"
                                           (change)="updateTaskStatus(t)" type="checkbox" id="scales"
                                           [ngModel]="t.status"
                                           name="scales">
                                    <label class="assignment_list_text" for="scales">
                                        {{t.description}}
                                    </label>
                                </div>
                                <svg-icon (click)="removeActionTask(i)" class="is-cursor"
                                          src="assets/svg/check-delete.svg"></svg-icon>
                            </div>
                        </div>
                        <div class="input-material mt-4 mx-3">
                            <input id="action" [(ngModel)]="task" style="height: 2.4125em;" class="form-control"
                                   type="text" required/>
                            <label for="action">
                                Digite a ação
                            </label>
                            <svg-icon class="is-cursor" (click)="onTask()"
                                      [svgClass]="'btn-submit position-absolute'"
                                      src="assets/svg/submit.svg"></svg-icon>
                        </div>
                        <div class="modal-kamban_divider">&nbsp;</div>
                        <p class="modal-kamban_lbl">Atividades</p>
                        <ul class="nav nav-tabs border-none" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="comment-tab" data-toggle="tab" href="#comment"
                                   role="tab"
                                   aria-controls="comment" aria-selected="true">Comentários</a>
                            </li>
                            <li>
                                <a class="nav-link" id="historic-tab" data-toggle="tab" href="#historic" role="tab"
                                   aria-controls="profile" aria-selected="false">Histórico</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="modal-comment tab-pane fade show active" id="comment" role="tabpanel"
                                 aria-labelledby="comment-tab">
                                <div class="mt-4 mx-3 mb-3 d-flex align-items-center">
                                    <img class="modal-kamban_user-img mr-3"
                                         src="https://www.pontoxtecidos.com.br/static/567/sku/164485009798408.jpg"
                                         alt="user">
                                    <div class="input-material flex-grow-1 mb-0">
                                        <input id="input-comment" [(ngModel)]="comments" style="height: 2.4125em;"
                                               class="form-control"
                                               type="text" required/>
                                        <label for="input-comment">Digite sua mensagem</label>
                                        <svg-icon class="is-cursor" (click)="onComment()"
                                                  [svgClass]="'btn-submit position-absolute'"
                                                  src="assets/svg/submit.svg"></svg-icon>
                                    </div>
                                </div>
                                <div class="modal-comment_card mb-3" *ngFor="let i of modelAction.comments">
                                    <img class="modal-kamban_user-img mr-3"
                                         src="{{i.user.photo}}" onerror="this.onerror=null;
                                this.src='https://www.pontoxtecidos.com.br/static/567/sku/164485009798408.jpg';"
                                         alt="user"/>

                                    <!--                                         src="{{i.user.photo != null ? i.user.photo : 'assets/images/nophoto.png' }}"-->
                                    <!--                                         alt="user">-->
                                    <div class="flex-grow-1 mt-2">
                                        <div class="d-flex justify-content-between">
                                            <span class="modal-comment_name">{{i.user?.name}}</span>
                                            <span class="modal-comment_date">{{i.createdAt |date:'dd/MM/yyyy'}}</span>
                                        </div>
                                        <p class="modal-comment_text">
                                            {{i.comment}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-historic tab-pane fade" id="historic" role="tabpanel"
                                 aria-labelledby="historic-tab">
                                <div class="mt-4">
                                    <div class="modal-historic_item" *ngFor="let i of modelActionHistory.history |
                                            paginate: {itemsPerPage: modelActionHistory.pageSize,
                                            currentPage: modelActionHistory.currentPage,
                                            totalItems: modelActionHistory.totalCount }">
                                        <div class="d-flex align-items-center flex-grow-1 mb-2">
                                            <img class="modal-kamban_user-img mr-3"
                                                 src="{{i.user.photo}}" onerror="this.onerror=null;
                                            this.src='https://www.pontoxtecidos.com.br/static/567/sku/164485009798408.jpg';"
                                                 alt="user"/>

                                            <!--                                            src="{{i.user.photo != null ? i.user.photo : 'assets/images/nophoto.png'}}"-->
                                            <!--                                            alt="user">-->
                                            <p class="modal-historic_info" *ngIf="i.type ==='add'">
                                                <span>
                                                    {{i.user?.name}}
                                                </span>
                                                Criou
                                                <span>
                                                    {{i.changedData}}
                                                </span>
                                                -
                                                <span>
                                                   {{i.before}}
                                                </span>
                                            </p>
                                            <p class="modal-historic_info" *ngIf="i.type ==='remove'">
                                                <span>
                                                    {{i.user?.name}}
                                                </span>
                                                Excluiu
                                                <span>
                                                    {{i.changedData}}
                                                </span>
                                            </p>
                                            <p class="modal-historic_info" *ngIf="i.type ==='change'">
                                                <span>
                                                   {{i.user?.name}}
                                                </span>
                                                Alterou
                                                <span>
                                                    {{i.changedData}}
                                                </span>
                                                de
                                                <span>
                                                   {{i.before}}
                                                </span>
                                                para
                                                <span>
                                                   {{i.after}}
                                                </span>

                                            </p>


                                            {{i.createdAt | date: 'dd/MM/yyyy'}}
                                        </div>

                                        <span class="modal-historic_date ml-3"></span>
                                    </div>
                                    <div>
                                        <pagination-controls
                                                [previousLabel]="labels.previousLabel"
                                                [nextLabel]="labels.nextLabel"
                                                [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                                                [screenReaderPageLabel]="labels.screenReaderPageLabel"
                                                [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                                                (pageChange)="pageChanged($event)">
                                        </pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 2em"></div>
                    <div class="modal-kamban_col-secondary action">
                        <a data-dismiss="modal" class="modal_close" aria-label="Close">
                            <svg-icon src="assets/svg/close-modal.svg"></svg-icon>
                        </a>
                        <div class="w-100">
                            <select (change)="onUpdateStatus()" [ngClass]="modelAction.status"
                                    [disabled]="modelAction.idea?.status !=='analysis'"
                                    [(ngModel)]="modelAction.status"
                                    class="modal-kamban_select">
                                <option *ngFor="let i of statusKamban" class="option {{i}}"
                                        [value]="i">{{getTranslate(i)}}</option>
                            </select>

                            <div class="modal-kamban_card-secondary">
                                <div class="input-material">
                                    <select id="responsible_action" class="form-control" name="type"
                                            [(ngModel)]="responsible">
                                        <option selected
                                                [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                                        <option *ngFor="let u of listUser" [ngValue]="u.id">{{u.name}}</option>
                                    </select>
                                    <label for="responsible_action" class="mb-0 mr-3">Responsável</label>
                                </div>
                                <div class="modal-kamban_divider">&nbsp;</div>
                                <!--                                <div class="d-flex justify-content-between align-items-end mx-2 mb-3">-->
                                <!--                                    <span class="modal-kamban_lbl">Início</span>-->
                                <!--                                    <span class="modal-kamban_value">{{(modelAction.createdAt ? modelAction.createdAt : dateNow)|date:'dd/MM/yyyy' }}</span>-->
                                <!--                                </div>-->
                                <div class="row" style="row-gap: 15px">
                                    <div class="col-md-6">
                                        <div class="input-material mb-0">
                                            <input id="start-date" class="form-control picker-action-start"
                                                   type="text"
                                                   required/>
                                            <label for="start-date">Início</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="input-material mb-0">
                                            <input id="end" class="form-control picker-action"
                                                   type="text"
                                                   required/>
                                            <label for="end">Final</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-kamban_divider">&nbsp;</div>
                                <dl *ngIf="!isNullOrUndefined(modelAction.idea?.unity)">
                                    <dt class="modal-kamban_lbl">{{'unity' | translate}}</dt>
                                    <dd class="modal-kamban_value mt-2 ml-2">{{modelAction.idea?.unity.name}}</dd>
                                </dl>
                                <dl *ngIf="!isNullOrUndefined(modelAction.idea?.committee)">
                                    <dt class="modal-kamban_lbl">{{'committee' | translate}}</dt>
                                    <dd class="modal-kamban_value mt-2 ml-2">{{modelAction.idea?.committee.name}}</dd>
                                </dl>
                                <!--                                <dl *ngIf="!isNullOrUndefined(modelAction.idea?.areaResponsible)">-->
                                <!--                                    <dt class="modal-kamban_lbl">Área de aplicação</dt>-->
                                <!--                                    <dd class="modal-kamban_value mt-2 ml-2">{{modelAction.idea?.areaResponsible}}</dd>-->
                                <!--                                </dl>-->
                                <div class="modal-kamban_divider">&nbsp;</div>

                                <p class="modal-kamban_lbl">Superior Imediato</p>
                                <div *ngIf="!isNullOrUndefined(modelAction.idea?.areaResponsible)"
                                     class="modal-kamban_responsible-adm">
                                    <img class="modal-kamban_user-img"
                                         src="{{modelAction.responsible.photo}}" onerror="this.onerror=null;
                                            this.src='https://www.pontoxtecidos.com.br/static/567/sku/164485009798408.jpg';"
                                         alt="user"/>

                                    <!--src="{{modelAction.responsible.photo != null ? modelAction.responsible.photo : 'assets/images/nophoto.png' }}"-->
                                    <!--alt="user">-->
                                    <p>{{modelAction.idea?.managerName}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="modal-kamban_in">
                            <p *ngIf="!isNullOrUndefined(modelAction.createdAt)" class="mt-1 mb-2">Criado
                                em {{modelAction.createdAt|date:'dd/MM/yyyy'}}</p>
                            <p *ngIf="!isNullOrUndefined(modelAction.createdAt)" class="mb-0">Atualizado
                                em {{modelAction.updatedAt|date:'dd/MM/yyyy'}}</p>
                        </div>
                        <a (click)="onSave()" class="btn btn-primary btn-block mt-3">
                            Salvar
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

