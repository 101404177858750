import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {Notification} from '../../model/user/notification';
import {
    GetNotificationGQL,
    GetNotificationQuery,
    ReadNotificationGQL,
    UserNotificationDto
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends BaseComponent implements OnInit {
    modelNotifications: Notification[] = [];
    notificationsQuery: QueryRef<GetNotificationQuery>;
    notifications: UserNotificationDto;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public getNotifications: GetNotificationGQL,
                public notificationsService: UserService,
                public readNotification: ReadNotificationGQL) {
        super(router, loading, translate);
    }

    ngOnInit() {
        this.showNotifications();
    }

    showNotifications() {
        this.notificationsQuery = this.getNotifications.watch({}, {});
        this.notificationsQuery.startPolling(500000);
        this.notificationsQuery.valueChanges.subscribe(({data}) => {
            this.notifications = data.notificationsByUser as UserNotificationDto;
        });
    }

    actionNotification(model) {
        // this.router.navigate(['/my-list-detail/' + model.relationId]);
        this.readNotification.mutate({id: model.id}).subscribe((result) => {
            let url = '';
            url = '/my-list-detail/' + model.relationId;

            this.router.navigate([url]);
            this.showNotifications();
        });
    }
}
