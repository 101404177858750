import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {
    Area,
    CreateAreaGQL, CreateAreaInput, RemoveAreaGQL,
    SearchAreaGQL, SearchAreaInput,
    SearchAreaQuery, UpdateAreaGQL, UpdateAreaInput, User
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {ExcelService} from "../../service/excel.service";

declare var $: any;

@Component({
    selector: 'app-area',
    templateUrl: './area.component.html',
    styleUrls: ['./area.component.scss']
})
export class AreaComponent extends BaseComponent implements OnInit, OnDestroy {
    user: User = new User();
    searchAreaQuery: QueryRef<SearchAreaQuery>;
    areaResult: Area[] = [];
    modelArea: Area = new Area();
    searchAreaInput: SearchAreaInput = new SearchAreaInput();

    buttonExcel: boolean;
    editArea: boolean;
    addArea: boolean;
    deleteArea: boolean;


    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public userService: UserService,
                public translate: TranslateService,
                public searchAreaGQL: SearchAreaGQL,
                public createAreaGQL: CreateAreaGQL,
                public updateAreaGQL: UpdateAreaGQL,
                public removeAreaGQL: RemoveAreaGQL,
                public excelService: ExcelService,
    ) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.userProfile();
            }, error: err => super.onError(err)
        });
        this.actionList();
    }

    ngOnDestroy(): void {
        const el = $('#modalArea');
        if (el[0]) {
            el.remove();
        }
    }

    actionList() {
        this.loading.show();
        this.searchAreaQuery = this.searchAreaGQL.watch({input: this.searchAreaInput});
        this.searchAreaQuery.valueChanges.subscribe(({data}) => {
            this.areaResult = data.searchArea as Area[];
            this.loading.hide();
        });
    }

    onSave() {
        const arrValidateFields = [
            {value: this.modelArea.name, text: 'Nome<br>'},
        ];
        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }
        if (super.isNullOrUndefined(this.modelArea.id)) {
            this.actionCreatedArea();
        } else {
            this.actionUpdateArea();
        }
    }

    actionUpdateArea() {
        this.loading.show();
        const input: UpdateAreaInput = {
            id: this.modelArea.id,
            name: this.modelArea.name,
        };
        this.updateAreaGQL.mutate({input}).subscribe(
            (data) => {
                $('#modalArea').modal('hide');
                this.searchAreaQuery.refetch({input: this.searchAreaInput});
                this.loading.hide();
            }, err => super.onError(err)
        );
    }

    actionCreatedArea() {
        this.loading.show();
        const input: CreateAreaInput = {
            name: this.modelArea.name,
        };
        this.createAreaGQL.mutate({input}).subscribe(
            (data) => {
                $('#modalArea').modal('hide');
                this.searchAreaQuery.refetch({input: this.searchAreaInput});
                this.loading.hide();
            }, err => super.onError(err)
        );
    }

    onSearch() {
        this.searchAreaQuery.refetch({input: this.searchAreaInput});
    }

    newUnity() {
        this.modelArea = new Area();
        super.showModal('modalArea');
    }

    updateArea(area: Area) {
        this.modelArea = Object.assign({}, area);
        super.showModal('modalArea');
    }

    onDelete(id: string) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningDeleteArea'), () => {
            this.loading.show();
            this.removeAreaGQL.mutate({id}).subscribe(
                (data) => {
                    this.searchAreaQuery.refetch({input: this.searchAreaInput});
                    this.loading.hide();
                    this.showMessage(this.translate.instant('baseComponent.success'), (this.translate.instant('areaexcluido')), 'success');
                }, err => super.onError(err));
        });
    }

    actionExcel() {
        this.loading.show();
        this.excelService.getExcelArea(this.searchAreaInput).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    userProfile() {
        switch (this.user.role) {
            case 'admin':
                this.buttonExcel = true;
                this.addArea = true;
                this.editArea = true;
                this.deleteArea = true;
                break;
            case 'user':
                this.buttonExcel = true;
                this.addArea = true;
                this.editArea = true;
                this.deleteArea = true;
                break;
            case 'corporate-manager':
                this.buttonExcel = true;
                this.addArea = true;
                this.editArea = true;
                this.deleteArea = true;
                break;
            case 'local-manager':
                this.buttonExcel = true;
                this.addArea = true;
                this.editArea = true;
                this.deleteArea = true;
                break;
            case 'committee-member':
                this.buttonExcel = false;
                this.addArea = false;
                this.editArea = false;
                this.deleteArea = false;
                break;
        }
    }
}
