import {BrowserModule} from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {HeaderComponent} from './views/header/header.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SidebarComponent} from './views/sidebar/sidebar.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {LogoutComponent} from './views/logout/logout.component';
import {AppDataService} from './service/app_data.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import {RegisterComponent} from './views/register/register.component';
import {HomeComponent} from './views/home/home.component';
import {AutocompleteComponent} from './util/autocomplete/autocomplete.component';
import {MyListComponent} from './views/my-list/my-list.component';
import {NotificationComponent} from './views/notification/notification.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {RegisterDetailComponent} from './views/register-detail/register-detail.component';
import {UserComponent} from './views/user/user.component';
import {AuthenticateComponent} from './views/authenticate/authenticate.component';
import {HeaderHttpInterceptor} from './base/header-interceptor';
import {KambanComponent} from './views/kamban/kamban.component';
import {KambanListComponent} from "./views/component/kamban-list/kamban-list.component";
import {KambanCardComponent} from "./views/component/kamban-card/kamban-card.component";
import {MyListDetailComponent} from './views/my-list-detail/my-list-detail.component';
import {NgxMaskModule} from 'ngx-mask';
import {GraphQLModule} from "./graphql.module";
import {ClicNewsComponent} from './views/clic-news/clic-news.component';
import {AreaComponent} from './views/area/area.component';
import {UnityComponent} from './views/unity/unity.component';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {CommitteeComponent} from './views/committee/committee.component';
import {ClicNewsDetailComponent} from './views/clic-news-detail/clic-news-detail.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {ImageCropperModule} from 'ngx-image-cropper';
import {CropImageComponent} from './views/crop-image/crop-image.component';
import {CURRENCY_MASK_CONFIG, CurrencyMaskModule} from "ng2-currency-mask";
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
import {Challenge} from "../generated/graphql";
import {ChallengeComponent} from "./views/challenge/challenge.component";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";

export function playerFactory() {
    return player;
}

registerLocaleData(localePt, 'pt-BR');

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HeaderComponent,
        SidebarComponent,
        LogoutComponent,
        RegisterComponent,
        HomeComponent,
        AutocompleteComponent,
        DashboardComponent,
        MyListComponent,
        NotificationComponent,
        RegisterDetailComponent,
        UserComponent,
        AuthenticateComponent,
        KambanComponent,
        KambanListComponent,
        KambanCardComponent,
        MyListDetailComponent,
        ClicNewsComponent,
        AreaComponent,
        UnityComponent,
        CommitteeComponent,
        CropImageComponent,
        ChallengeComponent,
        ClicNewsDetailComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        AngularSvgIconModule.forRoot(),
        SlickCarouselModule,
        FormsModule,
        NgxSpinnerModule,
        ImageCropperModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgApexchartsModule,
        NgxPaginationModule,
        NgxMaskModule.forRoot(),
        GraphQLModule,
        CKEditorModule,
        CurrencyMaskModule,
        NgMultiSelectDropDownModule.forRoot(),
        [LottieModule.forRoot({player: playerFactory})]
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderHttpInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'BRL'
        },
        {
            provide: CURRENCY_MASK_CONFIG,
            useValue: {   // BRL
                align: 'left',
                allowNegative: true,
                decimal: ',',
                precision: 2,
                prefix: 'R$ ',
                suffix: '',
                thousands: '.'
            },
        },
        TranslateService,
        AppDataService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
