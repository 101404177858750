import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListSchema} from '../../../model/kamban/list-schema';
import {CardStore} from '../../../model/kamban/card-store';
import {Action, ActionKamban, Kamban, UpdateActionStatusGQL, User} from '../../../../generated/graphql';
import {BaseComponent} from '../../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from "../../../service/user.service";

@Component({
    selector: 'app-kamban-list',
    templateUrl: './kamban-list.component.html',
    styleUrls: ['./kamban-list.component.scss']
})
export class KambanListComponent extends BaseComponent implements OnInit {
    @Input() list: Kamban;
    @Input() cardStore: CardStore;
    displayAddCard = false;

    @Output() getAction = new EventEmitter<any>();
    @Output() newAction = new EventEmitter<string>();
    @Output() currentStatus = new EventEmitter<string>();
    @Output() updateCard = new EventEmitter<Action>();

    user: User = new User();

    move: boolean;


    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public userService: UserService,
                public updateActionStatusGQL: UpdateActionStatusGQL,
    ) {
        super(router, loading, translate);
    }

    toggleDisplayAddCard() {
        this.displayAddCard = !this.displayAddCard;
    }


    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.userProfile();
            }, error: err => super.onError(err)
        });
    }


    allowDrop($event) {
        $event.preventDefault();
    }

    drop($event) {
        $event.preventDefault();
        const id = $event.dataTransfer.getData('text');
        let target = $event.target;
        const targetClassName = target.className;
        while (target.className !== 'list') {
            target = target.parentNode;
        }
        target = target.querySelector('.cards');
        if (targetClassName === 'kamban-card') {
            $event.target.parentNode.insertBefore(
                document.getElementById(id),
                $event.target
            );
        } else if (targetClassName === 'list__title') {
            if (target.children.length) {
                target.insertBefore(document.getElementById(id), target.children[0]);
            } else {
                target.appendChild(document.getElementById(id));
            }
        } else {
            if (this.isNullOrUndefined(document.getElementById(id))) {
                return;
            }
            target.appendChild(document.getElementById(id));
        }
        this.updateActionStatusGQL.mutate({id: id, status: target.parentNode.id}).subscribe(({data}) => {
            this.updateCard.emit(data.updateActionStatus as Action);
        });
    }

    onEdit(action: Action) {
        this.getAction.emit(action);
    }

    dragStart(ev) {
        if (this.move === true) {
            const i = this.list.cards.find((e) => {
                return e.action.id === ev.target.id;
            })
            if (i.action.idea.status !== 'analysis') {
                return this.showMessage('Atenção', 'É permitido alterar status da ação apenas de ideias em análise');
            }
            this.currentStatus.emit(this.list.name);
            ev.dataTransfer.setData('text', ev.target.id);
        } else {
            const i = this.list.cards.find((e) => {
                return e.action.id === ev.target.id;
            })
            if (i.action.idea.status !== 'hg') {
                return this.showMessage('Atenção', 'Você não tem permissão para mover uma ideia');
            }
            this.currentStatus.emit(this.list.name);
            ev.dataTransfer.setData('text', ev.target.id);
        }


    }

    selectStatus(name: string) {
        this.newAction.emit(name);
    }

    splitCode(code: string) {
        return code.split("-")[1];
    }

    userProfile() {
        switch (this.user.role) {
            case 'admin':
                this.move = true;
                break;
            case 'user':
                this.move = true;
                break;
            case 'corporate-manager':
                this.move = true;
                break;
            case 'local-manager':
                this.move = true;
                break;
            case 'committee-member':
                this.move = true;
                break;

        }
    }
}
