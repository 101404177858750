import {Component, ViewChild, OnInit, AfterViewInit, OnDestroy, ElementRef} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {CardStore} from '../../model/kamban/card-store';
import {
    Action,
    Committee,
    CommitteesGQL,
    CreateActionGQL,
    CreateActionInput,
    CreateActionResponsibleInput,
    CreateActionIdeaInput,
    GetUnitiesGQL,
    GetUsersGQL,
    Idea,
    IdeaByStatusGQL,
    Kamban,
    SearchActionGQL,
    SearchActionInput,
    SearchActionQuery,
    Unity,
    User,
    UpdateActionGQL,
    UpdateActionInput,
    ActionComment,
    CreateActionCommentGQL,
    CreateActionCommentInput,
    CreateActionCommentActionInput,
    CreateActionTaskGQL,
    CreateActionTaskInput,
    ActionTask,
    UpdateActionTaskStatusGQL,
    ActionFile,
    RemoveActionTaskGQL,
    UpdateActionStatusGQL,
    ActionStatusGQL,
    GetIdeaGQL,
    RemoveActionFileGQL,
    GetIdeaCommiteeByIdGQL,
    SearchIdeaInput,
    SearchAllIdeasQuery,
    SearchActionHistoryQuery,
    SearchActionHistoryGQL,
    ActionHistory,
    ActionHistoryPage, GetIdeaQuery, Country, GetCountriesGQL
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {UserService} from '../../service/user.service';

declare var moment: any;
declare var $: any;

@Component({
    selector: 'app-kamban',
    templateUrl: './kamban.component.html',
    styleUrls: ['./kamban.component.scss']
})
export class KambanComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    user: User = new User();
    cardStore: CardStore;
    searchActionInput: SearchActionInput = new SearchActionInput();
    selectIdea: Idea = undefined;
    listKamban: Kamban[] = [];
    listIdeas: Idea[] = [];
    listIdeasAction: Idea[] = [];
    listUnity: Unity[] = [];
    listCountry: Country[] = [];
    listCommittee: Committee[] = [];
    committee: Array<Committee> = [];


    listUser: User[] = [];
    searchActionQuery: QueryRef<SearchActionQuery>;
    searchActionHistoryQuery: QueryRef<SearchActionHistoryQuery>;
    modelAction: Action = new Action();
    modelActionHistory: ActionHistoryPage = new ActionHistoryPage();
    responsible: string;
    comments: any;
    task: any;

    file: File = null;
    thumpPath: any = null;
    thumpName: string = null;
    croppedImage: any = null;
    createFiles: File[] = [];

    dropdownSettingsUnities = {};
    selectedUnityItems: { id: string; nameCode: string }[];
    selectedItemsResponsible: { id: string; name: string }[];

    selectStatus = 'pending';
    currentStatus: string;

    page = 1;
    parmId: string;

    idea: Idea = new Idea();

    dropSettings: any = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Marcar todos',
        unSelectAllText: 'Desmarcar todos',
        itemsShowLimit: 3,
        allowSearchFilter: true
    };

    addAction: boolean;
    unityCountry: boolean;

    @ViewChild('descriptionTextarea', { static: false }) descriptionTextarea: ElementRef;
    @ViewChild('titleTextarea', { static: false }) titleTextarea: ElementRef;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public route2: ActivatedRoute,
                public translate: TranslateService,
                public userService: UserService,
                private searchActionGQL: SearchActionGQL,
                private searchActionHistoryGQL: SearchActionHistoryGQL,
                private getIdeasGQL: IdeaByStatusGQL,
                private getUnitiesGQL: GetUnitiesGQL,
                private getCountriesGQL: GetCountriesGQL,
                private committeesGQL: CommitteesGQL,
                private getUsersGQL: GetUsersGQL,
                private createActionGQL: CreateActionGQL,
                private updateActionGQL: UpdateActionGQL,
                private createActionCommentGQL: CreateActionCommentGQL,
                private createActionTaskGQL: CreateActionTaskGQL,
                private updateActionTaskStatusGQL: UpdateActionTaskStatusGQL,
                private removeActionTaskGQL: RemoveActionTaskGQL,
                private updateActionStatusGQL: UpdateActionStatusGQL,
                private getIdeaGQL: GetIdeaGQL,
                private getIdeaCommiteeByIdGQL: GetIdeaCommiteeByIdGQL,
                private removeActionFileGQL: RemoveActionFileGQL,
    ) {
        super(router, loading, translate);
    }


    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.userProfile();
            }
        });
        this.dropdownSettingsUnities = {
            singleSelection: true,
            idField: 'id',
            textField: 'nameCode',
            itemsShowLimit: 1,
            enableCheckAll: false,
            allowSearchFilter: true,
            maxHeight: 500,
        };
        this.searchActionInput.responsible = [];
    }


    ngAfterViewInit() {
        this.route2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
                this.searchActionInput.idea = url.id;
                this.parmId = url.id;
            }
            this.configFilter();
            this.actionList();
        });
    }

    ngOnDestroy(): void {
        const elA = $('#modalAction');
        if (elA[0]) {
            elA.remove();
        }
    }

    configFilter() {
        this.getIdeasGQL.watch({status: ['approved', 'denied', 'awarded', 'analysis']}).valueChanges.subscribe(({data}) => {
            this.listIdeas = data.ideaByStatus as Idea[];
            const idea = this.listIdeas.find((e) => e.id === this.parmId);
            if (!this.isNullOrUndefined(idea)) {
                this.selectedUnityItems = [{id: idea.id, nameCode: idea.nameCode}];
            }
        });
        this.getUnitiesGQL.watch().valueChanges.subscribe(({data}) => {
            this.listUnity = data.getUnities as Unity[];
        });
        this.getCountriesGQL.watch().valueChanges.subscribe(({data}) => {
            this.listCountry = data.getCountries as Country[];
        });
        this.committeesGQL.watch().valueChanges.subscribe(({data}) => {
            this.listCommittee = data.committees as Committee[];
        });

        if (!this.isNullOrUndefined(this.searchActionInput.idea)) {
            this.getIdeaCommiteeByIdGQL.watch({input: this.searchActionInput.idea}).valueChanges.subscribe(({data}) => {
                const a = data.getIdea as Idea;
                this.listUser = a.committee.participants as User[];
            });
        } else {
            this.getUsersGQL.watch().valueChanges.subscribe(({data}) => {
                this.listUser = data.getUsers as User[];
            });
        }

        this.setDatePicker('picker-period', (start, end, label) => {
            this.searchActionInput.start = start.utcOffset(0, true).format();
            this.searchActionInput.end = end.utcOffset(0, true).format();
        });

    }

    actionList() {
        this.searchActionQuery = this.searchActionGQL.watch({input: this.searchActionInput});
        this.searchActionQuery.valueChanges.subscribe(({data}) => {
            // console.log('this.searchAction');
            // console.log(data.searchAction as Kamban[]);
            this.listKamban = data.searchAction as Kamban[];
        });
    }

    actionSearch() {
        this.searchActionQuery.refetch({input: this.searchActionInput});
    }

    newSelectIdea(select = this.statusKamban[0]) {
        this.createFiles = [];
        this.file = null;
        this.selectStatus = select;
        if (this.isNullOrUndefined(this.searchActionInput.idea)) {
            this.selectIdea = undefined;
            this.getIdeasGQL.watch({status: ['analysis']}).valueChanges.subscribe(({data}) => {
                this.listIdeasAction = data.ideaByStatus as Idea[];
            });
            super.showModal('modalIdea');
        } else {
            this.getIdeasById();
        }
    }

    getIdeasById() {
        this.getIdeaGQL.watch({id: this.searchActionInput.idea}).valueChanges.subscribe(({data}) => {
            this.selectIdea = data.getIdea as Idea;
            this.newAction(false);
        });
    }

    newAction(modalIdea = true) {
        if (modalIdea) {
            super.closeModal('modalIdea');
        }
        if (this.selectIdea.status !== 'analysis') {
            return this.showMessage('Atenção', 'É permitido criar ação apenas de ideias em análise');
        }
        this.modelAction = new Action();
        this.modelAction.endDate = this.dateNow;
        this.modelAction.startDate = this.dateNow;
        this.modelAction.status = this.selectStatus;
        this.responsible = undefined;
        this.comments = undefined;
        this.task = undefined;
        this.modelAction.idea = this.selectIdea;
        this.configAction();
        super.showModal('modalAction');
    }

    updateAction(event: Action) {
        this.modelAction = Object.assign({}, event);
        this.searchActionHistoryQuery = this.searchActionHistoryGQL.watch({id: this.modelAction.id, page: this.page});
        this.searchActionHistoryQuery.valueChanges.subscribe(({data}) => {
            this.modelActionHistory = data.actionHistory as ActionHistoryPage;
        });
        this.route2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
                this.searchActionInput.idea = url.id;
            }
        });
        this.responsible = Object.assign({}, event).responsible.id;
        this.comments = undefined;
        this.task = undefined;
        this.configAction();
        super.showModal('modalAction');
    }

    configAction() {
        this.setDate('picker-action', (start, end) => {
            this.modelAction.endDate = end.utcOffset(0, true).format();
        }, this.modelAction.endDate);

        this.setDate('picker-action-start', (start, end) => {
            this.modelAction.startDate = start.utcOffset(0, true).format();
        }, this.modelAction.startDate);
    }

    onSave() {
        if (!super.isNullOrUndefined(this.task)) {
            return this.showMessage('Atenção', `Uma ação foi informada, mas não foi enviada! Faça o envio antes de salvar.`);
        }

        const arrValidateFields = [
            {value: this.modelAction.title, text: 'Titulo<br>'},
            {value: this.modelAction.description, text: 'Descrição<br>'},
            {value: this.modelAction.status, text: 'Status<br>'},
            {value: this.responsible, text: 'Responsável<br>'},
            // {value: this.modelUser.role, text: 'Perfil<br>'},
        ];
        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br> ${stringError}`);
            return;
        }
        if (super.isNullOrUndefined(this.modelAction.id)) {
            this.actionCreatedAction();
        } else {
            this.actionUpdateAction();
        }
    }

    actionCreatedAction() {
        const createActionResponsibleInput: CreateActionResponsibleInput = {id: this.responsible};
        const CreateActionIdeaInput: CreateActionIdeaInput = {id: this.modelAction.idea.id};
        const input: CreateActionInput = {
            title: this.modelAction.title,
            description: this.modelAction.description,
            idea: CreateActionIdeaInput,
            responsible: createActionResponsibleInput,
            startDate: this.modelAction.startDate,
            endDate: this.modelAction.endDate,
            status: this.modelAction.status,
        };
        this.loading.show();
        this.createActionGQL.mutate({input: input}).subscribe(
            ({data}) => {
                if (!super.isNullOrUndefined(this.modelAction.comments)) {
                    this.modelAction.comments.forEach((e) => {
                        this.actionComment(data.createAction.id, e.comment);
                    });
                }
                if (!super.isNullOrUndefined(this.modelAction.tasks)) {
                    this.modelAction.tasks.forEach((e) => {
                        this.actionTask(data.createAction.id, e.description);
                    });
                }
                if (!super.isNullOrUndefined(this.createFiles)) {
                    this.createFiles.forEach((e) => {
                        this.uploadImage(data.createAction.id, e, false);
                    });
                }
                setTimeout(() => {
                    this.searchActionQuery.refetch({input: this.searchActionInput});
                    this.loading.hide();
                    super.closeModal('modalAction');
                    this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('acaocriada'), 'success');
                }, 500);
            }, err => super.onError(err)
        );
    }

    actionUpdateAction() {
        const createActionResponsibleInput: CreateActionResponsibleInput = {id: this.responsible};
        const CreateActionIdeaInput: CreateActionIdeaInput = {id: this.modelAction.idea.id};
        const input: UpdateActionInput = {
            id: this.modelAction.id,
            title: this.modelAction.title,
            description: this.modelAction.description,
            idea: CreateActionIdeaInput,
            responsible: createActionResponsibleInput,
            startDate: this.modelAction.startDate,
            endDate: this.modelAction.endDate,
            status: this.modelAction.status
        };
        this.loading.show();
        this.updateActionGQL.mutate({input: input}).subscribe(
            (data) => {
                this.loading.hide();
                setTimeout(() => {
                    super.closeModal('modalAction');
                    this.showMessage('Sucesso!', `Atualização realizada com sucesso!`, 'success');
                    this.searchActionQuery.refetch({input: this.searchActionInput});
                }, 500);
            }, err => super.onError(err)
        );
    }

    onComment() {
        if (super.isNullOrUndefined(this.comments)) {
            return this.showMessage('Atenção', `Digite uma mensagem antes de enviar!`);
        }
        if (super.isNullOrUndefined(this.modelAction.id)) {
            this.pushComment();
        } else {
            this.actionComment(this.modelAction.id, this.comments);
        }
        this.comments = undefined;
    }

    pushComment() {
        this.userService.user.subscribe((data) => {
            this.modelAction.comments = Object.assign([], this.modelAction.comments);
            let comment: ActionComment = new ActionComment();
            comment.comment = this.comments;
            comment.createdAt = this.dateNow;
            comment.user = data;
            this.modelAction.comments.push(comment);
        });
    }

    actionComment(id: string, comments: string) {
        this.modelAction.comments = Object.assign([], this.modelAction.comments);
        const action: CreateActionCommentActionInput = {id: id};
        const input: CreateActionCommentInput = {action: action, comment: comments};

        this.createActionCommentGQL.mutate({input: input}).subscribe(
            ({data}) => {
                this.modelAction.comments.push(data.createActionComment as ActionComment);
                this.searchActionQuery.refetch({input: this.searchActionInput});
            }, err => super.onError(err)
        );
    }

    onTask() {
        if (super.isNullOrUndefined(this.task)) {
            return this.showMessage('Atenção', `Digite uma ação antes de enviar!`);
        }
        if (super.isNullOrUndefined(this.modelAction.id)) {
            this.pushTask();
        } else {
            this.actionTask(this.modelAction.id, this.task);
        }
        this.task = undefined;
    }

    pushTask() {
        this.modelAction.tasks = Object.assign([], this.modelAction.tasks);
        let task: ActionTask = new ActionTask();
        task.description = this.task;
        this.modelAction.tasks.push(task);
    }

    actionTask(id: string, task: string) {
        this.modelAction.tasks = Object.assign([], this.modelAction.tasks);
        const action: CreateActionCommentActionInput = {id: id};
        const input: CreateActionTaskInput = {action: action, description: task};

        this.createActionTaskGQL.mutate({input: input}).subscribe(
            ({data}) => {
                this.modelAction.tasks.push(data.createActionTask as ActionTask);
                this.searchActionQuery.refetch({input: this.searchActionInput});
            }, err => super.onError(err)
        );
    }

    updateTaskStatus(task: ActionTask) {
        this.updateActionTaskStatusGQL.mutate({id: task.id}).subscribe(
            ({data}) => {
                this.searchActionQuery.refetch({input: this.searchActionInput});
            }, err => super.onError(err)
        );
    }

    removeActionTask(index: number) {

        super.confirmMessage('Atenção', 'Deseja realmente excluir essa tarefa?', () => {
            if (super.isNullOrUndefined(this.modelAction.id)) {
                return this.modelAction.tasks.splice(index, 1);
            }
            this.removeActionTaskGQL.mutate({id: this.modelAction.tasks[index].id}).subscribe(
                ({data}) => {
                    this.modelAction.tasks = Object.assign([], this.modelAction.tasks);
                    this.modelAction.tasks.splice(index, 1);
                    this.showMessage('Sucesso!', `Remoção realizada com sucesso!`, 'success');
                    this.searchActionQuery.refetch({input: this.searchActionInput});
                }, err => super.onError(err));
        });
    }

    handleUpload(event) {
        const files = event.target.files;
        this.file = files[0];
        let type = this.file.name.toLowerCase().split('.');
        if (files.length > 0) {
            if (files[0].size > 60000000) {
                super.showMessage(this.translate.instant('profile.edit.warningAttention'),
                    this.translate.instant('profile.edit.warningUpload'), 'info');
                return;
            }

            if (type[type.length - 1].toLowerCase() !== 'jpeg'
                && type[type.length - 1].toLowerCase() !== 'png'
                && type[type.length - 1].toLowerCase() !== 'jfif'
                && type[type.length - 1].toLowerCase() !== 'pdf'
                && type[type.length - 1].toLowerCase() !== 'doc'
                && type[type.length - 1].toLowerCase() !== 'xlt'
                && type[type.length - 1].toLowerCase() !== 'xls'
                && type[type.length - 1].toLowerCase() !== 'xlsx'
                && type[type.length - 1].toLowerCase() !== 'sheet'
                && type[type.length - 1].toLowerCase() !== 'xml'
                && type[type.length - 1].toLowerCase() !== 'ppt'
                && type[type.length - 1].toLowerCase() !== 'pptx'
                && type[type.length - 1].toLowerCase() !== 'txt'
                && type[type.length - 1].toLowerCase() !== 'plain'
                && type[type.length - 1].toLowerCase() !== 'docx') {
                super.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('warningUploadExtensions'),
                    'info');
                return;
            }
        }

        let reader = new FileReader();
        reader.onload = (_event) => {

            this.thumpPath = type[type.length - 1];
            this.thumpName = this.file.name;
            if (type[type.length - 1] === 'jpeg' || type[type.length - 1] === 'png' || type[type.length - 1] === 'jfif') {
                this.thumpPath = _event.target.result;
            }

            console.log(this.thumpPath);
            if (super.isNullOrUndefined(this.modelAction.id)) {
                this.pushImage(this.file);
            } else {
                this.uploadImage(this.modelAction.id, this.file);
            }
        };
        reader.readAsDataURL(event.target.files[0]);
        $('#doc-1').val('');
    }

    uploadImage(id: string, file: File, success = true) {
        this.loading.show();
        this.userService.upload(file, id, 'action').subscribe(
            data => {
                if (success) {
                    this.modelAction.files = Object.assign([], this.modelAction.files);
                    this.modelAction.files.push(data as ActionFile);
                    this.loading.hide();
                    this.showMessage('Sucesso!', `Atualização realizada com sucesso!`, 'success');
                }
            }, error => super.onError(error));
    }

    pushImage(file: File) {
        this.modelAction.files = Object.assign([], this.modelAction.files);
        let actionFile: ActionFile = new ActionFile();
        actionFile.url = this.thumpPath;
        actionFile.name = this.thumpName;
        this.createFiles.push(file);
        this.modelAction.files.push(actionFile);
    }

    onUpdateStatus() {
        if (this.isNullOrUndefined(this.modelAction.id)) {
            return;
        }
        this.updateActionStatusGQL.mutate({
            id: this.modelAction.id,
            status: this.modelAction.status
        }).subscribe(({data}) => {
            this.searchActionQuery.refetch({input: this.searchActionInput});
        }, error => super.onError(error));

    }

    getCurrentStatus(event) {
        this.currentStatus = event;
    }

    updateList(event: Action) {
        // const kambanId = this.listKamban.findIndex((e) => {
        //     return e.name == this.currentStatus;
        // });
        // const cardId = this.listKamban[kambanId].cards.findIndex((e) => {
        //     return e.action.id == event.id;
        // });
        // console.log(this.listKamban[kambanId].cards[cardId]);

        this.searchActionQuery.refetch({input: this.searchActionInput});
    }

    removeActionFile(id: string, index: number) {
        super.confirmMessage('Atenção', 'Deseja realmente excluir esse anexo?', () => {
            if (super.isNullOrUndefined(this.modelAction.id)) {
                return this.modelAction.files.splice(index, 1);
            }
            this.loading.show();
            this.removeActionFileGQL.mutate({id: id}).subscribe(({data}) => {
                this.modelAction.files = Object.assign([], this.modelAction.files);
                this.modelAction.files.splice(index, 1);
                this.searchActionQuery.refetch({input: this.searchActionInput});
                this.loading.hide();
                this.showMessage('Sucesso!', `anexo removido com sucesso!`, 'success');
            }, error => this.onError(error));
        });

    }

    actionSearchPage() {
        this.loading.show();
        this.searchActionHistoryQuery.refetch({
            id: this.modelAction.id,
            page: this.page
        }).finally(() => this.loading.hide());
    }

    pageChanged(event) {
        this.page = event;
        this.actionSearchPage();
    }

    onItemSelectUnities(item: any) {
        this.searchActionInput.idea = item.id;
    }

    onItemDeselectUnities(item: any) {
        this.searchActionInput.idea = undefined;
    }

    selectIdeaByAction(item: any) {
        this.selectIdea = this.listIdeasAction.find(e => e.id == item.id);
    }

    deSelectIdeaByAction(item: any) {
        this.selectIdea = undefined;
    }

    selectResponsible(item: any) {
        this.searchActionInput.responsible.push(item.id);
    }

    deSelectResponsible(item: any) {
        const index = this.searchActionInput.responsible.indexOf(item.id);
        if (index > -1) {
            this.searchActionInput.responsible.splice(index, 1);
        }
    }

    selectedAllResponsible(item: any) {
        this.searchActionInput.responsible = [];
        item.forEach((e) => {
            this.searchActionInput.responsible.push(e.id);
        });
    }

    actionCleanFilter() {
        this.searchActionInput = new SearchActionInput();
        this.selectedItemsResponsible = [];
        this.selectedUnityItems = undefined;
        this.committee = [];
    }

    typeFileNew(url: string) {
        switch (url) {
            case 'pdf':
                return 'assets/svg/pdf.svg';
            case url.startsWith('data') ? url : 'url':
                return url;
            case 'ppt':
            case 'pptx':
                return 'assets/svg/ppt.svg';
            case 'xls':
            case 'xlsx':
                return 'assets/svg/xls.svg';
            default:
                return 'assets/svg/doc.svg';
        }
    }

    getType(url) {
        let a = url.split('.');
        switch (a[a.length - 1]) {
            case 'png':
            case 'jpg':
            case 'jfif':
                return true;
            default:
                return false;
        }
    }

    typeFileUpdate(url: string) {
        let a = url.split('.');
        switch (a[a.length - 1]) {
            case 'pdf':
                return 'assets/svg/pdf.svg';
            case 'png':
            case 'jpg':
            case 'jfif':
                return url;
            case 'ppt':
            case 'pptx':
                return 'assets/svg/ppt.svg';
            case 'xls':
            case 'xlsx':
                return 'assets/svg/xls.svg';
            default:
                return 'assets/svg/doc.svg';
        }
    }

    userProfile() {
        switch (this.user.role) {
            case 'admin':
                this.addAction = true;
                this.unityCountry = true;
                break;
            case 'user':
                this.addAction = true;
                this.unityCountry = true;
                break;
            case 'corporate-manager':
                this.addAction = true;
                this.unityCountry = true;
                break;
            case 'local-manager':
                this.addAction = true;
                this.unityCountry = true;
                break;
            case 'committee-member':
                this.addAction = false;
                this.unityCountry = false;
                break;
        }
    }

    onSelectDropCommitte($event: [Committee]) {
        this.searchActionInput.committee = $event.map(value => value.id);
    }

    // Método para ajustar a altura da textarea dinamicamente
    adjustTextareaHeight() {
        const descriptionTextarea = this.descriptionTextarea.nativeElement;
        descriptionTextarea.style.height = 'auto'; // Reinicia a altura para deixar o navegador calcular o tamanho necessário
        descriptionTextarea.style.height = descriptionTextarea.scrollHeight + 'px'; // Ajuste a altura da textarea com base no conteúdo

        const titleTextarea = this.titleTextarea.nativeElement;
        titleTextarea.style.height = 'auto'; // Reinicia a altura para deixar o navegador calcular o tamanho necessário
        titleTextarea.style.height = titleTextarea.scrollHeight + 'px'; // Ajuste a altura da textarea com base no conteúdo
    }
}
