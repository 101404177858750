<nav id="sidebar">
    <ul class="list-unstyled components navbar-nav" id="accordionExample">

        <li class="header__mobile d-lg-none">
            <div class="header">
                <div class="d-flex justify-content-around text-white">
                    <font class="header_font text-white">Idioma:</font>
                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'pt'}"
                       href="javascript:void('');"
                       (click)="switchLanguage('pt')">PT</a>
                    <span class="separator">|</span>
                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'es'}"
                       href="javascript:void('');"
                       (click)="switchLanguage('es')">ES</a>
                </div>
                <div class="header__btn mobile_notification notification_trigger">
                    <app-notification></app-notification>
                </div>
            </div>
        </li>
        <li class="nav-item active" *ngIf="btnNew">
            <a href="javascript: void(0);" (click)="router.navigate(['/register'])"
               class="btn btn-warning btn-home-custom">
              <span class="icon flex-grow-0 mh-100">
                <svg-icon src="assets/svg/plus.svg" class="svg-plus--white--black"></svg-icon>
              </span>
                <span class="separator">|</span>
                <span class="text flex-grow-1 btn-mini-text ">{{'sidebar.novasolicitacao' | translate}}</span>
            </a>

        </li>
        <li class="nav-item menu-custom" [ngClass]="{'active':router.url === '/'}">
            <a href="javascript:void('Nota');" [routerLink]="['/']"
               class="nav-link side-primary"
               style="gap: 20px"

               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/home.svg"></svg-icon>
                <span class="lbl">Home</span>
            </a>
        </li>
        <li class="nav-item menu-custom" [ngClass]="{'active':router.url === '/dashboard'}">
            <a href="javascript:void('Nota');"
               *ngIf="dashboardSidebar"
               [routerLink]="['/dashboard']"
               class="nav-link side-primary"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/dashboard.svg"></svg-icon>
                <span class="lbl">Dashboard</span>
            </a>
        </li>
        <li class="nav-item menu-custom"
            [ngClass]="{'active':router.url == '/my-list'||getRoute() === '/kanban'||getRoute() === '/my-list-detail'}">
            <a href="javascript:void('Nota');"
               *ngIf="ideasSidebar"
               class="nav-link side-primary" style="gap: 20px"
               data-toggle="collapse"
               data-target="#idea" aria-expanded="false"
               aria-controls="idea">
                <svg-icon src="assets/svg/idea.svg"></svg-icon>
                <span class="lbl">{{'sidebar.ideias' | translate}}</span>
            </a>
            <div class="collapse" id="idea" data-parent="#accordionExample"
                 [ngClass]="{'show':router.url == '/my-list'||getRoute() === '/kanban'||getRoute() === '/my-list-detail'}">
                <a href="javascript:void(0);"
                   *ngIf="listSidebar"
                   [routerLink]="['/my-list']"
                   (click)="actionCloseCollapse()"
                   [ngClass]="{'active':router.url === '/my-list'||getRoute() === '/my-list-detail'}"
                   class="nav-link side-secondary"
                   style="gap: 20px">
                    <svg-icon src="assets/svg/list.svg"></svg-icon>
                    <span class="lbl">Lista</span>
                </a>
                <a href="javascript:void(0);" [routerLink]="['/kanban']"
                   *ngIf="kanbanSidebar"
                   [ngClass]="{'active':getRoute() === '/kanban'}"
                   (click)="actionCloseCollapse()"
                   class="nav-link side-secondary"
                   style="gap: 20px">
                    <svg-icon src="assets/svg/kamban.svg"></svg-icon>
                    <span class="lbl">Kanban</span>
                </a>
            </div>
        </li>
        <li class="nav-item menu-custom" [ngClass]="{'active':router.url === '/clic-news'}">
            <a href="javascript:void('Nota');"
               *ngIf="newsSidebar"
               [routerLink]="['/clic-news']"
               class="nav-link side-primary"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/news.svg"></svg-icon>
                <span class="lbl">Clic News</span>
            </a>
        </li>
        <li class="nav-item menu-custom" [ngClass]="{'active':router.url === '/challenge'}">
            <a href="javascript:void('Nota');"
               *ngIf="chalengeSidebar"
               [routerLink]="['/challenge']"
               class="nav-link side-primary"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/dashboard.svg"></svg-icon>
                <span class="lbl">{{'challenges' | translate}}</span>
            </a>
        </li>
        <li class="nav-item  menu-custom" [ngClass]="{'active':routerManager}">
            <a href="javascript:void('Nota');"
               *ngIf="managementSidebar"
               class="nav-link side-primary"
               style="gap: 20px"
               data-toggle="collapse"
               data-target="#management" aria-expanded="false"
               aria-controls="management">
                <svg-icon class="side-primary" src="assets/svg/gear.svg"></svg-icon>
                <span class="lbl">{{'management' | translate}}</span>
            </a>
            <div class="collapse" id="management" data-parent="#accordionExample"
                 [ngClass]="{'show':routerManager}">
                <a href="javascript:void(0);"
                   *ngIf="userSidebar"
                   [routerLink]="['/user']"
                   (click)="actionCloseCollapse()"
                   [ngClass]="{'active':router.url === '/user'}"
                   class="nav-link side-secondary"
                   style="gap: 20px">
                    <svg-icon src="assets/svg/users.svg"></svg-icon>
                    <span class="lbl">{{'users' | translate}}</span>
                </a>
                <a href="javascript:void(0);"
                   *ngIf="areaSidebar"
                   [routerLink]="['/area']"
                   (click)="actionCloseCollapse()"
                   [ngClass]="{'active':router.url === '/area'}"
                   class="nav-link side-secondary"
                   style="gap: 20px">
                    <svg-icon src="assets/svg/area.svg"></svg-icon>
                    <span class="lbl">Áreas</span>
                </a>
                <a href="javascript:void(0);"
                   *ngIf="unitySidebar"
                   [routerLink]="['/unity']"
                   (click)="actionCloseCollapse()"
                   [ngClass]="{'active':router.url === '/unity'}"
                   class="nav-link side-secondary"
                   style="gap: 20px">
                    <svg-icon src="assets/svg/unity.svg"></svg-icon>
                    <span class="lbl">Unidades</span>
                </a>
                <a href="javascript:void(0);"
                   *ngIf="committeeSidebar"
                   [routerLink]="['/committee']"
                   (click)="actionCloseCollapse()"
                   [ngClass]="{'active':router.url === '/committee'}"
                   class="nav-link side-secondary"
                   style="gap: 20px">
                    <svg-icon src="assets/svg/comite.svg"></svg-icon>
                    <span class="lbl">{{'committee' | translate}}</span>
                </a>
            </div>
        </li>
    </ul>
    <!--    <div class="logout d-lg-none">-->
    <!--        <a href="javascript:void('sair');" [routerLink]="['/logout']">-->
    <!--            {{'sair' | translate}}-->
    <!--        </a>-->
    <!--    </div>-->

</nav>
