import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {
    GetNewsSearchGQL,
    GetUnitiesGQL,
    GetNewsSearchQuery,
    Unity,
    News,
    User,
    RemoveNewsGQL, NewsPage
} from '../../../generated/graphql';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {QueryRef} from 'apollo-angular';
import {UserService} from "../../service/user.service";

@Component({
    selector: 'app-clic-news',
    templateUrl: './clic-news.component.html',
    styleUrls: ['./clic-news.component.scss']
})
export class ClicNewsComponent extends BaseComponent implements OnInit {
    user: User = new User();
    getNewsSearchQuery: QueryRef<GetNewsSearchQuery>;
    news: NewsPage = new NewsPage() // News[] = [];
    modelFilterKeyword = '';
    modelFilterStatus = '';
    language = 'pt';
    page = 1;

    newClicNews: boolean;
    editClicNews: boolean;
    deleteClicNews: boolean;

    constructor(
        public router: Router,
        public loading: NgxSpinnerService,
        public translate: TranslateService,
        public getNewsSearchGQL: GetNewsSearchGQL,
        public removeNewsGQL: RemoveNewsGQL,
        public userService: UserService
    ) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.userProfile();
            }, error: err => super.onError(err)
        });
        this.userService.language.subscribe({
            next: data => {
                this.language = this.isNullOrUndefined(data) ? this.translate.currentLang : data;
                this.page = 1;
                this.getNewsSearch();
            }, error: err => super.onError(err)
        })
    }

    pageChanged(event) {
        this.page = event;
        this.getNewsSearch();
    }


    actionSearch() {
        this.getNewsSearchQuery.refetch({
            input: this.modelFilterKeyword,
            status: this.modelFilterStatus,
            language: this.language === 'es' ? 'es-ES' : 'pt-BR'
        });
    }

    getNewsSearch() {
        this.getNewsSearchQuery = this.getNewsSearchGQL.watch({
            page: this.page,
            input: this.modelFilterKeyword,
            status: this.modelFilterStatus,
            language: this.language === 'es' ? 'es-ES' : 'pt-BR'
        });
        this.getNewsSearchQuery.valueChanges.subscribe(({data}) => {
            this.news = data.getNewsSearch as NewsPage;
        });
    }

    removeNews(id) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), 'Deseja realmente deletar essa notícia?', () => {
            this.loading.show();
            this.removeNewsGQL.mutate({input: id}).subscribe(({data}) => {
                this.getNewsSearchQuery.refetch({
                    input: this.modelFilterKeyword,
                    status: this.modelFilterStatus,
                    language: this.language === 'es' ? 'es-ES' : 'pt-BR'
                });
                this.showMessage('Sucesso', `Notícia removida com sucesso!`, 'success');
                this.loading.hide();

            });
        });
    }

    userProfile() {
        switch (this.user.role) {
            case 'admin':
                this.newClicNews = true;
                this.editClicNews = true;
                this.deleteClicNews = true;
                break;
            case 'user':
                this.newClicNews = true;
                this.editClicNews = true;
                this.deleteClicNews = true;
                break;
            case 'corporate-manager':
                this.newClicNews = true;
                this.editClicNews = true;
                this.deleteClicNews = true;
                break;
            case 'local-manager':
                this.newClicNews = true;
                this.editClicNews = true;
                this.deleteClicNews = false;
                break;
            case 'committee-member':
                this.newClicNews = false;
                this.editClicNews = false;
                this.deleteClicNews = false;
                break;

        }
    }
}
