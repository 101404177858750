import {Component, Input, OnInit} from '@angular/core';
import {CardSchema} from "../../../model/kamban/card-schema";
import {ActionKamban} from "../../../../generated/graphql";

@Component({
    selector: 'app-kamban-card',
    templateUrl: './kamban-card.component.html',
    styleUrls: ['./kamban-card.component.scss']
})
export class KambanCardComponent implements OnInit {
    @Input() card: ActionKamban;

    constructor() {
    }

    ngOnInit() {
    }

    dragStart(ev) {
        ev.dataTransfer.setData("text", ev.target.id);
    }

}
