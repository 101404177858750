import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {
    Committee,
    CommitteesGQL,
    CommitteesQuery, GetAuthorsGQL,
    GetIdeaCommiteeByIdGQL,
    GetUnitiesGQL,
    GetUnitiesQuery,
    GetUsersGQL,
    Idea,
    IdeaPage, InputMaybe,
    RemoveCommitteeGQL,
    RemoveIdeaGQL, Scalars,
    SearchAllIdeasGQL,
    SearchAllIdeasQuery,
    SearchIdeaInput,
    Unity,
    User,
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import moment from 'moment';
import {ExcelService} from '../../service/excel.service';
import {Subscription} from 'rxjs';
import {UserService} from '../../service/user.service';
import {newArray} from '@angular/compiler/src/util';


declare var $: any;

@Component({
    selector: 'app-my-list',
    templateUrl: './my-list.component.html',
    styleUrls: ['./my-list.component.scss']
})
export class MyListComponent extends BaseComponent implements OnInit, AfterViewInit {
    user: User = new User();
    subscription: Subscription;
    keyword = '';
    status;
    getUnity;
    checked = true;
    searchIdeaInput: SearchIdeaInput = new SearchIdeaInput();

    getIdeasSearchQuery: QueryRef<SearchAllIdeasQuery>;

    ideas: IdeaPage = new IdeaPage();
    committess: Array<Committee> = [];
    unities: Array<Unity> = [];
    committee: Array<Committee> = [];
    users: Array<User> = [];
    authors: Array<Idea> = [];
    author: Array<Idea> = [];

    listStatusDrop = [];
    filterStatus = [];

    listStatusItems = [];

    dropSettings: any = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Marcar todos',
        unSelectAllText: 'Desmarcar todos',
        itemsShowLimit: 3,
        allowSearchFilter: true
    };

    dropSettingsAuthor: any = {
        singleSelection: false,
        idField: 'authorName',
        textField: 'authorName',
        selectAllText: 'Marcar todos',
        unSelectAllText: 'Desmarcar todos',
        itemsShowLimit: 3,
        allowSearchFilter: true
    };

    btnUnity: boolean;
    btnCommittee: boolean;
    deleteIdea: boolean;
    period: boolean;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public searchAllIdeasGQL: SearchAllIdeasGQL,
                public getUnitiesGQL: GetUnitiesGQL,
                public committeesGQL: CommitteesGQL,
                public removeIdeiaGQL: RemoveIdeaGQL,
                public getUsersGQL: GetUsersGQL,
                public getAuthorsGQL: GetAuthorsGQL,
                public userService: UserService,
                public excelService: ExcelService,
    ) {
        super(router, loading, translate);

        this.subscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            $(() => {
                this.createStatusArray();
                this.cleanFilter();
            });
        });
    }

    ngOnInit() {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.userProfile();
            }, error: err => super.onError(err)
        });
        this.searchIdeaInput.start = moment().subtract(90, 'days');
        this.searchIdeaInput.end = moment();
        this.setDatePicker('picker-period', (start, end, label) => {
            this.searchIdeaInput.start = start.utcOffset(0, true).format();
            this.searchIdeaInput.end = end.utcOffset(0, true).format();
        });
        this.handlerFilterModel();
        this.createStatusArray();
        this.actionIdea();
        this.getUnities();
        this.committees();
        // this.getUsers();
        this.getAuthors();
    }

    createStatusArray() {
        this.listStatusDrop = [
            {id: 'received', name: this.translate.instant('received')},
            {id: 'analysis', name: this.translate.instant('analysis')},
            {id: 'approved', name: this.translate.instant('approved')},
            {id: 'denied', name: this.translate.instant('denied')},
            {id: 'denial', name: this.translate.instant('denial')},
            {id: 'awarded', name: this.translate.instant('awarded')},
            {id: 'approved_award', name: this.translate.instant('approved_award')}
        ];
    }

    getUnities() {
        this.getUnitiesGQL.watch().valueChanges.subscribe(({data}) => {
            this.unities = data.getUnities as Array<Unity>;
        });
    }

    committees() {
        this.committeesGQL.watch().valueChanges.subscribe(({data}) => {
            this.committee = data.committees as Array<Committee>;
        });
    }

    // getUsers() {
    //     this.getUsersGQL.watch().valueChanges.subscribe(({data}) => {
    //         this.users = data.getUsers as Array<User>;
    //     });
    // }

    getAuthors() {
        this.getAuthorsGQL.watch().valueChanges.subscribe(({data}) => {
            this.author = data.getAuthors as Array<Idea>;
        });
    }

    actionIdea() {
        this.loading.show();
        this.getIdeasSearchQuery = this.searchAllIdeasGQL.watch({input: this.handlerInput()});
        this.getIdeasSearchQuery.valueChanges.subscribe(({data}) => {
            this.ideas = data.searchAllIdeas as IdeaPage;
            this.loading.hide();
        });
    }

    ngAfterViewInit() {
    }


    actionClear() {
        this.keyword = undefined;
        this.getUnity = undefined;
        this.actionSearch();
    }


    handlerInput() {
        const input: SearchIdeaInput = {
            authorName: this.searchIdeaInput.authorName,
            committeeId: this.searchIdeaInput.committeeId,
            countryId: this.searchIdeaInput.countryId,
            start: this.period ? this.searchIdeaInput.start : undefined,
            end: this.period ? this.searchIdeaInput.end : undefined,
            keyword: this.searchIdeaInput.keyword,
            order: this.searchIdeaInput.order,
            page: this.searchIdeaInput.page,
            status: this.searchIdeaInput.status,
            unityId: this.searchIdeaInput.unityId,
        };
        return input;
    }

    actionSearch() {
        this.searchIdeaInput.status = [];
        this.searchIdeaInput.committeeId = [];
        this.searchIdeaInput.authorName = [];

        this.listStatusItems.forEach((e) => {
            this.searchIdeaInput.status.push(e.id);
        });
        this.committess.forEach((e) => {
            this.searchIdeaInput.committeeId.push(e.id);
        });
        this.authors.forEach((e) => {
            this.searchIdeaInput.authorName.push(e.authorName);
        });
        super.setFilterLocal('filterIdea', this.searchIdeaInput);
        super.setFilterLocal('committess', this.committess);
        super.setFilterLocal('authors', this.authors);

        this.loading.show();
        this.getIdeasSearchQuery.refetch({input: this.handlerInput()}).finally(() => this.loading.hide());
    }

    actionExcel() {
        // let modelExcel = {
        //     ...this.searchIdeaInput,
        //     userId: this.user.id
        // }

        this.excelService.getExcelIdea(this.handlerInput()).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }

    pageChanged(event) {
        this.searchIdeaInput.page = event;
        this.actionSearch();
    }

    downloadHandlerFile(e) {
        $('#btnDownload').attr('href', e);
        setTimeout(() => {
            $('#btnDownload')[0].click();
        });
    }

    cleanFilter() {
        this.searchIdeaInput.keyword = undefined;
        this.searchIdeaInput.unityId = undefined;
        this.searchIdeaInput.committeeId = undefined;
        this.searchIdeaInput.status = [];
        this.committess = [];
        this.authors = [];
        this.listStatusItems = [];
        // this.searchIdeaInput.responsibleId = undefined;
        this.searchIdeaInput.authorName = undefined;
        this.searchIdeaInput.start = moment().subtract(90, 'days');
        this.searchIdeaInput.end = moment();
        this.setDatePicker('picker-period', (start, end, label) => {
            this.searchIdeaInput.start = start.utcOffset(-90, true).format();
            this.searchIdeaInput.end = end.utcOffset(0, true).format();
        });
        localStorage.removeItem('filterIdea');
    }

    onSelectDrop(item: any[]): void {
        const el = item.findIndex((e) => e.id === 'denied');
        this.period = el >= 0;
    }

    onDeselect(item: any): void {
    }

    validateUser() {
        if (this.user.role !== 'admin') {
            return false;
        }
        return true;
    }

    private handlerFilterModel() {
        const localFilter = super.getFilterLocal('filterIdea');
        const modelFormat = JSON.stringify(this.searchIdeaInput);

        if (!this.isNullOrUndefined(localFilter) && (modelFormat !== localFilter)) {

            const el = JSON.parse(localFilter);
            $('.picker-period').daterangepicker({
                startDate: moment(el.start),
                endDate: moment(el.end),
                locale: super.getObjectLocaleDatePicker()
            });
            this.searchIdeaInput = JSON.parse(localFilter);

            this.searchIdeaInput.status.forEach((s) => {
                this.listStatusDrop.push({id: s, name: this.translate.instant(s)});
                this.listStatusItems.push({id: s, name: this.translate.instant(s)});
            });
        }


        const localCommittes = super.getFilterLocal('committess');
        const modelFormatCommittes = JSON.stringify(this.committess);
        if (!this.isNullOrUndefined(localCommittes) && (modelFormatCommittes !== localCommittes)) {
            this.committess = JSON.parse(localCommittes);
        }


        const localAuthor = super.getFilterLocal('authors');
        const modelFormatAuthor = JSON.stringify(this.authors);
        if (!this.isNullOrUndefined(localAuthor) && (modelFormatAuthor !== localAuthor)) {
            this.authors = JSON.parse(localAuthor);
        }
    }

    userProfile() {
        switch (this.user.role) {
            case 'admin':
                this.btnUnity = true;
                this.btnCommittee = true;
                this.deleteIdea = true;
                break;
            case 'user':
                this.btnUnity = true;
                this.btnCommittee = true;
                this.deleteIdea = false;
                break;
            case 'corporate-manager':
                this.btnUnity = true;
                this.btnCommittee = true;
                this.deleteIdea = true;
                break;
            case 'local-manager':
                this.btnUnity = true;
                this.btnCommittee = true;
                this.deleteIdea = false;
                break;
            case 'committee-member':
                this.btnUnity = false;
                this.btnCommittee = false;
                this.deleteIdea = false;
                break;

        }
    }

    onSelectDropCommitte($event: [Committee]) {
        // this.searchIdeaInput.committeeId = $event.map(value => value.id);
    }

    onSelectDropAuthors($event: [Idea]) {
        // this.searchIdeaInput.authorName = $event.map(value => value.authorName);
    }

    onDelete(id: string) {
        super.confirmMessage('Atenção', 'Deseja realmente excluir essa ideia?', () => {
            this.loading.show();
            this.removeIdeiaGQL.mutate({id: id}).subscribe(
                (data) => {
                    this.getIdeasSearchQuery.refetch({input: this.searchIdeaInput});
                    this.loading.hide();
                }, err => super.onError(err));
        });
    }
}
