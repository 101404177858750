<div class="list-detail">
    <div class="list-detail_col-primary">
        <div class="d-flex align-items-end my-4">
            <svg-icon class="is-cursor" routerLink="/my-list" src="assets/svg/arrow.svg"></svg-icon>
            <h1 class="list-detail_title"><span>{{idea?.code}} |</span> {{idea?.title}}</h1>
            <div class="register-date">
                <dl>
                    <dt class="list-detail_lbl">Data de Cadastro</dt>
                    <dd class="list-detail_value">{{idea?.createdAt| date:'dd/MM/yyyy'}}</dd>
                </dl>
            </div>
            <span *ngIf="!isNullOrUndefined(idea?.status)" class="item_status text-capitalize" [ngClass]="idea?.status"
                  [ngStyle]="{'background': getStatusColors(idea?.status)}">
                            <svg-icon src="assets/svg/{{idea?.status}}.svg"></svg-icon>
                {{getTranslate(idea?.status)}}
            </span>
        </div>
        <div class="list-detail_info">
            <dl class="flex-grow-1 px-2">
                <dt class="list-detail_lbl">Idealizador</dt>
                <dd class="list-detail_value">{{idea?.authorName}}</dd>
            </dl>
            <dl class="flex-grow-1 px-2">
                <dt class="list-detail_lbl">RE</dt>
                <dd class="list-detail_value">{{idea?.re}}</dd>
            </dl>
            <dl class="flex-grow-1 px-2">
                <dt class="list-detail_lbl">{{'unity' | translate}}</dt>
                <dd class="list-detail_value">{{idea?.unity?.name}}</dd>
            </dl>
            <dl class="flex-grow-1 px-2">
                <dt class="list-detail_lbl">Cargo</dt>
                <dd class="list-detail_value">{{idea?.role}}</dd>
            </dl>
            <dl class="flex-grow-1 px-2">
                <dt class="list-detail_lbl">Centro de custo</dt>
                <dd class="list-detail_value">{{idea?.costCenter}}</dd>
            </dl>
            <dl class="flex-grow-1 px-2">
                <dt class="list-detail_lbl">Email</dt>
                <dd class="list-detail_value">{{idea?.email}}</dd>
            </dl>
        </div>
        <div *ngIf="!isNullOrUndefined(idea.authors)" class="participant_title mb-3">
            <div class="divider position-absolute m-0" style="top: 56%;">&nbsp;</div>
            <span>Participantes</span>
        </div>
        <!--        <div class="mb-3">-->
        <!--            <div class="mt-3" *ngFor="let i of idea.authors">-->
        <!--                <div class="d-flex justify-content-between align-items-center mt-1 mb-2" >-->
        <!--                    <span class="participant_lbl">{{i.name}}</span>-->
        <!--                    <span class="participant_value">{{i.re}}</span>-->
        <!--                    <span class="participant_value">{{i.jobRole}}</span>-->
        <!--                    <span class="participant_value">{{i.area}}</span>-->
        <!--                    <span class="participant_value">{{i.costCenter}}</span>-->
        <!--                    <span class="participant_value">{{i.email}}</span>-->
        <!--                </div>-->
        <!--                <div class="divider">&nbsp;</div>-->
        <!--            </div>-->
        <!--        </div>-->


        <div class="table-responsive">
            <table class="table table_regular">
                <thead>
                <tr>
                    <th scope="col">Autor</th>
                    <th scope="col">RE</th>
                    <th scope="col">Cargo</th>
                    <th scope="col">Area</th>
                    <th scope="col">Centro de custo</th>
                    <th scope="col">Email</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(idea.authors)">
                    <td colspan="8" class="text-center" style="padding: 68px 0;"> Nenhum autor encontrado!</td>
                </tr>
                <tr *ngFor="let i of idea.authors">
                    <td>
                        <span class="participant_lbl">{{i.name}}</span>
                    </td>
                    <td>
                        <span class="participant_lbl">{{i.re}}</span>
                    </td>
                    <td>
                        <span class="participant_lbl">{{i.jobRole}}</span>
                    </td>
                    <td>
                        <span class="participant_lbl">{{i.area}}</span>
                    </td>
                    <td>
                        <span class="participant_lbl">{{i.costCenter}}</span>
                    </td>
                    <td>
                        <span class="participant_lbl">{{i.email}}</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <!--<div class="participant_title mb-3">
            <div class="divider position-absolute m-0" style="top: 56%;">&nbsp;</div>
            <span>Descrição</span>
        </div>
        <div class="mb-3">
            <div class="d-flex justify-content-between align-items-center mt-1 mb-2">
                <h2 class="participant_lbl">{{idea.description}}</h2>
            </div>
            <div class="divider">&nbsp;</div>
        </div>-->


        <div class="list-detail_card mt-4">
            <p class="list-detail_card_lbl" *ngIf="!isNullOrUndefined(idea?.challenge)">{{idea?.challenge?.title}}</p>
            <p class="list-detail_card_value" *ngIf="!isNullOrUndefined(idea?.challenge)">
                {{idea?.challenge?.description}}
            </p>
            <div class="divider my-2" style="height: 2px" *ngIf="!isNullOrUndefined(idea?.challenge)"></div>
            <div class="d-flex justify-content-between">
                <div class="d-block">
                    <p class="list-detail_card_title">{{'description' | translate}}</p>
                    <p class="list-detail_card_value col-9">{{idea.description}}</p>
                </div>
                <div class="d-block text-left col-3" *ngIf="idea.status == 'denied'">
                    <p class="list-detail_card_lbl">{{'motivoreprova' | translate}}</p>
                    <p class="list-detail_card_value">{{!isNullOrUndefined(idea.motive) ? idea.motive : "Não foi informado motivo para reprova"}}</p>
                </div>
            </div>
        </div>

        <div class="list-detail_card">
            <p class="list-detail_card_title">{{'implementation' | translate}}</p>
            <div class="list-detail_card_implement">
                <dl class="flex-grow-1" *ngIf="!isNullOrUndefined(idea?.unity?.name)">
                    <dt class="list-detail_card_lbl">Local</dt>
                    <dd class="list-detail_card_value">{{idea?.unity?.name}}</dd>
                </dl>
                <dl class="flex-grow-1" *ngIf="!isNullOrUndefined(idea?.areaResponsible)">
                    <dt class="list-detail_card_lbl">Área Principal</dt>
                    <dd class="list-detail_card_value">{{idea?.areaResponsible}}</dd>
                </dl>
                <dl class="flex-grow-1" *ngIf="!isNullOrUndefined(idea?.areaExtensionLine)">
                    <dt class="list-detail_card_lbl">Ramal | Telefone</dt>
                    <dd class="list-detail_card_value">{{idea?.areaExtensionLine}}
                        | {{idea.phone |mask:'(00) 00000-0000 ||+00 (00) 0000-0000'}}</dd>
                </dl>
                <dl class="flex-grow-1" *ngIf="!isNullOrUndefined(idea?.productiveDescription)">
                    <dt class="list-detail_card_lbl">Equipamento</dt>
                    <dd class="list-detail_card_value"> {{idea?.productiveDescription}}</dd>
                </dl>
            </div>
        </div>
        <!--        <div class="list-detail_card" *ngIf="!isNullOrUndefined(idea?.expectations)">-->
        <div class="list-detail_card" *ngIf="!isNullOrUndefined(idea?.expectations ) && idea?.status !== 'approved'">
            <p class="list-detail_card_title">{{'whatReturn' | translate}}</p>
            <dl class="flex-grow-1" *ngFor="let x of idea?.expectations">
                <dt class="list-detail_card_lbl text-capitalize">{{ getTranslate(x.expectation)}}</dt>
                <dd class="list-detail_card_value mb-5">
                    {{x.expectationDescription}}
                </dd>
            </dl>
        </div>
        <div class="list-detail_card" *ngIf="!isNullOrUndefined(idea.implementationResume)">
            <p class="list-detail_card_title d-flex align-items-center">
                <span class="flex-grow-1">{{'summaryIimplementation' | translate}}</span>
                <button *ngIf="validateStatus && !idea?.blocked" (click)="onModalImplementation()"
                        [disabled]="!editAction"
                        class="btn btn-primary p-2">
                    <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/edit.svg"></svg-icon>
                </button>
            </p>
            <div class="flex-grow-1">
                <p class="list-detail_card_value">
                    {{idea?.implementationResume}}
                </p>
            </div>
            <hr>

            <div class="d-flex flex-wrap" style="margin-top: 32px;gap: 20px">
                <dl class="flex-grow-1">
                    <dt class="definition-lb">Quantidade H/Homem</dt>
                    <dd class="definition-value"><span></span>{{idea.analysisManValue }}</dd>
                </dl>
                <dl class="flex-grow-1">
                    <dt class="definition-lb">Quantidade H/Máquina</dt>
                    <dd class="definition-value"><span></span>{{idea.analysisMachineValue }}</dd>

                </dl>
                <dl class="flex-grow-1">
                    <dt class="definition-lb">Retorno financeiro</dt>
                    <dd class="definition-value"><span></span>{{idea.analysisFinacialreturn | currency}}</dd>

                </dl>
                <dl class="flex-grow-1">
                    <dt class="definition-lb">Sustentabilidade</dt>
                    <dd class="definition-value"
                        style="font-size: 14px">{{
                        handlerSustainability(idea.sustainability)}}</dd>
                </dl>
            </div>
            <div class="d-flex flex-column flex-sm-row" style="margin-top: 20px;column-gap: 89px;row-gap: 20px">
                <div class="flex-grow-0">
                    <div class="definition-lb">Data da premiação</div>
                    <div class="definition-value" style="font-size: 18px">
                        <svg-icon src="assets/svg/date.svg"></svg-icon>
                        {{idea.premiationDate|date:'dd/MM/yyyy'}}
                    </div>
                </div>
                <div class="flex-grow-1 d-flex flex-wrap">
                    <!--<div class="definition-card">
                        <div class="card-name">{{idea?.conclusion?.fileName}}</div>
                        <a href="{{idea?.conclusion?.file}}" target="_blank"
                           class=" btn card-btn btn-gray-fill text-primary">
                            <svg-icon src="assets/svg/download.svg"></svg-icon>
                            Download
                        </a>
                    </div>-->
                    <div class="definition-card mx-1" *ngFor="let c of idea?.ideafiles">
                        <div class="card-name mr-3 mb-3">{{c?.fileName}}</div>
                        <a href="{{c?.file}}" target="_blank"
                           class=" btn card-btn btn-gray-fill text-primary">
                            <svg-icon src="assets/svg/download.svg"></svg-icon>
                            Download
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="list-detail_card definition" *ngIf="idea.father === null  && idea.status === 'awarded'">
            <p class="list-detail_card_title d-flex align-items-center">Publicação</p>
            <div class="d-flex align-items-center mb-3">
                <svg-icon src="assets\images\country\brazil.svg" style="width: 30px"></svg-icon>
                <p class="list-detail_card_lbl flex-grow-1 text-capitalize ml-3 mb-0">Português</p>
                <a (click)="showModalTranslateESPT(1)" class="btn btn-primary p-2">
                    <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/edit.svg"></svg-icon>
                </a>
            </div>
            <dl>
                <dt class="list-detail_card_lbl text-capitalize">Titulo</dt>
                <dd class="list-detail_card_value">{{modelSonsBR.title}}</dd>
            </dl>
            <dl>
                <dt class="list-detail_card_lbl text-capitalize">{{'description' | translate}}</dt>
                <dd class="list-detail_card_value">{{modelSonsBR.description}}</dd>
            </dl>
            <dt class="list-detail_card_lbl text-capitalize">Expectativa de ganho:</dt>
            <dl class="row align-items-center" *ngFor="let i of modelSonsBR.expectations;let index = index">
                <dt class="col-3">
                    <p class="list-detail_card_lbl text-capitalize text-center mb-0">{{i.expectation | translate}}</p>
                    <div class="divider_publication">&nbsp;</div>
                </dt>
                <dd class="col-8 list-detail_card_value py-2 mb-0"
                    [ngClass]="{'border-top':index != 0}">{{i.expectationDescription}}</dd>
            </dl>
            <div class="list-detail_divider" style="background-color: #8B8B8B">&nbsp;</div>

            <div class="d-flex align-items-center mb-3">
                <svg-icon src="assets\images\country\espanha.svg" [svgStyle]="{'border-radius':'50%'}"
                          style="width: 30px"></svg-icon>
                <p class="list-detail_card_lbl flex-grow-1 text-capitalize ml-3 mb-0">Espanhol</p>
                <a (click)="showModalTranslateESPT(2)" class="btn btn-primary p-2">
                    <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/edit.svg"></svg-icon>
                </a>
            </div>

            <dl>
                <dt class="list-detail_card_lbl text-capitalize">Titulo</dt>
                <dd class="list-detail_card_value">{{modelSonsES.title}}</dd>
            </dl>
            <dl>
                <dt class="list-detail_card_lbl text-capitalize">{{'description' | translate}}</dt>
                <dd class="list-detail_card_value">{{modelSonsES.description}}</dd>
            </dl>
            <dt class="list-detail_card_lbl text-capitalize">Expectativa de ganho:</dt>
            <dl class="row align-items-center" *ngFor="let i of modelSonsES.expectations;let index = index">
                <dt class="col-3">
                    <p class="list-detail_card_lbl text-capitalize text-center mb-0">{{i.expectation | translate}}</p>
                    <div class="divider_publication">&nbsp;</div>
                </dt>
                <dd class="col-8 list-detail_card_value py-2 mb-0"
                    [ngClass]="{'border-top':index != 0}">{{i.expectationDescription}}</dd>
            </dl>
        </div>

        <!--        Definição do comitê-->
        <div class="list-detail_card definition" *ngIf="!isNullOrUndefined(idea.conclusion)">

            <p class="list-detail_card_title d-flex align-items-center">
                <span class="flex-grow-1">Definição do comitê</span>
                <button (click)="onEditDefinition()" [disabled]="!definitionCommitte" class="btn btn-primary p-2"
                        *ngIf="!idea?.blocked">
                    <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/edit.svg"></svg-icon>
                </button>
            </p>
            <div class="card bg-primary px-5 py-4">
                <div class="d-flex flex-column flex-wrap flex-sm-row align-items-center" style="gap: 50px">
                    <div class="flex-grow-1 d-flex align-items-center "
                         style="gap: 10px">
                        <div class="prize_div">
                            <img class="lottiebg" src="assets/images/lottiebg.png" alt="">
                            <ng-lottie class="lottie" width="140px" [options]="accessAnimation"></ng-lottie>
                            <div class="text-center">
                                <p class="definition-subtitle">Pontuação total</p>
                                <p class="definition-award award-bold">{{idea.conclusion.points}}<span> Pontos</span></p>

                                <p class="definition-subtitle">Valor do Prêmio</p>
                                <p class="definition-award award-bold">{{idea.conclusion.prize | currency}}</p>
                            </div>
                        </div>

                    </div>
                    <div class="flex-grow-1">
                        <h4 class="definition-title">Premiados</h4>
                        <div class="d-flex mb-2 flex-column" style="gap: 10px">
                            <!--<img class="definition-img" alt="img"
                                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoHYtXTchhspak0O8PNPKAPD9Cf08U6284ng&usqp=CAU">-->
                            <div class="d-flex flex-grow-1 border-bottom pr-2 pb-2">
                                <span class="definition-name">{{idea.authorName}}</span>
                                <span class="definition-award"><!--<span>R$</span>-->{{(idea.conclusion.prize / (idea?.authors.length + 1)) | currency}}</span>
                            </div>
                            <div class="d-flex flex-grow-1 border-bottom pr-2 pb-2" *ngFor="let a of idea?.authors">
                                <span class="definition-name">{{a.name}}</span>
                                <span class="definition-award"><!--<span>R$</span>-->{{(idea.conclusion.prize / (idea?.authors.length + 1)) | currency}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table secondary mt-4">
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col" *ngFor="let i of enumVote">{{getTranslate(i)}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">{{'productivityIncrease' | translate}}</th>
                        <td *ngFor="let i of enumVote">
                            <div class="check-definition"
                                 [ngClass]="{'background':idea.conclusion.productivityVote == i}">&nbsp;
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Sustentabilidade</th>
                        <td *ngFor="let i of enumVote">
                            <div class="check-definition"
                                 [ngClass]="{'background':idea.conclusion.sustainabilityVote == i}">&nbsp;
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Retorno financeiro</th>
                        <td *ngFor="let i of enumVote">
                            <div class="check-definition"
                                 [ngClass]="{'background':idea.conclusion.financialVote == i}">&nbsp;
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>


    </div>
    <aside class="list-detail_col-secondary" *ngIf="!idea?.blocked">
        <ng-container *ngIf="!isNullOrUndefined(idea?.status)&&idea?.status == 'received'">
            <h4 class="list-detail_aside_title"> {{'defineEevaluated' | translate}} </h4>
            <p class="list-detail_aside_subtitle">
                {{'selectCommittee' | translate}}
            </p>
            <!--            <div class="input-material" style="margin-top: 3em">-->
            <!--                <input id="code" class="form-control" type="text"-->
            <!--                       [(ngModel)]="acceptIdeaInput.short"-->
            <!--                       (keyup.enter)="createTag($event.target.value)"-->
            <!--                       required/>-->
            <!--                <label for="code">Código</label>-->
            <!--            </div>-->
            <div class="input-material" *ngIf="whichCommitee">
                <select [(ngModel)]="committeeId" id="committee" class="form-control" name="type">
                    <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option *ngFor="let i of committees" [value]="i.id">{{i.name}}</option>
                </select>
                <label for="committee" class="mb-0 mr-3">Qual Comitê</label>
            </div>
            <div style="min-height: 14.5em;margin-top: 3em">
                <p class="list-detail_aside_title">Tags</p>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="input-material" style="margin-bottom: 0.5em">
                        <input id="startTag" class="form-control" type="text" #targetTags
                               [(ngModel)]="modelTag"
                               [disabled]="!tags"
                               (keyup.enter)="createTag($event.target.value)"
                               required/>
                        <label for="startTag">Nome da tag</label>
                    </div>
                    <a href="javascript:void('');" class="btn btn-primary" (click)="createTag(targetTags.value)">+</a>
                </div>
                <div class="d-flex flex-wrap" style="gap: 5px">
                    <div class="list-detail_tag" *ngFor="let i of acceptIdeaInput.tags">
                        {{i}}
                        <svg-icon class="is-cursor" (click)="actionRemoveTag(i)" src="assets/svg/close.svg"></svg-icon>
                    </div>
                </div>
            </div>
            <div class="list-detail_divider">&nbsp;</div>
            <button [disabled]="!goCommitte" (click)="onAcceptIdea()"
                    class="list-detail_aside_btn btn btn-block btn-warning">
                {{'sendCommitte' | translate}}
            </button>
            <button [disabled]="!reproveCommitte" (click)="onReprove(idea.id)"
                    class="list-detail_aside_btn btn btn-block btn-warning">
                {{'denial' | translate}}
            </button>
            <!--            <a (click)="onAcceptIdea()" class="list-detail_aside_btn btn btn-block btn-warning">Enviar para Comitê</a>-->
            <!--            <a (click)="onReprove(idea.id)" class="list-detail_aside_btn btn btn-block btn-outline-danger">Reprovar</a>-->
        </ng-container>
        <ng-container
                *ngIf="!isNullOrUndefined(idea?.status)&&idea?.status != 'received' && idea?.status != 'analysis'">
            <p class="committee-lbl mb-3">Comitê responsável</p>
            <h3 class="committee-title">{{idea?.committee?.name}}</h3>
            <ng-container *ngIf="!isNullOrUndefined(idea.committee)">
                <p class="committee-lbl mb-2">Participantes</p>
                <div class="d-flex flex-wrap justify-content-between mb-4" style="gap: 5px">
                    <div class="d-flex flex-column align-items-center" style="gap:5px"
                         *ngFor="let i of idea.committee?.participants">
                        <img  class="committee-img"
                              src="{{i?.photo}}" onerror="this.onerror=null;
                                this.src='https://www.pontoxtecidos.com.br/static/567/sku/164485009798408.jpg';"
                              alt="img"/>
<!--                        <img class="committee-img" alt="img"-->
<!--                             src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoHYtXTchhspak0O8PNPKAPD9Cf08U6284ng&usqp=CAU">-->
                        <span class="committee-name">{{i.name}}</span>
                    </div>
                </div>
                <div class="list-detail_divider">&nbsp;</div>
            </ng-container>
            <ng-container *ngIf="!isNullOrUndefined(idea.tags)">
                <p class="list-detail_aside_title mt-3">Tags</p>
                <div class="d-flex flex-wrap mb-3" style="gap: 5px">
                    <div class="list-detail_tag px-2" *ngFor="let i of idea.tags">
                        {{i.tag}}
                    </div>
                </div>
                <div class="list-detail_divider">&nbsp;</div>
            </ng-container>
            <div class="d-flex mt-3">
                <p class="list-detail_aside_title align-items-center flex-grow-1" style="gap: 10px">
                    <svg-icon src="assets/svg/kamban.svg"></svg-icon>
                    {{'actions' | translate}}
                </p>
                <p class="list-detail_aside_title align-items-end">
                    {{idea.endedAction}}<span>/{{idea.action ? idea.action?.length : 0}}</span></p>
            </div>
            <ng-container *ngIf="!isNullOrUndefined(idea.action)">
                <div class="d-flex border-bottom pt-2 pb-1" *ngFor="let s of actionStatus">
                    <div class="flex-grow-1">{{getTranslate(s)}}</div>
                    <div>{{getActionByType(s).length}}</div>
                </div>
            </ng-container>

            <a [routerLink]="'/kanban/'+this.paramId"
               class="list-detail_aside_btn btn btn-block btn-outline-primary mt-3">
                {{'goAction' | translate}}
            </a>
            <div class="list-detail_divider" style="margin: 19px 0 1.5em">&nbsp;</div>
            <button (click)="onAnalysis(idea.id)" *ngIf="idea.status == 'analysis'" [disabled]="!aproveonAnalysis"
                    class="list-detail_aside_btn btn btn-block btn-warning">
                {{'novocadastro2.aprovar' | translate}}
            </button>
            <button (click)="onApproved()" *ngIf="idea.status == 'approved'" [disabled]="!finalizeAction"
                    class="list-detail_aside_btn btn btn-block btn-warning">
                {{'finalizeCommittee' | translate}}
            </button>
            <a (click)="onAward()" *ngIf="idea.status == 'approved_award'"
               class="list-detail_aside_btn btn btn-block btn-primary">
                {{'finishAwards' | translate}}</a>
            <button (click)="onReprove(idea.id)"
                    [disabled]="!finishAwards"
                    *ngIf="idea.status == 'received'|| idea.status == 'analysis' || idea.status == 'denial'"
                    class="list-detail_aside_btn btn btn-block btn-outline-danger"> {{idea.status == 'analysis' ? 'Reprovado' : 'Reprovar'}}</button>
        </ng-container>
        <ng-container *ngIf="!isNullOrUndefined(idea?.status)&& idea?.status == 'analysis'">
            <p class="committee-lbl mb-2">Comitê responsável</p>
            <select [disabled]="!(user.role === 'local-manager' || user.role === 'corporate-manager' || user.role === 'admin')"
                    [(ngModel)]="this.committeeUpdate" class="form-control" name="type"
                    (change)="UpdateCommittee()">
                <option *ngFor="let i of committees" [value]="i.id">{{i.name}}</option>
            </select>
            <ng-container *ngIf="!isNullOrUndefined(idea.committee)">
                <p class="committee-lbl mb-2">Participantes</p>
                <div class="d-flex flex-wrap justify-content-between mb-4" style="gap: 5px">
                    <div class="d-flex flex-column align-items-center" style="gap:5px"
                         *ngFor="let i of idea.committee?.participants">
                        <img class="committee-img" alt="img"
                        src="{{i?.photo}}" onerror="this.onerror=null;
                        this.src='https://www.pontoxtecidos.com.br/static/567/sku/164485009798408.jpg';"
                        alt="img"/>
<!--                        <img class="committee-img" alt="img"-->
<!--                             src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoHYtXTchhspak0O8PNPKAPD9Cf08U6284ng&usqp=CAU">-->
                        <span class="committee-name">{{i.name}}</span>
                    </div>
                </div>
                <div class="list-detail_divider">&nbsp;</div>
            </ng-container>
            <ng-container *ngIf="!isNullOrUndefined(idea.tags)">
                <p class="list-detail_aside_title mt-3">Tags</p>
                <div class="d-flex flex-wrap mb-3" style="gap: 5px">
                    <div class="list-detail_tag px-2" *ngFor="let i of idea.tags">
                        {{i.tag}}
                    </div>
                </div>
                <div class="list-detail_divider">&nbsp;</div>
            </ng-container>
            <div class="d-flex mt-3">
                <p class="list-detail_aside_title align-items-center flex-grow-1" style="gap: 10px">
                    <svg-icon src="assets/svg/kamban.svg"></svg-icon>
                    {{'actions' | translate}}
                </p>
                <p class="list-detail_aside_title align-items-end">
                    {{idea.endedAction}}<span>/{{idea.action ? idea.action?.length : 0}}</span></p>
            </div>
            <ng-container *ngIf="!isNullOrUndefined(idea.action)">
                <div class="d-flex border-bottom pt-2 pb-1" *ngFor="let s of actionStatus">
                    <div class="flex-grow-1">{{getTranslate(s)}}</div>
                    <div>{{getActionByType(s).length}}</div>
                </div>
            </ng-container>

            <a [routerLink]="'/kanban/'+this.paramId"
               class="list-detail_aside_btn btn btn-block btn-outline-primary mt-3">
                {{'goAction' | translate}}
            </a>
            <div class="list-detail_divider" style="margin: 19px 0 1.5em">&nbsp;</div>
            <button (click)="onAnalysis(idea.id)" *ngIf="idea.status == 'analysis'" [disabled]="!aproveonAnalysis"
                    class="list-detail_aside_btn btn btn-block btn-warning">
                {{'novocadastro2.aprovar' | translate}}
            </button>
            <button (click)="onApproved()" *ngIf="idea.status == 'approved'" [disabled]="!finalizeAction"
                    class="list-detail_aside_btn btn btn-block btn-warning">
                {{'finalizeCommittee' | translate}}
            </button>
            <a (click)="onAward()" *ngIf="idea.status == 'approved_award'"
               class="list-detail_aside_btn btn btn-block btn-primary">
                {{'finishAwards' | translate}}</a>
            <button (click)="onReprove(idea.id)"
                    [disabled]="!finishAwards"
                    *ngIf="idea.status == 'received'|| idea.status == 'analysis' || idea.status == 'denial'"
                    class="list-detail_aside_btn btn btn-block btn-outline-danger"> {{idea.status == 'analysis' ? 'Reprovar' : 'Reprovado'}}</button>
        </ng-container>
    </aside>
</div>

<div class="modal-custom modal fade" id="ModalResume" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header align-items-center bg-gray" style="min-height: 107px;">
                <h2 class="modal-title">{{'describeImplementation' | translate}}</h2>
                <a data-dismiss="modal" class="modal_close" aria-label="Close">
                    <svg-icon src="assets/svg/close-modal.svg"></svg-icon>
                </a>
            </div>

            <div class="modal-body p-3 p-md-4">
                <div class="input-material p-3 mb-4">
                    <textarea [(ngModel)]="approveIdea.implementationResume" rows="6" id="unity"
                              class="form-control" name="type">
                    </textarea>
                    <label for="unity" class="mb-0 mr-3">{{'description' | translate}}</label>
                </div>


                <div>
                    <div class="input-material p-3 mb-4" *ngFor="let i of idea.expectations">
                    <textarea [(ngModel)]="i.expectationDescription" rows="1" id="TexpectationDescription"
                              class="form-control" name="type">
                    </textarea>
                        <label for="TexpectationDescription" class="mb-0 mr-3">{{i.expectation | translate}}</label>
                    </div>
                </div>


                <div class="divider"></div>
                <h3 class="modal-title secondary mb-4">{{'relevantInfo' | translate}}</h3>
                <div class="d-flex flex-wrap" style="column-gap: 30px">
                    <div class="flex-grow-1">
                        <div class="input-material">
                            <input [(ngModel)]="approveIdea.analysisManValue" id="valueMan" class="form-control"
                                   [readonly]="!validateStatus"
                                   type="number" required/>
                            <label [ngClass]="{'disabled':!validateStatus}" for="valueMan">Valor H/Homem</label>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <div class="input-material">
                            <input [(ngModel)]="approveIdea.analysisMachineValue" id="valueMachine"
                                   [readonly]="!validateStatus"
                                   class="form-control" type="number" required/>
                            <label [ngClass]="{'disabled':!validateStatus}" for="valueMachine">Valor H/Máquina</label>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <div class="input-material">
                            <input [(ngModel)]="approveIdea.analysisFinacialreturn" id="sustainability"
                                   [readonly]="!validateStatus"
                                   currencyMask
                                   class="form-control" type="text" required/>
                            <label [ngClass]="{'disabled':!validateStatus}" for="sustainability">Retorno
                                Financeiro</label>
                        </div>
                    </div>
                    <!--                    <div class="flex-grow-1">-->
                    <!--                        <div class="input-material">-->
                    <!--                            <select [(ngModel)]="approveIdea.analysisSustainability" id="Financial"-->
                    <!--                                    [disabled]="!validateStatus"-->
                    <!--                                    class="form-control" name="type">-->
                    <!--                                <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>-->
                    <!--                                <option *ngFor="let i of enumSustainability" [value]="i">{{getTranslate(i)}}</option>-->
                    <!--                            </select>-->
                    <!--                            <label [ngClass]="{'disabled':!validateStatus}" for="Financial">Sustentabilidade</label>-->
                    <!--                        </div>-->
                    <!--                    </div>-->


                    <div class="flex-grow-1">
                        <div class="input-material flex-grow-1">
                            <ng-multiselect-dropdown
                                    [settings]="dropSettings"
                                    [(ngModel)]="listStatusItems"
                                    [data]="sustainabilities"
                                    [placeholder]="'buscar' | translate"
                                    (ngModelChange)="onSelectDrop($event)">
                            </ng-multiselect-dropdown>
                            <label class="mb-0 mr-3 disabled">Sustentabilidade</label>
                        </div>
                    </div>


                </div>
                <div class="d-flex flex-wrap" style="gap: 30px">
                    <div class="flex-grow-1">
                        <div class="input-material mb-0">
                            <input id="start"
                                   class="form-control picker-premiationDate" type="text"
                                   required/>
                            <label [ngClass]="{'disabled':!validateStatus}" for="start">Data da premiação</label>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="flex-grow-1 text-center pr-4 align-items-start">
                            <p class="modal-description">{{'addInfo' | translate}}</p>
                            <div class="d-flex flex-column mb-5">
                                <div *ngFor="let f of approveIdea.ideafiles; let i = index"
                                     class="d-flex justify-content-between">
                                    <span>{{f.fileName}}</span>
                                    <a href="javascript:void('');" (click)="onRemoveFile(f.id, i)">
                                        <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                    </a>
                                </div>
                                <div *ngFor="let p of this.listFiles; let i = index;"
                                     class="d-flex justify-content-between">
                                    <span>{{p.file.name}}</span>
                                    <a href="javascript:void('');" (click)="onRemoveFileList(i)">
                                        <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <span class="btn btn-gray-fill_ btn-attachment">
                            <label for="doc-1" class="btn btn-block btn-primary  page-content__upload__input">
                                <svg-icon src="assets/svg/attachment.svg"></svg-icon>
                                <span>Anexar</span>
                                <input type="file" id="doc-1" name="doc-1" required
                                       [disabled]="!validateStatus"
                                       onclick="this.value = null" (change)="handlerUpload($event)"/>
                            </label>
                        </span>
                    </div>
                </div>

                <div class="d-flex flex-grow-1" style="gap: 30px;max-width: 394px;margin: 0 auto">
                    <a data-dismiss="modal" aria-label="Close" class="btn flex-grow-1 btn-outline-primary">Cancelar</a>
                    <a (click)="onImplementation()" class="btn flex-grow-1 btn-primary">Salvar</a>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="ModalRefusal" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header align-items-center bg-gray" style="min-height: 107px;">
                <h2 class="modal-title">Justifique o motivo da recusa</h2>
                <a data-dismiss="modal" class="modal_close" aria-label="Close">
                    <svg-icon src="assets/svg/close-modal.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body p-3 p-md-4">
                <div class="input-material p-3 mb-4">
                    <textarea [(ngModel)]="reproveIdeaInput.motive" rows="6" class="form-control" name="type">
                    </textarea>
                    <label for="unity" class="mb-0 mr-3">Descreva aqui</label>
                </div>
                <div class="d-flex flex-grow-1" style="gap: 30px;max-width: 394px;margin: 0 auto">
                    <a data-dismiss="modal" aria-label="Close" class="btn flex-grow-1 btn-outline-primary">Cancelar</a>
                    <a (click)="reproveIdea()" class="btn flex-grow-1 btn-primary">Salvar</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!--<div class="modal-custom modal fade" id="modalTranslate" data-keyboard="false"-->
<!--     data-backdrop="static" role="dialog"-->
<!--     aria-labelledby="modalNoteResumeLabel"-->
<!--     aria-hidden="true">-->
<!--    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">-->
<!--        <div class="modal-content p-0">-->
<!--            <div class="modal-header align-items-center bg-gray" style="min-height: 107px;">-->
<!--                <h2 class="modal-title">Tradução Ideia</h2>-->
<!--                <a data-dismiss="modal" class="modal_close" aria-label="Close">-->
<!--                    <svg-icon src="assets/svg/close-modal.svg"></svg-icon>-->
<!--                </a>-->
<!--            </div>-->

<!--            <div class="modal-body p-3 p-md-4">-->
<!--                <h3 class="modal-title secondary mb-4">Informações relevantes</h3>-->
<!--                <div class="d-flex flex-wrap" style="column-gap: 30px">-->
<!--                    <div class="input-material">-->
<!--                        <select id="status" [(ngModel)]="challengeId" class="form-control" name="type">-->
<!--                            <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>-->
<!--                            <option *ngFor="let u of challengeResult" [value]="u.id">{{u.title}}</option>-->
<!--                        </select>-->
<!--                        <label for="status" class="mb-0 mr-3">Idéia</label>-->
<!--                    </div>-->
<!--                    <div class="flex-grow-1" *ngIf="idea.status === 'denied'">-->
<!--                        <div class="input-material">-->
<!--                            <input id="motive" class="form-control"-->
<!--                                   [(ngModel)]="ideasSons.motive" required/>-->
<!--                            <label for="motive">Motivo</label>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="flex-grow-1">-->
<!--                        <div class="input-material">-->
<!--                            <input id="translateTitle" class="form-control"-->
<!--                                   [(ngModel)]="ideasSons.title" required/>-->
<!--                            <label for="translateTitle">Título</label>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="flex-grow-1">-->
<!--                        <div class="input-material">-->
<!--                            <input id="descricao"-->
<!--                                   class="form-control" type="text"-->
<!--                                   [(ngModel)]="ideasSons.description" required/>-->
<!--                            <label for="descricao">Descrição</label>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="flex-grow-1">-->
<!--                        <div class="input-material">-->
<!--                            <input id="descricaoProdutiva"-->
<!--                                   class="form-control" type="text"-->
<!--                                   [(ngModel)]="ideasSons.productiveDescription" required/>-->
<!--                            <label for="descricaoProdutiva">Descrição Produtiva</label>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="flex-grow-1">-->
<!--                        <div class="input-material">-->
<!--                            <input id="descricaoAnalise"-->
<!--                                   class="form-control" type="text"-->
<!--                                   [(ngModel)]=" ideasSons.analysisDescription" required/>-->
<!--                            <label for="descricaoAnalise">Descrição de Analise</label>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div *ngFor=" let i of ideasSons.expectations">-->
<!--                        <div class="input-material">-->
<!--                            <input id="expectationDescription"-->
<!--                                   class="form-control" type="text"-->
<!--                                   [(ngModel)]="i.expectationDescription" required/>-->
<!--                            <label for="expectationDescription">Expectativa: {{i.expectation | translate}}</label>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;Retirando Campos solicitados&ndash;&gt;-->
<!--                </div>-->
<!--                <div class="d-flex flex-grow-1" style="gap: 30px;max-width: 394px;margin: 0 auto">-->
<!--                    <a data-dismiss="modal" aria-label="Close" class="btn flex-grow-1 btn-outline-primary">Cancelar</a>-->
<!--                    <a (click)="ideaTranslate()" class="btn flex-grow-1 btn-primary">Salvar</a>-->
<!--                </div>-->

<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="modal-custom modal fade" id="ModalCommittee" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header align-items-center bg-gray" style="min-height: 107px;">
                <h2 class="modal-title">Conclusão do comitê</h2>
                <a data-dismiss="modal" class="modal_close" aria-label="Close">
                    <svg-icon src="assets/svg/close-modal.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body p-3 p-md-4">
                <h3 class="modal-title secondary mb-4">Votação</h3>
                <div class="d-flex flex-wrap" style="column-gap: 30px">
                    <div class="flex-grow-1">
                        <label for="productivity" class="input-label">{{'productivityIncrease' | translate}}</label>
                        <div class="input-material" *ngIf="validateStatus">
                            <select [(ngModel)]="productivityVote" id="productivity"
                                    class="form-control" name="type"
                                    (change)="calcAward()">
                                <option [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                                <!--                                <option *ngFor="let i of enumVote" [value]="i">{{getTranslate(i)}}</option>-->
                                <option *ngFor="let i of productivityVotes"
                                        [value]="i.id">{{getTranslate(i.id)}}</option>
                                -->

                            </select>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <label for="selectSustainability" class="input-label">Sustentabilidade</label>
                        <div class="input-material" *ngIf="validateStatus">
                            <select [(ngModel)]="sustainabilityVote" id="selectSustainability"
                                    class="form-control" name="type"
                                    (change)="calcAward()">
                                <option [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                                <option *ngFor="let i of sustainabilityVotes"
                                        [value]="i.id">{{getTranslate(i.id)}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <label for="return" class="input-label">Retorno financeiro</label>
                        <div class="input-material" *ngIf="validateStatus">
                            <select [(ngModel)]="financialVote" id="return"
                                    class="form-control" name="type"
                                    (change)="calcAward()">
                                <option [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                                <option *ngFor="let i of financialVotes" [value]="i.id">{{getTranslate(i.id)}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="flex-grow-1 mb-3">
                        <label class="input-label">Valor do Prêmio</label>
                        <p class="input-value">{{ideaConclusion.prize | currency}}</p>
                    </div>
                </div>
                <div class="divider mb-3">&nbsp;</div>
                <div class="input-material p-3 mb-4" *ngIf="financialVote !== 'not attend'">
                    <textarea [(ngModel)]="financialVoteDescription" rows="6" id="DescriptionFinancialVote"
                              class="form-control" name="type">
                    </textarea>
                    <label for="DescriptionFinancialVote" class="mb-0 mr-3">Descrição Retono Financeiro</label>
                </div>

                <div class="input-material p-3 mb-4" *ngIf="sustainabilityVote !== 'not attend'">
                    <textarea [(ngModel)]="sustainabilityVoteDescription" rows="6" id="DescriptionSustainabilityVote"
                              class="form-control" name="type">
                    </textarea>
                    <label for="DescriptionSustainabilityVote" class="mb-0 mr-3">Descrição Sustentabilidade</label>
                </div>

                <div class="input-material p-3 mb-4" *ngIf="productivityVote !== 'not attend'">
                    <textarea [(ngModel)]="productivityVoteDescription" rows="6" id="DescriptionProductivityVote"
                              class="form-control" name="type">
                    </textarea>
                    <label for="DescriptionProductivityVote" class="mb-0 mr-3">Descrição Aumento de
                        Produtividade</label>
                </div>
                <!--   <h3 class="modal-title secondary mb-4">Informações relevantes</h3>
                   <div class="d-flex flex-wrap" style="column-gap: 30px">
                       <div class="flex-grow-1">
                           <div class="input-material">
                               <input [(ngModel)]="ideaConclusion.manValue" id="valueMan" class="form-control"
                                      [readonly]="!validateStatus"
                                      currencyMask
                                      type="text" required/>
                               <label [ngClass]="{'disabled':!validateStatus}" for="valueMan">Valor H/Homem</label>
                           </div>
                       </div>
                       <div class="flex-grow-1">
                           <div class="input-material">
                               <input [(ngModel)]="ideaConclusion.machineValue" id="valueMachine"
                                      [readonly]="!validateStatus"
                                      currencyMask
                                      class="form-control" type="text" required/>
                               <label [ngClass]="{'disabled':!validateStatus}" for="valueMachine">Valor H/Máquina</label>
                           </div>
                       </div>
                       <div class="flex-grow-1">
                           <div class="input-material">
                               <input [(ngModel)]="ideaConclusion.finacialreturn" id="sustainability"
                                      [readonly]="!validateStatus"
                                      currencyMask
                                      class="form-control" type="text" required/>
                               <label [ngClass]="{'disabled':!validateStatus}" for="sustainability">Retorno
                                   Financeiro</label>
                           </div>
                       </div>
                       <div class="flex-grow-1">
                           <div class="input-material">
                               <select [(ngModel)]="ideaConclusion.sustainability" id="Financial"
                                       [disabled]="!validateStatus"
                                       class="form-control" name="type">
                                   <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                                   <option *ngFor="let i of enumSustainability" [value]="i">{{getTranslate(i)}}</option>
                               </select>
                               <label [ngClass]="{'disabled':!validateStatus}" for="Financial">Sustentabilidade</label>
                           </div>
                       </div>
                   </div>
                   <div class="d-flex flex-wrap" style="gap: 30px">
                       <div class="flex-grow-1">
                           <div class="input-material mb-0">
                               <input id="start"
                                      [readonly]="!validateStatus"
                                      [disabled]="!validateStatus"
                                      class="form-control picker-period" type="text"
                                      required/>
                               <label [ngClass]="{'disabled':!validateStatus}" for="start">Data da premiação</label>
                           </div>
                       </div>
                       <div class="d-flex">
                           <div class="flex-grow-1 text-center pr-4 align-items-start">
                               <p class="modal-description">Adicione aqui qualquer informação pertinente a conclusão realiza no comitê</p>
                               <div class="d-flex flex-column mb-5">
                                   <div *ngFor="let p of this.listFiles; let i = index;" class="d-flex justify-content-between">
                                       <span>{{p.file.name}}</span>
                                       <a href="javascript:void('');" (click)="onRemoveFileList(i)"><svg-icon src="assets/svg/trash.svg"></svg-icon></a>
                                   </div>
                               </div>
                           </div>
                           <span class="btn btn-gray-fill_ btn-attachment">
                               <label for="doc-1" class="btn btn-block btn-primary  page-content__upload__input">
                                   <svg-icon src="assets/svg/attachment.svg"></svg-icon>
                                   <span>Anexar</span>
                                   <input type="file" id="doc-1" name="doc-1" required
                                          [disabled]="!validateStatus"
                                          onclick="this.value = null" (change)="handlerUpload($event)"/>
                               </label>
                           </span>
                       </div>
                       <div class="flex-grow-1 text-right">
                           &lt;!&ndash;<div class="page-content__upload small">
                               <div class="d-flex justify-content-center align-items-center">
                                   <svg-icon class="icon svg-icon mt-2 mb-0 mr-3" src="assets/svg/check.svg"
                                             [ngClass]="{'svg-icon-success': !isNullOrUndefined(pathImageObj['primary'].path)}"></svg-icon>
                                   <p>Upload</p>
                               </div>
                               <label for="doc-1" class="btn btn-block btn-primary  page-content__upload__input">
                                   <svg-icon src="assets/svg/attachment.svg"></svg-icon>
                                   <span>Anexar</span>
                                   <input type="file" id="doc-1" name="doc-1" required
                                          onclick="this.value = null" (change)="handlerUpload($event, 'primary')" />
                               </label>
                               <div *ngIf="!isNullOrUndefined(pathImageObj['primary'].path)">
                                   {{pathImageObj['primary'].name}}
                               </div>
                           </div>&ndash;&gt;
                       </div>
                   </div>-->
                <div class="d-flex flex-grow-1" *ngIf="validateStatus"
                     style="gap: 30px;max-width: 394px;margin: 0 auto">
                    <a data-dismiss="modal" aria-label="Close" class="btn flex-grow-1 btn-outline-primary">Cancelar</a>
                    <button (click)="onIdeaConclusion()" [disabled]="!finishAwards" class="btn flex-grow-1 btn-primary">
                        Salvar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="ModalDenial" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header align-items-center bg-gray" style="min-height: 107px;">
                <h2 class="modal-title">Mudar para reprova</h2>
                <a data-dismiss="modal" class="modal_close" aria-label="Close">
                    <svg-icon src="assets/svg/close-modal.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body p-3 p-md-4">
                <div class="input-material p-3 mb-4">
                    <p>{{'turnDisapproved' | translate}}</p>
                </div>
                <div class="d-flex flex-grow-1" style="gap: 30px;max-width: 394px;margin: 0 auto">
                    <a data-dismiss="modal" aria-label="Close" class="btn flex-grow-1 btn-outline-primary">Cancelar</a>
                    <a (click)="changeDenialStatus()" class="btn flex-grow-1 btn-primary">Confirmar</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalTranslateESPT" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header align-items-center bg-gray" style="min-height: 107px;">
                <h2 class="modal-title">Publicação</h2>
                <a data-dismiss="modal" class="modal_close" aria-label="Close">
                    <svg-icon src="assets/svg/close-modal.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body p-3 p-md-4">
                <p>
                    {{'defineInfo' | translate}}
                </p>
                <div class="row justify-content-center mb-2 text-center" style="row-gap: 10px">
                    <a href="javascript:void(0)" class="col-6 col-sm-3" (click)="clickStep(1)">
                        <span class="modal_tab" [ngClass]="{'select': step === 1}">
                            Português
                        </span>
                    </a>
                    <a href="javascript:void(0)" class="col-6 col-sm-3" (click)="clickStep(2)">
                        <span class="modal_tab" [ngClass]="{'select': step === 2}">
                            Espanhol
                        </span>
                    </a>
                </div>
                <div class="row justify-content-center mb-4 text-center" style="row-gap: 10px">
                    <div class="col-sm-6" style="z-index: 2">
                        <div class="modal_tab_bar position-relative">
                            <div class="select" [ngClass]="step === 1?'left':'right'">&nbsp;</div>
                        </div>
                    </div>
                    <div class="col-12" style="margin-top: -10px;z-index: 1">
                        <div class="modal_tab_bar position-relative" style="background-color: #707070">&nbsp;</div>
                    </div>
                </div>

                <ng-container *ngIf="step == 1">
                    <div class="input-material">
                        <select id="statusPT" [(ngModel)]="challengeIdBR" class="form-control" name="type">
                            <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                            <option *ngFor="let u of challengeResult" [value]="u.id">{{u.title}}</option>
                        </select>
                        <label for="statusPT" class="mb-0 mr-3">Idéia</label>
                    </div>
                    <div class="input-material mb-4">
                                <textarea [(ngModel)]="modelSonsBR.title" rows="1" id="titlePT" class="form-control"
                                          name="type">
                                </textarea>
                        <label for="titlePT" class="mb-0 mr-3">Titulo</label>
                    </div>
                    <div class="input-material mb-5">
                                <textarea [(ngModel)]="modelSonsBR.description" class="form-control" name="message"
                                          placeholder="{{'ideaDescription' | translate}}" id="descriptionPT" rows="5">
                                </textarea>
                        <label for="descriptionPT">{{'description' | translate}}</label>
                    </div>
                    <div class="input-material mb-4">
                                <textarea [(ngModel)]="modelSonsBR.productiveDescription" rows="1"
                                          id="productiveDescriptionPT" class="form-control" name="type">
                                </textarea>
                        <label for="productiveDescriptionPT" class="mb-0 mr-3">Descrição Produtiva</label>
                    </div>
                    <div class="input-material mb-4">
                                <textarea [(ngModel)]="modelSonsBR.analysisDescription" rows="1"
                                          id="analysisDescriptionPT" class="form-control" name="type">
                                </textarea>
                        <label for="analysisDescriptionPT" class="mb-0 mr-3">Descrição de Analise</label>
                    </div>
                    <div class="mb-2">
                        <p class="list-detail_card_lbl text-capitalize">Expectativa de Ganho</p>
                        <ng-container *ngFor="let x of modelSonsBR.expectations; let i = index;">
                            <label class="checkbox-container">
                                <input type="checkbox" name="expectation"
                                       [value]="x.expectation"
                                       [checked]="x.isSelected"
                                       (change)="onChangeExpectation(x)"/>
                                <span class="checkmark">
                                    <span class="align-self-center">{{x.expectation | translate}}</span>
                                </span>
                            </label>
                            <div class="input-material mt-4 mb-5" *ngIf="x.isSelected">
                                 <textarea class="form-control" name="message{{i}}"
                                           id="descriptionF_{{i}}" rows="5"
                                           [(ngModel)]="modelSonsBR.expectations[i].expectationDescription">
                                 </textarea>
                                <label for="descriptionF_{{i}}">{{'description' | translate}} {{x.expectation | translate}}</label>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngIf="step == 2">
                    <div class="input-material">
                        <select id="statusES" [(ngModel)]="challengeIdES" class="form-control" name="type">
                            <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                            <option *ngFor="let u of challengeResult" [value]="u.id">{{u.title}}</option>
                        </select>
                        <label for="statusES" class="mb-0 mr-3">Idéia</label>
                    </div>
                    <div class="input-material mb-4">
                                <textarea [(ngModel)]="modelSonsES.title" rows="1" id="titleES" class="form-control"
                                          name="type">
                                </textarea>
                        <label for="titleES" class="mb-0 mr-3">Titulo</label>
                    </div>
                    <div class="input-material mb-5">
                                <textarea [(ngModel)]="modelSonsES.description" class="form-control" name="message"
                                          placeholder="{{'ideaDescription' | translate}}" id="descriptionES" rows="5">
                                </textarea>
                        <label for="descriptionES">{{'description' | translate}}</label>
                    </div>
                    <div class="input-material mb-4">
                                <textarea [(ngModel)]="modelSonsES.productiveDescription" rows="1"
                                          id="productiveDescriptionES" class="form-control" name="type">
                                </textarea>
                        <label for="productiveDescriptionES" class="mb-0 mr-3">Descrição Produtiva</label>
                    </div>
                    <div class="input-material mb-4">
                                <textarea [(ngModel)]="modelSonsES.analysisDescription" rows="1"
                                          id="analysisDescriptionES" class="form-control" name="type">
                                </textarea>
                        <label for="analysisDescriptionES" class="mb-0 mr-3">Descrição de Analise</label>
                    </div>
                    <div class="mb-2">
                        <p class="list-detail_card_lbl text-capitalize">Expectativa de Ganho</p>
                        <ng-container *ngFor="let e of modelSonsES.expectations; let i = index;">
                            <label class="checkbox-container">
                                <input type="checkbox" name="expectation"
                                       [value]="e.expectation"
                                       [checked]="e.isSelected"
                                       (change)="onChangeExpectation(e)"/>
                                <span class="checkmark">
                                            <span class="align-self-center">{{e.expectation | translate}}</span>
                                         </span>
                            </label>
                            <div class="input-material mt-4 mb-5" *ngIf="e.isSelected">
                                         <textarea class="form-control" name="message{{i}}"
                                                   id="descriptionFES_{{i}}" rows="5"
                                                   [(ngModel)]="modelSonsES.expectations[i].expectationDescription">
                                         </textarea>
                                <label for="descriptionFES_{{i}}">{{'description' | translate}} {{e.expectation | translate}}</label>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <div class="row">
                    <div class="d-flex flex-grow-1" style="gap: 30px;max-width: 394px;margin: 0 auto">
                        <a data-dismiss="modal" aria-label="Close"
                           class="btn flex-grow-1 btn-outline-primary">Cancelar</a>
                        <button (click)="ideaTranslate()" [disabled]="!concludeIdea"
                                class="btn flex-grow-1 btn-primary">Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


