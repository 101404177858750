<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page_header">
        <h1 class="page_title">{{'management' | translate}} | <span>{{'users' | translate}}</span></h1>
        <div class="page_action" style="gap: 20px">
            <a href="javascript:void(0)" *ngIf="buttonExcel"
               (click)="actionExcel()"
               class="page_action_excel mr-2 btn btn-gray-fill"
               type="button">
                <svg-icon src="assets/svg/download.svg"></svg-icon>
                <span class="font-weight-normal">Exportar Excel</span>
            </a>
            <a href="javascript:void (0)" *ngIf="addUser"
               (click)="newUser()"
               class="page_action_excel btn btn-warning"
               type="button">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                <span class="ml-2 font-weight-normal">{{'newUser' | translate}} </span>
            </a>

        </div>
    </div>
    <div class="page_padding">
        <div class="page_filter mb-4">
            <div class="input-material" style="max-width: 394px">
                <input id="search" class="form-control" type="text" [(ngModel)]="searchUserInput.keyword" required/>
                <label for="search">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    Busca
                </label>
            </div>
            <div class="input-material mr-auto" style="max-width: 182px">
                <select id="committee" class="form-control" name="type" [(ngModel)]="searchUserInput.committee">
                    <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option *ngFor="let u of committeeResult" [value]="u.id">{{u.name}}</option>
                </select>
                <label for="committee" class="mb-0 mr-3">{{'committee' | translate}}</label>
            </div>
            <div class="input-material">
                <select id="unityFilter" class="form-control" name="type" [(ngModel)]="searchUserInput.unity">
                    <option [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option *ngFor="let u of listUnity" [value]="u.id">{{u.name}}</option>
                </select>
                <label for="unityFilter" class="mb-0 mr-3">{{'unity' | translate}}</label>
            </div>
            <div class="page_filter_action flex-grow-1" style="max-width: 109px;">
                <a href="javascript:void(0)"
                   (click)="onSearch()"
                   class="btn btn-block btn-lg btn-primary btn-search"
                   style="font-weight: normal;"
                   type="button">Buscar</a>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table_regular">
                <thead>
                <tr>
                    <th scope="col" class="w-10"></th>
                    <th scope="col" class="w-15">Perfil</th>
                    <th scope="col">{{'user' | translate}}</th>
                    <th scope="col">Email</th>
                    <th scope="col">{{'unity' | translate}}</th>
                    <th scope="col">Comite Gestão</th>
                    <th scope="col">Comite Participante</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(userResult.users)">
                    <td colspan="7" class="text-center">Nenhum usuário encontrado.</td>
                </tr>
                <tr *ngFor="let list of userResult.users | paginate: {
                                itemsPerPage: userResult.pageSize,
                                currentPage: userResult.currentPage,
                                totalItems: userResult.totalCount }">
                    <td>
                        <div class="d-flex justify-content-center" style="gap: 15px">
                            <svg-icon class="is-cursor" *ngIf="editUser" (click)="UpdateUser(list)"
                                      src="assets/svg/edit.svg"></svg-icon>
                            <svg-icon class="is-cursor" *ngIf="deleteUser" (click)="actionDelete(list.id)"
                                      src="assets/svg/close-circle.svg"></svg-icon>
                        </div>
                    </td>
                    <td>{{list?.role | translate}}</td>
                    <td>
                        <!--                        <img class="user-photo border-warning"-->
                        <!--                             src="{{!list?.photo ? 'https://medicina.ucpel.edu.br/wp-content/uploads/2020/07/apacucpel_ucpel_image_319-1024x960.jpeg' : list?.photo}}"-->
                        <!--                             alt="img">-->
                        <!--                        {{list?.name}}-->
                        <img class="user-photo border-warning"
                             src="{{list?.photo}}" onerror="this.onerror=null;
                                this.src='https://www.pontoxtecidos.com.br/static/567/sku/164485009798408.jpg';"
                             alt="img"/>
                        {{list?.name}}

                    </td>
                    <td>{{list?.email}}</td>
                    <td>{{list?.unity?.name}}</td>
                    <td>
                        <div class="d-flex flex-wrap">
                            <span *ngFor="let e of list?.committeeManager; let i = index;">{{e?.name}} <strong
                                    class="d-inline-block px-2"
                                    *ngIf="i < (list?.committeeManager.length - 1)"> | </strong></span>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex flex-wrap">
                            <span *ngFor="let e of list?.committeeParticipants; let i = index;">{{e?.name}} <strong
                                    class="d-inline-block px-2"
                                    *ngIf="i < (list?.committeeParticipants.length - 1)"> | </strong></span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <pagination-controls
                [previousLabel]="labels.previousLabel"
                [nextLabel]="labels.nextLabel"
                [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                [screenReaderPageLabel]="labels.screenReaderPageLabel"
                [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                (pageChange)="onSearch($event)"></pagination-controls>
    </div>
</div>

<div class="modal-custom modal fade" id="modalGroup" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 560px" role="document">
        <div class="modal-content modal-user">
            <div class="modal-header">
                <h2 class="modal-user_title" *ngIf="isNullOrUndefined(modelUser.id)">{{'newUser' | translate}}</h2>
                <h2 class="modal-user_title" *ngIf="!isNullOrUndefined(modelUser.id)">{{'editUser' | translate}}</h2>
                <a data-dismiss="modal" class="modal_close" aria-label="Close">
                    <svg-icon src="assets/svg/close-modal.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-user_body">
                <div class="modal-user_card">
                    <div class="modal-user_img-position">
                        <svg-icon src="assets/svg/new-user.svg"></svg-icon>
                        <!--<img src="https://medicina.ucpel.edu.br/wp-content/uploads/2020/07/apacucpel_ucpel_image_319-1024x960.jpeg"
                             class="modal-user_photo"/>-->
                    </div>
                    <fieldset class="custom__form" autocomplete="off">
                        <div class="row">
                            <div class="col-xs-12 col-md-12">
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="input-material custom__input modal_text_input">
                                            <input id="name" class="form-control" name="name" type="text"
                                                   [(ngModel)]="modelUser.name" required/>
                                            <label for="name">Nome</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-12">
                                        <div class="input-material custom__input modal_text_input">
                                            <input id="ra" class="form-control" name="e-mail" type="text" required
                                                   [(ngModel)]="modelUser.email"/>
                                            <label for="ra">E-mail</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-12">
                                        <div class="input-material custom__input modal_text_input">
                                            <select id="profileUpdate" class="form-control" name="type"
                                                    [(ngModel)]="modelUser.role"
                                                    (ngModelChange)="setCommitteeInput($event)">
                                                <option [ngValue]="undefined">Selecione</option>
                                                <!--                                                <option [ngValue]="'admin'">Admin</option>-->
                                                <!--                                                <option [ngValue]="'user'">User</option>-->
                                                <!--                                                <option [ngValue]="'userOffice-web'">Usuário Office365</option>-->
                                                <option [ngValue]="'corporate-manager'">Administrador Corporativo
                                                </option>
                                                <option [ngValue]="'local-manager'">Gestor Local</option>
                                                <option [ngValue]="'committee-member'">Membro do comitê</option>
                                            </select>
                                            <label for="profileUpdate" class="mb-0 mr-3">Perfil</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-12" *ngIf="isVisible">
                                        <div class="input-material custom__input modal_text_input">
                                            <ng-multiselect-dropdown
                                                    id="committee_drop"
                                                    [placeholder]="'Selecione o(s) comitê(s)'"
                                                    [(ngModel)]="committee"
                                                    [settings]="dropdownCommitteeSettings"
                                                    [data]="committeeResult"
                                            >
                                            </ng-multiselect-dropdown>
                                            <label for="committee_drop"
                                                   class="disabled">{{'committee' | translate}}</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-12" *ngIf="unityCommittee">
                                        <div class="input-material custom__input modal_text_input">
                                            <select name="country" id="country" (change)="getUnitiesFilter()"
                                                    [(ngModel)]="filterCountryId"
                                                    class="form-control" required>
                                                <option [ngValue]="undefined">Selecione</option>
                                                <option *ngFor="let c of filterCountries"
                                                        value="{{c.id}}">{{c.name}}</option>
                                            </select>
                                            <label for="country">País</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-12" *ngIf="unityCommittee">
                                        <div class="input-material custom__input modal_text_input">
                                            <select name="unity" id="unity" [(ngModel)]="filterUnityId"
                                                    class="form-control" required>
                                                <option [ngValue]="undefined">Selecione um país</option>
                                                <option *ngFor="let c of filterUnities"
                                                        value="{{c.id}}">{{c.name}}</option>
                                            </select>
                                            <label for="unity">{{'unity' | translate}}</label>
                                        </div>
                                    </div>

                                    <!-- <div class="col-12 col-md-12">-->
                                    <!--     <div class="input-material custom__input modal_text_input">-->
                                    <!--         <select id="perfil" class="form-control"-->
                                    <!--                 [(ngModel)]="modelUser.country">-->
                                    <!--             <option value="undefined" selected disabled>Selecione</option>-->
                                    <!--             <option *ngFor="let e of modelListCountry"-->
                                    <!--                     [value]="e.name">{{e.name}}</option>-->
                                    <!--         </select>-->
                                    <!--         <label for="perfil" class="mb-0 mr-3">Perfil</label>-->
                                    <!--     </div>-->
                                    <!-- </div>-->

                                    <!--<div class="col-12">
                                        <div class="modal-user_status">
                                            <span>Status</span>
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" id="customSwitch1">
                                                <label class="custom-control-label" for="customSwitch1">&nbsp;</label>

                                            </div>
                                        </div>
                                    </div>-->
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>

            <a class="btn btn-block btn-lg btn-primary text-warning" href="javascript:void('save');"
               (click)="actionSave()">SALVAR</a>

        </div>
    </div>
</div>

