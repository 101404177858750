import {Component, OnDestroy, OnInit, AfterViewInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {
    Country, CreateUnityCountryInput, CreateUnityGQL, CreateUnityInput,
    GetCountriesGQL,
    GetCountriesQuery,
    GetUnitiesGQL,
    GetUnitiesQuery, RemoveUnityGQL,
    SearchCommitteeGQL, SearchUnityGQL, SearchUnityInput, SearchUnityQuery,
    Unity, UpdateUnityGQL, UpdateUnityInput, UpdateUserInput, User
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {ExcelService} from "../../service/excel.service";
import {UserService} from "../../service/user.service";

declare var $: any;

@Component({
    selector: 'app-unity',
    templateUrl: './unity.component.html',
    styleUrls: ['./unity.component.scss']
})
export class UnityComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    user: User = new User();

    getUnitiesQuery: QueryRef<GetUnitiesQuery>;
    getCountriesQuery: QueryRef<GetCountriesQuery>;
    modelUnities: Unity = new Unity();
    unitiesResult: Unity[] = [];
    countriesResult: Country[] = [];
    createUnityCountryInput: CreateUnityCountryInput = new CreateUnityCountryInput();
    searchUnityInput: SearchUnityInput = new SearchUnityInput();
    searchUnityQuery: QueryRef<SearchUnityQuery>;

    buttonExcel: boolean;
    editUnity: boolean;
    addUnity: boolean;
    deleteUnity: boolean;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public userService: UserService,
                public translate: TranslateService,
                public searchUnityGQL: SearchUnityGQL,
                public getCountriesGQl: GetCountriesGQL,
                public createUnityGQL: CreateUnityGQL,
                public updateUnityGQL: UpdateUnityGQL,
                public removeUnityGQL: RemoveUnityGQL,
                public excelService: ExcelService,
    ) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.userProfile();
            }, error: err => super.onError(err)
        });
    }

    ngAfterViewInit(): void {
        this.actionCountries();
        this.actionList();
    }

    ngOnDestroy(): void {
        const el = $('#modalUnity');
        if (el[0]) {
            el.remove();
        }
    }

    actionList() {
        this.loading.show();
        this.searchUnityQuery = this.searchUnityGQL.watch({input: this.searchUnityInput});
        this.searchUnityQuery.valueChanges.subscribe(({data}) => {
            this.unitiesResult = data.searchUnity as Unity[];
            this.loading.hide();
        })
    }

    actionCountries() {
        this.getCountriesQuery = this.getCountriesGQl.watch();
        this.getCountriesQuery.valueChanges.subscribe(({data}) => {
            this.countriesResult = data.getCountries as Country[];
        })
    }

    onSave() {
        const arrValidateFields = [
            {value: this.modelUnities.name, text: 'Nome<br>'},
            {value: this.createUnityCountryInput.id, text: 'País<br>'},
            // {value: this.modelUser.role, text: 'Perfil<br>'},
        ];
        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br> ${stringError}`);
            return;
        }
        if (super.isNullOrUndefined(this.modelUnities.id)) {
            this.actionCreatedUnity();
        } else {
            this.actionUpdateUnity();
        }
    }

    onDelete(id: string) {
        super.confirmMessage('Atenção', 'Deseja realmente excluir essa unidade?', () => {
            this.loading.show();
            this.removeUnityGQL.mutate({id: id}).subscribe(
                (data) => {
                    this.searchUnityQuery.refetch({input: this.searchUnityInput});
                    this.loading.hide();
                    this.showMessage('Sucesso!', (this.translate.instant('usuarioexcluido')), 'success');
                }, err => super.onError(err));
        });


    }

    newUnity() {
        this.modelUnities = new Unity();
        this.createUnityCountryInput = new CreateUnityCountryInput();
        super.showModal('modalUnity');
    }

    updateUnity(unity: Unity) {
        this.modelUnities = Object.assign({}, unity);
        this.createUnityCountryInput.id = Object.assign({}, unity).country.id;
        super.showModal('modalUnity');
    }

    actionCreatedUnity() {
        this.loading.show();
        const input: CreateUnityInput = {
            name: this.modelUnities.name,
            country: this.createUnityCountryInput,
        };
        this.createUnityGQL.mutate({input: input}).subscribe(
            (data) => {
                $('#modalUnity').modal('hide');
                this.searchUnityQuery.refetch({input: this.searchUnityInput}).finally(() => this.loading.hide());
                this.loading.hide();
            }, err => super.onError(err)
        );
    }

    actionUpdateUnity() {
        this.loading.show();
        const input: UpdateUnityInput = {
            id: this.modelUnities.id,
            name: this.modelUnities.name,
            country: this.createUnityCountryInput,
        };
        this.updateUnityGQL.mutate({input: input}).subscribe(
            (data) => {
                $('#modalUnity').modal('hide');
                this.searchUnityQuery.refetch({input: this.searchUnityInput}).finally(() => this.loading.hide());
            }, err => super.onError(err)
        );
    }

    onSearch() {
        this.loading.show();
        this.searchUnityQuery.refetch({input: this.searchUnityInput}).finally(() => this.loading.hide());
    }

    actionExcel() {
        this.loading.show();
        this.excelService.getExcelUnities(this.searchUnityInput).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }


    userProfile() {
        switch (this.user.role) {
            case 'admin':
                this.buttonExcel = true;
                this.addUnity = true;
                this.editUnity = true;
                this.deleteUnity = true;
                break;
            case 'user':
                this.buttonExcel = true;
                this.addUnity = true;
                this.editUnity = true;
                this.deleteUnity = true;
                break;
            case 'corporate-manager':
                this.buttonExcel = true;
                this.addUnity = true;
                this.editUnity = true;
                this.deleteUnity = true;
                break;
            case 'local-manager':
                this.buttonExcel = false;
                this.addUnity = false;
                this.editUnity = false;
                this.deleteUnity = false;
                break;
            case 'committee-member':
                this.buttonExcel = false;
                this.addUnity = false;
                this.editUnity = false;
                this.deleteUnity = false;
                break;

        }
    }
}
