<div class="page">
    <div class="page_header">
        <h1 class="page_title">{{'challenges' | translate}}</h1>
        <div class="page_action" style="gap: 20px">
            <a href="javascript:void(0)" *ngIf="newChallenge" (click)="actionChallenge()"
               class="btn btn-warning d-flex align-items-center" style="gap: 10px"
               type="button">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                <span class="font-weight-normal">{{'newChallenge' | translate}}</span>
            </a>
        </div>
    </div>

    <div class="page_padding">
        <div class="page_filter mb-4">
            <div class="input-material">
                <input id="search" class="form-control" type="text" required
                       [(ngModel)]="filter.keyword"/>
                <label for="search">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    Busca
                </label>
            </div>
            <!--<div class="input-material">
                <select id="status" class="form-control" name="type">
                    <option [ngValue]="''">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option [value]="'published'">Publicado</option>
                    <option [value]="'unpublished'">Não Publicado</option>
                </select>
                <label for="status" class="mb-0 mr-3">Status</label>
            </div>-->
            <div class="page_filter_action flex-grow-1" style="max-width: 109px;">
                <a href="javascript:void(0)"
                   (click)="filter.page = 1; actionSearch()"
                   class="btn btn-block btn-lg btn-primary btn-search"
                   style="font-weight: normal;"
                   type="button">Buscar</a>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table_regular">
                <thead>
                <tr>
                    <th class="text-center w-10" scope="col"></th>
                    <th class="text-center w-5" scope="col">Status</th>
                    <!--<th class="text-center w-10" scope="col">Código</th>-->
                    <th scope="col">Nome</th>
                    <th scope="col">Título</th>
                    <th scope="col">{{'description' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(model.results)">
                    <td colspan="8" class="text-center" style="padding: 68px 0;"> nenhum registro encontrado</td>
                </tr>
                <tr *ngFor="let a of model.results |
                paginate: {itemsPerPage: model.pageSize,
                currentPage: model.currentPage,
                totalItems: model.totalCount }">
                    <td>
                        <div class="d-flex justify-content-center" style="gap: 15px">
                            <span *ngIf="editChallenge" (click)="updateChallenge(a)" class="is-cursor">
                                <svg-icon class="is-cursor" src="assets/svg/edit.svg"></svg-icon>
                            </span>
                            <svg-icon *ngIf="deleteChallenge" (click)="removeChallenge(a.id)" class="is-cursor"
                                      src="assets/svg/close-circle.svg"></svg-icon>
                        </div>
                    </td>
                    <td class="text-center">
                        <label class="switch disabled cursor-default">
                            <input type="checkbox" name="checkbox"
                                   [checked]="a.status === 'active'"
                                   [disabled]="true">
                            <span class="slider round cursor-default"></span>
                        </label>
                    </td>
                    <td class="idea-item_text">
                        {{a.name}}
                    </td>
                    <td>
                        {{a.title}}
                    </td>
                    <td>
                        {{a.description}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div>
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>


<div class="modal-custom modal fade" id="modalChallenge" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 560px" role="document">
        <div class="modal-content modal-user">
            <div class="modal-header">
                <h2 class="modal-user_title">{{'challenge' | translate}}</h2>
                <a data-dismiss="modal" class="modal_close" aria-label="Close">
                    <svg-icon src="assets/svg/close-modal.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-user_body">
                <div class="modal-user_card">
                    <fieldset class="custom__form" autocomplete="off">
                        <div class="row">
                            <div class="col-xs-12 col-md-12">
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="input-material custom__input modal_text_input">
                                            <input id="iptName" class="form-control" name="name" type="text"
                                                   [(ngModel)]="modelChallenge.name" required/>
                                            <label for="iptName">Nome</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-12">
                                        <div class="input-material custom__input modal_text_input">
                                            <input id="iptTitle" class="form-control" name="e-mail" type="text"
                                                   [(ngModel)]="modelChallenge.title" required/>
                                            <label for="iptTitle">Título</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-12">
                                        <div class="input-material custom__input modal_text_input">
                                            <input id="iptDescription" class="form-control" name="e-mail" type="text"
                                                   [(ngModel)]="modelChallenge.description" required/>
                                            <label for="iptDescription">{{'description' | translate}}</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="d-flex align-items-center mb-3" style="gap: 15px">
                                            <p class="label">Haverá um período?</p>
                                            <label class="switch cursor-default mb-0">
                                                <input type="checkbox" name="checkbox"
                                                       [(ngModel)]="modelChallenge.isDateSensitive">
                                                <span class="slider round cursor-default"></span>
                                            </label>
                                        </div>

                                        <div class="input-material custom__input modal_text_input"
                                             [ngClass]="{'d-none':!modelChallenge.isDateSensitive}">
                                            <input id="date" class="form-control picker-period" name="e-mail"
                                                   type="text" required/>
                                            <label for="date"></label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="input-material custom__input modal_text_input">
                                            <select id="iptLanguage" class="form-control" name="type" [disabled]="!isNullOrUndefined(this.modelChallenge.id)"
                                                    [(ngModel)]="modelChallenge.language">
                                                <option [ngValue]="undefined" selected>Selecione</option>
                                                <option [ngValue]="'pt-BR'">PT</option>
                                                <option [ngValue]="'es-ES'">ES</option>
                                            </select>
                                            <label for="iptLanguage" class="mb-0 mr-3 disabled">Idioma</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="input-material custom__input modal_text_input">
                                            <select id="iptStatus" class="form-control" name="type"
                                                    [(ngModel)]="modelChallenge.status">
                                                <option [ngValue]="undefined" selected>Selecione</option>
                                                <option [ngValue]="'active'">Ativo</option>
                                                <option [ngValue]="'inactive'">Inativo</option>
                                            </select>
                                            <label for="iptStatus" class="mb-0 mr-3">Status</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <a class="btn btn-block btn-lg btn-primary text-warning"
                                           href="javascript:void('save');"
                                           (click)="actionSave()"
                                        >SALVAR</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>


        </div>
    </div>
</div>

