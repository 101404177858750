import {AfterViewInit, Component, OnInit, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from "../../service/user.service";
import {User} from "../../../generated/graphql";

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    user: User = new User();
    navBarShort = false;
    btnNew = false;
    routerManager: boolean;
    urlRoute: string;

    dashboardSidebar: boolean;
    ideasSidebar: boolean;
    listSidebar: boolean;
    kanbanSidebar: boolean;
    newsSidebar: boolean;
    chalengeSidebar: boolean;
    managementSidebar: boolean;
    userSidebar: boolean;
    areaSidebar: boolean;
    unitySidebar: boolean;
    committeeSidebar: boolean;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public userService: UserService) {

        super(router, loading, translate);
    }


    ngOnInit(): void {
        this.handlerCollapse();
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.userProfile();
            }, error: err => super.onError(err)
        });
        this.getRoute()
        this.routerManager = this.getRouteManagement();
    }

    getRoute() {
        const t = this.router.url.split('/');
        return this.urlRoute = '/' + t[1];
    }


    ngOnDestroy(): void {
        $('#idea').collapse('hide');
        $('#management').collapse('hide');
    }

    ngAfterViewInit(): void {

        this.navActive();
    }

    handlerCollapse() {
        $('.navbar-nav .nav-item').click(function () {
            $('.navbar-nav .nav-item').removeClass('active');
            $(this).addClass('active');
        });
        $('.navbar-nav .nav-link').click(function () {
            $('.navbar-nav .nav-link').removeClass('text-white');
            $(this).addClass('text-white');
        });
    }

    actionCloseCollapse() {
        this.actionSideBar();
        $('#idea').collapse('hide');
        $('#management').collapse('hide');
        $('body').removeClass('nav-open');
        $('.hamburger').removeClass('open');
    }

    navActive() {
        if (this.router.url === '/calibration' || this.router.url === '/engines') {
            return this.handlerNavActive('.report-ts');
        }
        if (this.router.url === '/training' || this.router.url === '/faq') {
            return this.handlerNavActive('.help-ts');
        }
    }

    handlerNavActive(el) {
        $('.navbar-nav .nav-item').removeClass('active');
        $('.navbar-nav .nav-link').removeClass('text-white');
        $(el).addClass('active');
        $(el + ' .nav-link').addClass('text-white');

    }

    actionSideBar() {
        this.navBarShort = !this.navBarShort;
    }

    getRouteManagement() {
        switch (this.router.url) {
            case '/user':
                return true;
            case '/area':
                return true;
            case '/unity':
                return true;
            case '/committee':
                return true;
            default:
                return false;
        }
    }

    userProfile(){
        switch (this.user.role) {
            case 'admin':
                this.dashboardSidebar = true;

                this.ideasSidebar = true;
                this.listSidebar = true;
                this.kanbanSidebar = true;

                this.newsSidebar = true;

                this.chalengeSidebar = true;

                this.managementSidebar = true;
                this.userSidebar = true;
                this.areaSidebar = true;
                this.unitySidebar = true;
                this.committeeSidebar = true;
                break;
            case 'user':
                this.dashboardSidebar = true;

                this.ideasSidebar = true;
                this.listSidebar = true;
                this.kanbanSidebar = true;

                this.newsSidebar = true;

                this.chalengeSidebar = true;

                this.managementSidebar = true;
                this.userSidebar = true;
                this.areaSidebar = true;
                this.unitySidebar = true;
                this.committeeSidebar = true;
                break;
            case 'corporate-manager':
                this.dashboardSidebar = true;

                this.ideasSidebar = true;
                this.listSidebar = true;
                this.kanbanSidebar = true;

                this.newsSidebar = true;

                this.chalengeSidebar = true;

                this.managementSidebar = true;
                this.userSidebar = true;
                this.areaSidebar = true;
                this.unitySidebar = true;
                this.committeeSidebar = true;
                break;
            case 'local-manager':
                this.dashboardSidebar = true;

                this.ideasSidebar = true;
                this.listSidebar = true;
                this.kanbanSidebar = true;

                this.newsSidebar = true;

                this.chalengeSidebar = true;

                this.managementSidebar = true;
                this.userSidebar = true;
                this.areaSidebar = true;
                this.unitySidebar = true;
                this.committeeSidebar = true;
                break;
            case 'committee-member':
                this.dashboardSidebar = true;

                this.ideasSidebar = true;
                this.listSidebar = true;
                this.kanbanSidebar = true;

                this.newsSidebar = false;

                this.chalengeSidebar = false;

                this.managementSidebar = false;
                this.userSidebar = false;
                this.areaSidebar = false;
                this.unitySidebar = false;
                this.committeeSidebar = false;
                break;
        }
    }
}
