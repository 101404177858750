<div class="list" id="{{list.name}}" (dragover)="allowDrop($event)" (drop)="drop($event)">
    <p class="list__title font-weight-bold">{{getTranslate(list.name)}}</p>
    <a class="cards">
        <div *ngFor="let card of list.cards" (click)="onEdit(card.action)" class="kamban-card" draggable="true"
             (dragstart)="dragStart($event)" id="{{card?.action.id}}">
            <p class="kamban-card_code"
               *ngIf="(list.name === (('pending')) || (list.name === ('in progress')))">
                {{card.isDelayed === true ? 'Em atraso' : 'Em dia'}}
            </p>
            <p class="kamban-card_code">{{card?.action?.code}} - {{card?.action?.idea?.title}}</p>
            <p class="kamban-card_description">{{card?.action.title}}</p>
            <div class="kamban-card_info">
                <span class="kamban-card_date">{{card?.action.endDate | date:'dd/MM/yyyy'}}</span>
                <div class="kamban-card_info_item">
                    <svg-icon src="assets/svg/comment.svg"></svg-icon>
                    <span class="kamban-card_comment">{{card?.commentsCount}}</span>
                </div>
                <div class="kamban-card_assignment">
                    <svg-icon src="assets/svg/check-circle.svg"></svg-icon>
                    <span>{{card?.doneTasks}}/{{card?.totalTasks}}</span>
                </div>
                <div class="kamban-card_info_item">
                    <svg-icon src="assets/svg/attachment.svg"></svg-icon>
                    <span class="kamban-card_attachment">{{card?.filesCount}}</span>
                </div>
            </div>
            <div class="kamban-card_created-by">
<!--                <img src="{{card.action.responsible.photo != null ? card.action.responsible.photo : 'assets/images/nophoto.png' }}">-->

                <img src="{{card.action?.responsible?.photo}}" onerror="this.onerror=null;
                                this.src='https://www.pontoxtecidos.com.br/static/567/sku/164485009798408.jpg';"
                      alt="img"/>
                <p>{{card?.action.responsible?.name}}</p>
            </div>

        </div>
    </a>
    <a class="btn btn-block btn-sm btn-outline-primary opacity" href="javaScript:void(0)"
       (click)="selectStatus(list.name)">+ Adicionar Ação</a>
</div>
